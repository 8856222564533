<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" @click="toggleArchive">
        <v-icon v-if="deviceFilters.show_archived">mdi-archive-eye-outline</v-icon>
        <v-icon v-else>mdi-archive-off-outline</v-icon>
      </v-btn>
    </template>
    <span v-if="deviceFilters.show_archived">Archived Devices Shown</span>
    <span v-else>Archived Devices Hidden</span>
  </v-tooltip>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'DeviceArchiveToggle',
    data: () => ({
      filtered: []
    }),
    computed: {
      ...mapGetters(['site', 'sitePermission', 'deviceFilterVisible', 'deviceFilters']),
    },
    mounted () {
    },
    methods: {
      toggleArchive() {
        this.filtered = Object.assign({}, this.deviceFilters)
        this.filtered.show_archived = !this.filtered.show_archived
        this.$store.commit('setDeviceFilters', this.filtered)
        this.$store.commit('saveDeviceFilters')
      },
    }
  }
</script>