const state = {
  interfaceIO: ['input', 'output', 'bi-directional'],
  interfaceTerminal: [  'RJ45',
                        'XLR',
                        'Power Supply',
                        'HDMI',
                        'RCA',
                        'CABLE',
                        'USB',
                        'Jack 3.5',
                        'Jack 6.3',
                        'IEC',
                        'DC',
                        'XLR/PHOENIX',
                        'SPEAKON/PHOENIX',
                        'PHOENIX',
                        'XLR/Jack 6.3',
                        'Optical',
                        'S/PDIF Coax',
                        'S/PDIF Tosslink',
                        'Midi 5-pin',
                        'D-size slot',
                        'VGA',
                        'DVI',
                        'Displayport',
                        'BNC',
                        'RS-232',
                        'SFP',
                        'RJ10',
                        'RJ9'
                      ],
  interfaceSignal: ['Data', 'Audio', 'AC/DC', 'Video', 'Power'],
  locationTypes: ['room', 'space', 'outdoor area', 'Meeting Room', 'Meeting Room Teams']
};

const getters = {
  interfaceIO: state => state.interfaceIO,
  interfaceTerminal: state => state.interfaceTerminal,
  interfaceSignal: state => state.interfaceSignal,
  locationTypes: state => state.locationTypes
};

export default {
  state,
  getters
};