<template>
  <v-menu :close-on-content-click="false" v-model="statusMenu" offset-y min-width="200">
    <template #activator="{ on: onMenu }">
      <v-tooltip top>
        <template #activator="{ on: onTooltip }">
          <v-btn v-on="{ ...onMenu, ...onTooltip }" :color="statusColor(device.status)" large block outlined :disabled="sitePermission<2">
            {{showStatus(device.status)}}
          </v-btn>
        </template>

        <span>Change Status</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          v-for=" (item, index) in site.device_statuses"
          :key="item.id"
          @click="updateStatus(index)"
        > 
          <v-list-item-icon>
            <v-icon :color="item.color">mdi-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'StatusSelector',
    components: {
    },
    data: () => ({
      statusMenu: false,
      selectedItem: "",
    }),
    computed: {
      ...mapGetters(['site', 'device', 'sitePermission']),
    },

    methods: {
      showStatus (status) {
        return status ? status : "Status not set"
      },

      statusColor(status) {
        let index = this.site.device_statuses.findIndex(a => a.name == status)
        return (index > -1) ? this.site.device_statuses[index].color : "#888888"
      },

      async updateStatus(index) {
        const Device = new FormData()
        Device.append('id', this.device.id)
        Device.append('status', this.site.device_statuses[index].name)
        this.statusMenu = false
        let response = await this.$http.post('/site/devices/update_status', Device)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('updateDevice', response.data.device)
          this.$toast.success('Status Updated')
        }
      },
    },
  }
</script>