<template>
  <v-container fluid class="schemas-page">
    <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
      <h1 class="page-title">Schemas</h1>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="download"
        >
          Download
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-file-input
          truncate-length="15"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          @change="excelImport"
          ref="file"
          name="excel-input"
          id="input"
        ></v-file-input>
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="update"
        >
          Update Devices
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="excelData.length > 0">
      <v-col cols="12">
        <v-data-table
          :headers="excelHeaders"
          :items="excelData"
          :items-per-page="5"
          :loading="loading"
          loading-text="Processing... Please wait"
          class="elevation-1"
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import { utils, writeFile } from "xlsx"
import readXlsxFile from 'read-excel-file'

export default {
  name: "Schemas",
  data() {
    return {
      excelData: [],
      excelHeaders: [],
      loading: false,
      exportData: [],
      filename: "Wikibuild - Devices.xlsx"
    };
  },
  computed: {
    ...mapGetters(['site']),
  },
  mounted() {
    this.$store.commit('setHeaderTitle', this.site.name + ' > Schemas')
  },
  methods: {
    download () {
      this.filename = 'WikiBuild - '+ this.site.name + ' - Devices.xlsx'
      this.exportData = this.site.devices
      const data = utils.json_to_sheet(this.exportData)
      const wb = utils.book_new()
      utils.book_append_sheet(wb, data, 'data')
      writeFile(wb, this.filename)
    },
    excelImport () {
      this.excelData = []
      this.excelHeaders = []
      readXlsxFile(document.getElementById('input').files[0]).then((rows) => {
        let devices = []
        let headers = rows.shift()
        rows.forEach((row) => {
          const obj = {};
          row.forEach((prop, index) => {
            obj[headers[index]] = prop
          })
          devices.push(obj)
        })
        this.excelData = devices
        headers.forEach((header) => {
          let h = {}
          h["value"] = header
          h["text"] = header
          this.excelHeaders.push(h)
        })
      })
    },
    async update() {
      this.loading = true
      await this.excelData.forEach( async (device) => {
        const Device = new FormData()
        Device.append('id', device.id)
        if (device.floor_id != null) { Device.append('floor_id', device.floor_id)}
        if (device.location_id != null) { Device.append('location_id', device.location_id)}
        if (device.zone_id != null) { Device.append('zone_id', device.zone_id)}
        if (device.layer_id != null) { Device.append('layer_id', device.layer_id)}
        if (device.company_id != null) { Device.append('company_id', device.company_id)}
        if (device.name != null) { Device.append('name', device.name)}
        if (device.code != null) { Device.append('code', device.code)}
        if (device.sn != null) { Device.append('sn', device.sn)}
        if (device.mac != null) { Device.append('mac', device.mac)}
        if (device.ip != null) { Device.append('ip', device.ip)}
        if (device.patch != null) { Device.append('patch', device.patch)}
        if (device.port != null) { Device.append('port', device.port)}
        if (device.note != null) { Device.append('note', device.note)}
        if (device.purchase_date != null) { Device.append('purchase_date', device.purchase_date)}
        if (device.warranty_expiration_date != null) { Device.append('warranty_expiration_date', device.warranty_expiration_date)}
        
        let response = await this.$http.put('/site/devices', Device)
        this.$store.commit('updateDevice', response.data.device)
      })
      this.loading = false

    },
  },
};
</script>