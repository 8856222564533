<template>
  <v-autocomplete
    v-model="sites"
    :items="items"
    :label="label"
    :filter="siteFilter"
    item-value="id"
    prepend-icon="mdi-domain"
    clearable
    multiple
    chips
    :dense="dense"
    @change="input"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar left>
          <img v-if="data.item.image" :src="data.item.image">
          <span v-else class="white--text headline">{{ data.item.name?.substring(0,1) }}</span>
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template>
        <v-list-item-avatar color="primary">
          <img v-if="data.item.image" :src="data.item.image">
          <span v-else class="white--text headline">{{ data.item.name?.substring(0,1) }}</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
  export default {
    name: 'SiteSelect',
    data() {
      return {
        search: "",
        sites: []
      }
    },
    props: ['items', 'label', 'dense'],
    methods: {
      siteFilter (item, queryText) {
        const textOne = item.name.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1
      },
      input () {
        this.$emit('submit', this.sites)
      },
      remove (item) {
        const index = this.sites.indexOf(item.id)
        if (index >= 0) this.sites.splice(index, 1)
      },
      clear() {
        this.sites = []
        this.search = ''
        this.input()
      }
    },

  }
</script>