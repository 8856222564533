<template>
  <v-list-item @click="download">
    <v-list-item-icon>
      <v-icon>mdi-file-export-outline</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      Export Site's Locations
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import {mapGetters} from 'vuex'
import { utils, writeFile } from "xlsx"

export default {
  name: "XlsSiteDownloader",
  data() {
    return {
      exportData: [],
      filename: "Wikibuild - Site.xlsx"
    };
  },
  computed: {
    ...mapGetters(['site']),
  },
  methods: {
    download () {
      this.filename = 'WikiBuild - '+ this.site.name + ' - Site configuration.xlsx'
      this.site.floors.forEach( (floor) => {
        floor.locations.forEach( (loc) => {
          let row = {}
          row.floorName = floor.name
          row.floorCode = floor.code
          row.floorId = floor.id
          row.locationName = loc.name
          row.locationCode = loc.code
          row.locationId = loc.id
          row.locationType = loc.location_type
          this.exportData.push(row)
        })
      })
      const floors = utils.json_to_sheet(this.exportData)
      const wb = utils.book_new()
      utils.book_append_sheet(wb, floors, 'floors')
      writeFile(wb, this.filename)
    },
  },
};
</script>