<template>
  <v-container fluid class="products-page mt-5">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="products"
          :search="search"
          :loading="loading"
          sort-by="id"
          class="elevation-1"
          fixed-header
          height="74vh"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              height="90"
            >
              <v-toolbar-title>Products</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                clearable
                flat
                color="primary"
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                width="80%"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Product
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
                    <v-toolbar-title class="headline primary--text">{{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
<!--                     <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="deleteDialog = true" color="error">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip> -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="save" color="primary">
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </template>
                      <span>Save</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="close" color="error">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Close</span>
                    </v-tooltip>
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="3" class="pt-16 pr-4">
                          <image-uploader ref="imgUploader" :obj="editedItem" directory="products" @located="setFileLocation" class="mt-16"/>
                        </v-col>
                        <v-col cols="9">
                          <v-tabs fixed-tabs >
                            <v-tab>General</v-tab>
                            <v-tab>Specifications</v-tab>
                            <v-tab>Description</v-tab>
                            <v-tab>I/O</v-tab>

                            <v-tab-item key="1" class="pt-6">
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editedItem.name"
                                    label="Product Name*"
                                    prepend-icon="mdi-package-variant"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6" class="pb-0">
                                  <v-text-field
                                    v-model="editedItem.brand"
                                    label="Brand"
                                    prepend-icon="mdi-tag"
                                  ></v-text-field>
                                  <v-text-field
                                    v-model="editedItem.product_model"
                                    label="Model"
                                    prepend-icon="mdi-package"
                                  ></v-text-field>
                                  <v-autocomplete 
                                    v-model="editedItem.procat_id" 
                                    :items="procats" 
                                    item-text="name" 
                                    item-value="id" 
                                    label="Product Category" 
                                    prepend-icon="mdi-format-list-bulleted-type" >
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="6" class="pb-0">
                                  <v-autocomplete 
                                    v-model="editedItem.preferred_supplier_id" 
                                    :items="suppliers" 
                                    item-text="name" 
                                    item-value="id" 
                                    label="Supplier" 
                                    prepend-icon="mdi-truck" >
                                  </v-autocomplete>
                                  <v-autocomplete 
                                    v-model="editedItem.company_id" 
                                    :items="manufacturers" 
                                    item-text="name" 
                                    item-value="id" 
                                    label="Manufacturer" 
                                    prepend-icon="mdi-tools" >
                                  </v-autocomplete>
                                  <v-text-field
                                    v-model="editedItem.product_url"
                                    label="Product Webpage"
                                    prepend-icon="mdi-web"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="4" class="pt-0">
                                  <v-text-field
                                    v-model="editedItem.sku"
                                    label="SKU"
                                    prepend-icon="mdi-web"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="4" class="pt-0">
                                  <v-text-field
                                    v-model="editedItem.ean"
                                    label="EAN"
                                    prepend-icon="mdi-web"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="4" class="pt-0">
                                  <v-text-field
                                    v-model="editedItem.upc"
                                    label="UPC"
                                    prepend-icon="mdi-web"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-tab-item>
                            <v-tab-item key="2" class="pt-6">
                              <v-row>
                                <v-col cols=6>
                                  <v-text-field 
                                    v-model="editedItem.height" 
                                    label="Height" 
                                    prepend-icon="mdi-axis-z-arrow" 
                                    suffix="mm"
                                  ></v-text-field>
                                  <v-text-field 
                                    v-model="editedItem.width" 
                                    label="Width" 
                                    prepend-icon="mdi-axis-y-arrow" 
                                    suffix="mm"
                                  ></v-text-field>
                                  <v-text-field 
                                    v-model="editedItem.depth" 
                                    label="Depth" 
                                    prepend-icon="mdi-axis-x-arrow" 
                                    suffix="mm"
                                  ></v-text-field>
                                  <v-text-field 
                                    v-model="editedItem.weight" 
                                    label="Weight" 
                                    prepend-icon="mdi-weight" 
                                    suffix="g"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols=6>
                                    <v-checkbox 
                                      v-model="editedItem.rack_mounted" 
                                      label="Rack Mounted"
                                    ></v-checkbox>
                                    <v-text-field 
                                      type="number" 
                                      v-model="editedItem.rack_units" 
                                      :disabled="!editedItem.rack_mounted" 
                                      label="Rack Units" 
                                      suffix="U" 
                                      prepend-icon="mdi-server"
                                    ></v-text-field>
                                    <v-text-field 
                                      v-model="editedItem.power" 
                                      label="Power Consumption" 
                                      prepend-icon="mdi-power-plug"
                                    ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-tab-item>
                            <v-tab-item key="3" class="pt-10">
                              <v-row>
                                <v-col cols="12">
                                  <v-textarea
                                    outlined
                                    name="input-7-4"
                                    v-model="editedItem.description"
                                    label="Description"
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                            </v-tab-item>
                            <v-tab-item key="4" class="pt-6">
                              <v-row>
                                <v-col cols=2>
                                  <v-btn outlined tile block color="primary" @click="addIO(editedItem.id)">Add I/O</v-btn>
                                  <v-dialog v-model="interfaceDialog" max-width="1000px">
                                    <template v-slot:activator="{ on }">
                                      <v-btn outlined tile block color="primary" class="mt-4" v-on="on" @click="setProductId(editedItem.id)">add multiple I/O</v-btn>
                                    </template>
                                    <v-card>
                                      <v-card-title>
                                        add multiple I/O
                                      </v-card-title>

                                      <v-card-text>
                                        <v-row class="justify-center">
                                          <v-col cols=2>
                                            <v-text-field 
                                              v-model="newInterfaceQty" 
                                              label="Quantity" 
                                              prepend-icon="mdi-plus-minus"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols=3>
                                            <v-select 
                                              v-model="newInterface.interface_type" 
                                              :items="interfaceIO" 
                                              label="I/O" 
                                              prepend-icon="mdi-swap-horizontal"
                                            ></v-select>
                                          </v-col>
                                          <v-col cols=3>
                                            <v-select 
                                              v-model="newInterface.terminal" 
                                              :items="interfaceTerminal" 
                                              label="Terminal" 
                                              prepend-icon="mdi-console"
                                            ></v-select>
                                          </v-col>
                                          <v-col cols=3>
                                            <v-text-field 
                                              v-model="newInterface.label" 
                                              label="Label" 
                                              prepend-icon="mdi-label"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols=3>
                                            <v-select 
                                              v-model="newInterface.signal" 
                                              :items="interfaceSignal" 
                                              label="Signal" 
                                              prepend-icon="mdi-signal-distance-variant"
                                            ></v-select>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" text @click="closeInterfaceDialog">Cancel</v-btn>
                                        <v-btn color="success" text @click="addMultipleInterfaces">Save</v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                </v-col>
                                <v-col cols=10>
                                  <v-simple-table dense fixed-header height="250">
                                    <template v-slot:default>
                                      <thead>
                                        <tr>
                                          <th class="text-left px-0">I/O</th>
                                          <th class="text-left px-0">Terminal</th>
                                          <th class="text-left px-0">Label</th>
                                          <th class="text-left px-0">Signal</th>
                                          <th class="text-right pl-0">Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(item, index) in editedItem.interfaces" :key="index">
                                          <td class="px-0">
                                            <v-select 
                                              v-model="item.interface_type" 
                                              :items="interfaceIO" 
                                              hide-details 
                                              dense 
                                              height="32" 
                                            ></v-select>
                                          </td>
                                          <td class="px-0">
                                            <v-select 
                                              v-model="item.terminal" 
                                              :items="interfaceTerminal" 
                                              hide-details 
                                              dense 
                                              height="32" 
                                            ></v-select>
                                          </td>
                                          <td class="px-0">
                                            <v-text-field 
                                              v-model="item.label" 
                                              dense 
                                              hide-details 
                                              height="32"
                                            ></v-text-field>
                                          </td>
                                          <td class="px-0">
                                            <v-select 
                                              v-model="item.signal" 
                                              :items="$store.getters.interfaceSignal" 
                                              hide-details 
                                              dense 
                                              height="32" 
                                            ></v-select>
                                          </td>
                                          <td width="100" class="text-right pl-0">
                                            <v-icon class="mr-2 info--text" @click="cloneIO(item)">mdi-content-duplicate</v-icon>
                                            <v-icon class="error--text" @click="discardIO(item, index)">mdi-delete</v-icon>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                </v-col>
                              </v-row>
                            </v-tab-item>
                          </v-tabs>
                        </v-col>
                     </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-switch
                      v-model="editedItem.product_status"
                      inset
                      :label="editedItem.product_status ? 'In Production' : 'Discontinued'"
                      class="ml-4"
                    ></v-switch>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>showCategory
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <div class="p-2">
              <v-img :src="item.image" :alt="item.name" max-height="100px" max-width="100px" contain></v-img>
            </div>
          </template>
          <template v-slot:[`item.tenant_id`]="{ item }">
            {{ showTenantName(item.tenant_id) }}
          </template>
          <template v-slot:[`item.company_id`]="{ item }">
            {{ showCompanyName(item.company_id) }}
          </template>
          <template v-slot:[`item.procat_id`]="{ item }">
            {{ showCategory(item.procat_id) }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  import ImageUploader from '@/components/S3Upload/ImageUploader'

  export default {
    name: 'Products',
    components: {
      ImageUploader
    },
    data: () => ({
      products: [],
      tenants: [],
      procats: [],
      companies: [],
      search: '',
      loading: true,
      dialog: false,
      dialogDelete: false,
      interfaceDialog: false,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Image', value: 'image', sortable: false },
        { text: 'Name', value: 'name' },
        { text: 'Brand', value: 'brand' },
        { text: 'Model', value: 'product_model' },
        { text: 'Tenant', value: 'tenant_id' },
        { text: 'Category', value: 'procat_id' },
        { text: 'Manufacturer', value: 'company_id' },
        { text: 'Created at', value: 'created_at' },
        { text: 'Updated at', value: 'updated_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        image: '',
        product_model: '',
        product_url: '',
        product_status: true,
        description: '',
        features: '',
        height: '',
        width: '',
        depth: '',
        weight: '',
        rack_mounted: false,
        rack_units: '',
        sku: '',
        upc: '',
        ean: '',
        tenant_id: 1,
        procat_id: '',
        company_id: '',
        preferred_supplier_id: '',
        interfaces: [],
        discarded_interfaces: []
      },
      defaultItem: {
        name: '',
        image: '',
        product_model: '',
        product_url: '',
        product_status: true,
        description: '',
        features: '',
        height: '',
        width: '',
        depth: '',
        weight: '',
        rack_mounted: false,
        rack_units: '',
        sku: '',
        upc: '',
        ean: '',
        tenant_id: 1,
        procat_id: '',
        company_id: '',
        preferred_supplier_id: '',
        interfaces: [],
        discarded_interfaces: []
      },
      newInterfaceQty: 1,
      newInterface: {
        product_id: null
      }
    }),

    computed: {
      ...mapGetters(['interfaceIO', 'interfaceTerminal', 'interfaceSignal', 'StateUser']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Product' : 'Edit Product'
      },
      suppliers () {
        return this.companies.filter( el => { return el.supplier == true })
      },
      manufacturers () {
        return this.companies.filter( el => { return el.manufacturer == true })
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      interfaceDialog (val) {
        val || this.closeInterfaceDialog()
      }
    },

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', 'Products')
    },

    methods: {
      async initialize () {
        let response = await this.$http.get('/tenant/products')
        this.products = response.data.products
        this.tenants = response.data.tenants
        this.procats = response.data.procats
        this.companies = response.data.companies
        this.loading = false
        this.editedItem.tenant_id = this.StateUser.tenant_id
        this.defaultItem.tenant_id = this.StateUser.tenant_id
      },

      editItem (item) {
        this.editedIndex = this.products.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.editedItem.discarded_interfaces = []
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.products.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.products.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.$refs.imgUploader.clear()
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      setFileLocation (value) {
        this.editedItem.image = value
      },

      async save () {
        const Product = new FormData();
        Product.append("image", this.editedItem.image )
        Product.append("name", this.editedItem.name)
        Product.append("product_model", this.editedItem.product_model)
        Product.append("product_url", this.editedItem.product_url)
        Product.append("product_status", this.editedItem.product_status)
        Product.append("brand", this.editedItem.brand)
        Product.append("power", this.editedItem.power)
        Product.append("sku", this.editedItem.sku)
        Product.append("upc", this.editedItem.upc)
        Product.append("ean", this.editedItem.ean)
        Product.append("description", this.editedItem.description)
        Product.append("features", this.editedItem.features)
        Product.append("height", this.editedItem.height)
        Product.append("width", this.editedItem.width)
        Product.append("depth", this.editedItem.depth)
        Product.append("weight", this.editedItem.weight)
        Product.append("rack_mounted", this.editedItem.rack_mounted)
        Product.append("rack_units", this.editedItem.rack_units)
        Product.append("company_id", this.editedItem.company_id)
        Product.append("preferred_supplier_id", this.editedItem.preferred_supplier_id)
        Product.append("procat_id", this.editedItem.procat_id)
        Product.append("tenant_id", this.editedItem.tenant_id)

        let allInterfaces = JSON.stringify([...this.editedItem.interfaces, ...this.editedItem.discarded_interfaces])
        Product.append("interfaces", allInterfaces)

        if (this.editedIndex > -1) {
          Product.append("id", this.editedItem.id);
          let response = await this.$http.put('/tenant/products', Product)
          Object.assign(this.products[this.editedIndex], response.data.product)
          this.$refs.imgUploader.clear()
          this.$toast.success("Product Updated")
        } else {
          let response = await this.$http.post('/tenant/products', Product)
          this.products.push(response.data.product)
          this.$refs.imgUploader.clear()
          this.$toast.success("Product Created")
          
        }
        this.close()
      },

      showTenantName (id) {
        let t = this.tenants.find(obj => { return obj.id === id })
        return t ? t.name : ''
      },

      showCompanyName (id) {
        let c = this.companies.find(obj => { return obj.id === id })
        return c ? c.name : ''
      },

      showCategory (id) {
        let c = this.procats.find(obj => { return obj.id === id})
        return c ? c.name : ''
      },

      addIO (id) {
        this.editedItem.interfaces.push({product_id: id})
      },

      closeInterfaceDialog () {
        this.interfaceDialog = false
        this.newInterfaceQty = 1
        this.newInterface = { product_id: null }
      },

      setProductId (id) {
        this.newInterface.product_id = id
      },

      addMultipleInterfaces () {
        for (let i = 0; i < this.newInterfaceQty ; i++) {
          this.editedItem.interfaces.push(this.newInterface)
        }
        this.closeInterfaceDialog()
      },

      cloneIO(item) {
        let clone = Object.assign({}, item)
        clone.id = null
        this.editedItem.interfaces.push(clone)
      },

      discardIO(item, index) {
        this.editedItem.interfaces.splice(index, 1)
        if (item.id) {
          item.discard = true
          this.editedItem.discarded_interfaces.push(item)
        }
      }

    },
  }
</script>