<template>
  <v-card min-height="85vh">
    <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
      <v-toolbar-title class="headline primary--text">Edit Site</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="save" color="primary">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>Save</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="site.name" label="Name*" required></v-text-field>
            <v-autocomplete v-model="site.company_id" :items="customers" label="Customer" item-text="name" item-value="id" ></v-autocomplete>
          </v-col>
          <v-col cols="9" class="py-0">
            <v-text-field label="Street" v-model="site.street"></v-text-field>
          </v-col>
          <v-col cols="3" class="py-0">
            <v-text-field label="Street Number" v-model="site.street_number" ></v-text-field>
          </v-col>
          <v-col cols="3" class="py-0">
            <v-text-field label="Postal Code" v-model="site.zip" ></v-text-field>
          </v-col>
          <v-col cols="9" class="py-0">
            <v-text-field label="City" v-model="site.city" ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-autocomplete v-model="site.country" :items="countries" label="Country" ></v-autocomplete>
          </v-col>
          <v-col cols="12" class="py-0">
            <s3-file-uploader :obj="site" fieldName="image" directory="sites" label="Upload Picture" @located="setFileLocation"/>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import S3FileUploader from '@/components/S3Upload/S3FileUploader'

export default {
  name: "DeviceStatus",
  components: {
    S3FileUploader,
  },
  data() {
    return {
      customers: [],
      countries: ['Belgium', 'France', 'Luxemburg', 'Netherlands'],
    };
  },
  computed: {
    ...mapGetters(['site']),
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      let response = await this.$http.get('/site/index')
      this.customers = response.data.customers
      this.loading = false
    },
    setFileLocation (value) {
      this.site.image = value
    },
    async save () {
      const Site = new FormData()
      Site.append("name", this.site.name)
      Site.append("company_id", this.site.company_id)
      Site.append("image", this.site.image)
      Site.append("country", this.site.country)
      Site.append("zip", this.site.zip)
      Site.append("city", this.site.city)
      Site.append("street", this.site.street)
      Site.append("street_number", this.site.street_number)
      Site.append("id", this.site.id)
      let response = await this.$http.put('/site/sites', Site)
      if (response.data.error) {
        Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
      } else {
        this.$store.commit('updateSite', response.data.site)
        this.$toast.success("Site updated")
      }
    },
  },
}
</script>