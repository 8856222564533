<template>
<v-card scrollable>
  <v-card-text style="height: 70vh; overflow-y: auto;">
    <v-timeline dense>
      <v-timeline-item v-for="message in ticket.messages" :key="message.id" small>
        <v-card outlined elevation="3">
          <v-card-subtitle>
            {{message.topic+' by '+showUserName(message.sender_id) }}
          </v-card-subtitle>
          <v-card-subtitle>
            {{ message.created_at | moment($store.getters.dateFormat) }}
          </v-card-subtitle>
          <v-card-text>
            <span style="white-space: pre;">{{message.body}}</span>
          </v-card-text>
        </v-card>
      </v-timeline-item>
      <v-timeline-item small>
        <v-card outlined elevation="3">
          <v-card-title>
            Write a message
          </v-card-title>
          <v-card-text>
            <v-textarea v-model="messageBody" outlined clearable multiline>
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="sendMessage">Send</v-btn>
          </v-card-actions>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-card-text>
</v-card>
  
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "messageBoard",
    data () {
      return {
        loading: true,
        messageBody: '',
      }
    },
    computed: {
      ...mapGetters(['StateUser', 'users', 'ticket'])
    },
    mounted() {
      this.initialize()
    },
    methods: {
      initialize () {
        this.loading = false
      },
      showUserName (id) {
        let user = this.users.find(obj => { return obj.id === id })
        return user.fname+' '+user.lname
      }, 
      async sendMessage() {
        const Message = new FormData
        Message.append("topic", 'Ticket response')
        Message.append("sender_id", this.StateUser.id)
        Message.append("recipient_id", this.ticket.assignee_id)
        Message.append("title", "Ticket Response")
        Message.append("body", this.messageBody)
        Message.append("messageable_type", 'Ticket')
        Message.append("messageable_id", this.ticket.id)

        let response = await this.$http.post('/global/messages', Message)
        this.$store.commit('createTicketMessage', response.data.message)
        this.messageBody = ''
        this.$toast.success('Message Sent')

      }
    },
  }
</script>