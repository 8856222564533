<template>
  <v-card>
    <v-toolbar color="grey lighten-2 mb-5">
      <v-toolbar-title class="headline">Ticket #{{ ticket.id }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="updateTicket">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>Save</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="closeTicket">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Close</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="ticket.site_id"
            :items="sites"
            label="Site"
            item-text="name"
            item-value="id"
            prepend-icon="mdi-office-building"
            required
          >
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="ticket.subject"
            label="Subject"
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-autocomplete
            v-model="ticket.assigner_id"
            :items="users"
            label="Assigner"
            item-text="name"
            item-value="id"
            prepend-icon="mdi-account"
            clearable
          >
            <template v-slot:selection="data">
              {{ data.item.fname +' '+ data.item.lname}}
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar color="primary">
                  <img v-if="data.item.avatar" :src="data.item.avatar">
                  <span v-else class="white--text headline">{{ data.item.fname.substring(0,1) }}</span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.fname"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.lname"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="2" class="text-center pt-7">
          <v-icon x-large>mdi-arrow-right-thick</v-icon>
        </v-col>
        <v-col cols="5">
          <v-autocomplete
            v-model="ticket.assignee_id"
            :items="users"
            label="Assignee"
            item-text="name"
            item-value="id"
            prepend-icon="mdi-account"
            clearable
          >
            <template v-slot:selection="data">
              {{ data.item.fname +' '+ data.item.lname}}
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar color="primary">
                  <img v-if="data.item.avatar" :src="data.item.avatar">
                  <span v-else class="white--text headline">{{ data.item.fname.substring(0,1) }}</span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.fname"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.lname"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="ticket.priority"
            :items="ticket_priorities"
            label="Priority"
            prepend-icon="mdi-priority-high"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-menu
            v-model="dp1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDueDate"
                label="Due Date"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="ticket.due_date"
              @input="dp1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="ticket.ticket_type"
            :items="ticket_types"
            label="Type"
            prepend-icon="mdi-priority-high"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "updateTicket",
    data () {
      return {
        loading: true,
        ticketStatusToggle: undefined,
        editTicketdialog: false,
        dp1: false,
        updateTicketDialog: false,
        sites: [],
      }
    },
    computed: {
      computedDueDate () {
        return this.ticket.due_date ? this.$moment(String(this.ticket.due_date)).format('DD/MM/YYYY') : ''
      },
      ...mapGetters(['tickets', 'users', 'ticket', 'ticket_priorities', 'ticket_types'])
    },
    mounted() {
      this.initialize()
    },
    methods: {
      async initialize () {
        let response = await this.$http.get('/site/index')
        this.sites = response.data.sites
        this.loading = false
      },
      async updateTicket() {
        const Ticket = new FormData()
        Ticket.append('id', this.ticket.id)
        Ticket.append('site_id', this.ticket.site_id)
        Ticket.append('due_date', this.ticket.due_date)
        Ticket.append('assigner_id', this.ticket.assigner_id)
        Ticket.append('assignee_id', this.ticket.assignee_id)
        Ticket.append('priority', this.ticket.priority)
        Ticket.append('ticket_type', this.ticket.ticket_type)
        Ticket.append('subject', this.ticket.subject)

        let response = await this.$http.put('/support/tickets', Ticket)
        this.$store.commit('updateTicket', response.data.ticket)
        this.closeTicket ()
        this.$toast.success('Ticket Created');

      },
      closeTicket () {
        this.$emit('closeUpdateTicketDialog')
      },
    },
  }
</script>