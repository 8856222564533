import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

import auth from './modules/auth'
import header from './modules/header'
import settings from './modules/settings'
import constants from './modules/constants'
import site from './modules/site'
import support from './modules/support'
import s3 from './modules/s3'
import admin from './modules/admin'
import tenant from './modules/tenant'
import plan from './modules/plan'

Vue.use(Vuex)

export default new Vuex.Store({

  namespace: true,
  modules: {
    auth,
    header,
    settings,
    constants,
    site,
    support,
    s3,
    admin,
    tenant,
    plan
  },
  plugins: [createPersistedState()]
});
