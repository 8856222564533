<template>
  <v-app>
    <site-bar />
    <v-main class="v3-content">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import {mapGetters} from 'vuex'
  import SiteBar from '@/components/V3/Header/SiteBar'
  import './Layout.scss';

  export default {
    name: 'NewSiteLayout',
    components: { SiteBar },
    computed: {
      ...mapGetters(['site', 'deviceDialog','sitePermission', 'defaultDeviceHeaders']),
    },
    async mounted() {
      this.$store.commit('setDeviceHeaders', this.defaultDeviceHeaders)
      this.getPermissions()
      this.$store.commit('setHeaderTitle', "Wikibuild")
    },
    unmounted () {
      this.$store.commit('unsetSite')
    },
    methods: {
      async getPermissions () {
        const Site = new FormData()
        Site.append("id", this.$route.params.site_id)
        let response = await this.$http.post('/site/permission', Site)
        if (response.data.permission == 0) {
          this.exitSite()
        } else {
          await this.$store.dispatch('setSite', this.$route.params.site_id)
          this.$store.commit('setPermission', response.data.permission)
          this.$store.commit('setAccess', response.data.access.id)
          this.$store.commit('setDeviceHeaders', response.data.access.device_headers)
          this.$store.commit('setDeviceFilters', response.data.access.device_filters)
          this.$store.commit('setHeaderTitle', this.site.name)
        }
      },
      exitSite () {
        this.$store.commit('unsetSite')
        this.$router.push('/sites')
      }
    }
  };
</script>

<style src="./Layout.scss" lang="scss" />