<template>
  <v-container fluid class="profile-page mt-5">
    <v-row>
      <v-col cols="2">
        <v-card>
          <v-img
            :aspect-ratio="1/1"
            contain
            src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/male-placeholder-image.jpeg"
          ></v-img>
          <div class="text-h5 grey--text text--darken-3 px-4 pt-4">{{StateUser.fname}} {{StateUser.lname}}</div>
          <div class="subtitle-2 primary--text font-weight-regular px-4">{{UserRole}}</div>
          <v-list two-line class="mb-10">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary">
                  mdi-phone
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{StateUser.phone1}}</v-list-item-title>
                <v-list-item-subtitle>Primary Phone</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary">
                  mdi-email
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{StateUser.email}}</v-list-item-title>
                <v-list-item-subtitle>Email</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary">
                  mdi-domain
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{myCompany}}</v-list-item-title>
                <v-list-item-subtitle>Company</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-bottom-navigation grow class="card-navigation" color="primary" :value="value">
            <v-btn @click="editUser">
              <span>Edit Profile</span>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="editPassword">
              <span>Change Password</span>
              <v-icon>mdi-lock</v-icon>
            </v-btn>
          </v-bottom-navigation>
          <v-dialog
            v-model="dialog"
            width="600px"
          >
            <v-card>
              <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
                <v-toolbar-title class="headline primary--text">Edit Profile</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="save" color="primary">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>Save</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="close" color="error">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Close</span>
                </v-tooltip>
              </v-toolbar>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                      label="First name*"
                      v-model="editedItem.fname"
                      prepend-icon="mdi-account"
                      required
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                      label="Last name*"
                      v-model="editedItem.lname"
                      prepend-icon="mdi-account-multiple"
                      required
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                      label="Email/Username*"
                      v-model="editedItem.email"
                      prepend-icon="mdi-mail"
                      required
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                      label="Primary Phone"
                      v-model="editedItem.phone1"
                      prepend-icon="mdi-phone"
                      required
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                      label="Secundary Phone"
                      v-model="editedItem.phone2"
                      prepend-icon="mdi-phone"
                      required
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="editedItem.company_id"
                        :items="companies"
                        item-text="name"
                        item-value="id"
                        label="Company"
                        prepend-icon="mdi-domain"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="passwordDialog"
            width="400px"
          >
            <v-card>
              <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
                <v-toolbar-title class="headline primary--text">Change Password</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="savePassword" color="primary">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>Save</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="closePassword" color="error">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Close</span>
                </v-tooltip>
              </v-toolbar>

              <v-card-text>
                <div class="pb-5">
                  <v-icon color="error" class="mr-2">mdi-alert</v-icon>
                  You will be logged out if succesfull
                </div>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Old Password"
                        v-model="oldPass"
                        prepend-icon="mdi-lock-clock"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        name="oldPass"
                        :rules="[rules.required]"
                        @click:append="show1 = !show1"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                      label="New Password"
                      v-model="newPass"
                      prepend-icon="mdi-lock-open"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      name="newPass"
                      :rules="[rules.required, rules.min]"
                      :error-messages="error2"
                      @click:append="show2 = !show2"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                      label="Retype New Password"
                      v-model="newPass2"
                      prepend-icon="mdi-lock-open-plus"
                      :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show3 ? 'text' : 'password'"
                      name="newPass2"
                      :rules="[rules.required, rules.min]"
                      :error-messages="error3"
                      @click:append="show3 = !show3"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
      <!-- <v-col cols="10 mt-3">
        <v-card>
          <v-row>
            <v-col cols="3">
              <v-card flat class="text-center" to="/profile">
                <v-card-text>
                  <v-icon style="font-size: 64px" color="primary">mdi-office-building</v-icon>
                  <h1 class="page-title primary--text mb-5">Sites</h1>
                  <span class="font-weight-medium" style="font-size: 36px">
                    6
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card flat class="text-center" to="/profile">
                <v-card-text>
                  <v-icon style="font-size: 64px" color="primary">mdi-share-variant</v-icon>
                  <h1 class="page-title primary--text mb-5">Shared Sites</h1>
                  <span class="font-weight-medium" style="font-size: 36px">
                    4
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-tabs v-model="tab" fixed grow class="mt-10">
            <v-tab>Settings</v-tab>
            <v-tab>History</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="mt-3">
            <v-tab-item>
              <v-row>
                <v-col cols="12" class="mt-6">
                  Settings
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col cols="12" class="mt-6">
                  History
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'Profile',
    data () {
      return {
        loading: true,
        value: '',
        tab: '',
        companies: [],
        myCompany: "",
        editedItem: {},
        dialog: false,
        passwordDialog: false,
        oldPass: '',
        newPass: '',
        newPass2: '',
        show1: false,
        show2: false, 
        show3: false,
        error2: '',
        error3: '',
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 6 || 'Min 6 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
      }
    },
    computed: {
      ...mapGetters(['StateUser', 'UserRole']),
    },
    mounted() {
      this.initialize()
      this.$store.commit('setHeaderTitle', 'Profile > ' + this.StateUser.fname + ' ' + this.StateUser.lname )
    },
    methods: {
      async initialize () {
        let response = await this.$http.get('/global/company_list')
        this.companies = response.data.companies
        let com = this.companies[this.companies.findIndex(a => a.id === this.StateUser.company_id)]
        if (com) { this.myCompany = com.name }
        this.loading = false
      },
      editUser () {
        this.editedItem = this.StateUser
        this.dialog = true
      },
      close() {
        this.editedItem = {}
        this.dialog = false
      }, 
      async save () {
        const User = new FormData();
        User.append("email", this.editedItem.email)
        User.append("fname", this.editedItem.fname)
        User.append("lname", this.editedItem.lname)
        User.append("phone1", this.editedItem.phone1)
        User.append("phone2", this.editedItem.phone2)
        User.append("company_id", this.editedItem.company_id)
        User.append("id", this.editedItem.id)
         
        let response = await this.$http.put('/tenant/users', User)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('setUser', response.data.user)
          this.$toast.success("User Updated")
          this.$store.commit('setHeaderTitle', 'Profile > ' + this.StateUser.fname + ' ' + this.StateUser.lname )
          this.close()
        } 
      },
      editPassword () {
        this.passwordDialog = true
      },
      closePassword() {
        this.passwordDialog = false
        this.oldPass = ''
        this.newPass = ''
        this.newPass2 = ''
        this.show1 = false
        this.show2 = false
        this.show3 = false
        this.error2 = ''
        this.error3 = ''
      },
      async savePassword() {
        if (this.newPass !== this.newPass2) {
          this.error2 = this.error3 = "New Passwords don't Match"
        } else {
          const Password = new FormData()
          Password.append("id", this.StateUser.id)
          Password.append("oldPass", this.oldPass)
          Password.append("newPass", this.newPass)
          let response = await this.$http.post('/tenant/users/change_password', Password)
          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            await this.$store.dispatch('LogOut')
            this.$router.push('/login')
            this.$toast.success("Password Changed")
          }
        }
      }
    }
  }
</script>

<style scoped>
  .card-navigation {
    box-shadow: none !important;
  }
</style>
