<template>
  <v-app>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">
          <div class="d-flex">
            <v-img
              contain
              src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/header-logo.svg"
              max-width="200"
            ></v-img>
          </div>
          <div class="d-flex">
            <p>WIKIBUILD</p>
          </div>
        </v-col>
        <v-col cols="12" lg="5" class="login-part d-flex align-center justify-center">
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper pt-md-4 pt-0">
                <v-form>
                  <v-container>
                    <v-row class="flex-column">
                      <v-col>
                        <h1 class="login-slogan text-center font-weight-medium my-10">Log in as</h1>
                        <h3 class="login-slogan text-center font-weight-medium my-10">{{name}}</h3>
                      </v-col>
                      <v-form >
                        <v-col>
                          <v-text-field
                            v-model="mPass"
                            label="Master Password"
                            type="password"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex justify-space-between">
                          <v-btn
                              class="text-capitalize"
                              large
                              color="primary"
                              @click="login"
                          >
                            Login</v-btn>
                        </v-col>
                      </v-form>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text">© 2020-2023 WikiBuild. All rights reserved.</div>
              </v-footer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    name: 'Login',
    data() {
      return {
        name: '',
        id: 0,
        loading: false,
        mPass: ""
      }
    },
    computed: {
      isAuthenticated () {
        return this.$store.getters.isAuthenticated
      }
    },
    methods: {
      async login(){
        this.loading = true
        const User = new FormData()
        User.append("id", this.id)
        User.append("password", this.mPass)
        let response = await this.$http.post('/admin/users/loginas', User)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('setUser', response.data.user)
          this.$store.commit('setToken', response.data.token)
          this.$router.push('/sites')
        }
        this.loading = false
      },
    },
    created() {
      this.name = this.$route.query.name
      this.id = this.$route.query.id
      if (!this.isAuthenticated) {
        this.$router.push('/login')
      }
    }
  }

</script>

<style src="./Login.scss" lang="scss"/>