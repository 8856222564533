<template>
  <v-container fluid class="shares-page mt-5">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="site.site_shares"
          :search="search"
          :loading="loading"
          key="id"
          sort-by="id"
          class="elevation-1"
          :items-per-page="12"
          @click:row="editItem"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              height="90"
            >
              <v-icon large class="mx-4">mdi-share-variant</v-icon>              
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                clearable
                flat
                color="primary"
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                width="600px"
                v-if="sitePermission>1"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Share Site
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
                    <v-toolbar-title class="headline primary--text">Share site</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip top v-if="editedIndex > -1">
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="dialogDelete = true" color="error">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="save" color="primary" :disabled="saveDisabled">
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </template>
                      <span>Save</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="close" color="error">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Close</span>
                    </v-tooltip>
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.email"
                            label="email"
                            prepend-icon="mdi-shield-account"
                            v-on:blur="findInvitee"
                            :disabled="editedIndex > -1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="newUser">
                          <p>This user is not registered in our database, sharing this site will send a registration invite to the user. New users are read only by default.</p>
                        </v-col>
                        <v-col cols="12" v-if="newUser == false">
                          <v-select
                            v-model="editedItem.user_role"
                            :items="roles"
                            item-text="text"
                            item-value="value"
                            label="Role"
                            prepend-icon="mdi-account-lock"
                            clearable
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-menu
                            v-model="dp"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :value="computedValidityDate"
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                label="Expires on"
                                prepend-icon="mdi-calendar-alert"
                                hint="leave blank for permanent access"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.validity"
                              @input="dp = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" width="600px" v-if="sitePermission>1">
                <v-card class="text-center py-3">
                  <v-icon style="font-size: 64px" color="error" class="my-5">mdi-delete</v-icon>
                  <v-card-title>
                    <v-spacer />
                    <div class="text-center">
                      <h3 class="headline error--text mb-0">Remove access for {{editedItem.email}}</h3>
                      <h3 class="headline error--text mb-0">Are you Sure ?</h3>
                    </div>
                    <v-spacer />
                  </v-card-title>
                  <v-card-actions class="mt-5">
                    <v-spacer />
                    <v-btn @click="dialogDelete = false">Cancel</v-btn>
                    <v-btn @click="deleteItem" color="error">Confirm</v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.validity`]="{ item }">
            <span v-if="item.validity">
              {{ item.validity | moment($store.getters.dateFormat) }}
            </span>
            <span v-else>
              never
            </span>
          </template>
          <template v-slot:[`item.user_id`]="{ item }">
            {{ showUser(item.user_id) }}
          </template>
          <template v-slot:[`item.inviter_id`]="{ item }">
            {{ showUser(item.inviter_id) }}
          </template>
          <template v-slot:[`item.user_role`]="{ item }">
            {{ showRole(item.user_role) }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'SiteShares',
    data: () => ({
      search: '',
      loading: true,
      dialog: false,
      dialogDelete: false,
      saveDisabled: true,
      dp: false,
      users: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Acces To', value: 'user_id' },
        { text: 'Granted By', value: 'inviter_id' },
        { text: 'Role', value: 'user_role' },
        { text: 'Expires On', value: 'validity' },
        { text: 'Created at', value: 'created_at' }
        
      ],
      roles: [
        { text: 'Reader', value: 1 },
        { text: 'Contributor', value: 2 },
        { text: 'Full Access', value: 3 },
      ],
      editedIndex: -1,
      newUser: false,
      editedItem: {
        email: "",
        user_role: 0,
        validity: "",
        user_id: 0
      },
      defaultItem: {
        email: "",
        user_role: 0,
        validity: "",
        user_id: 0
      },
    }),

    computed: {
      computedValidityDate () {
        return this.editedItem.validity ? this.$moment(String(this.editedItem.validity)).format('DD/MM/YYYY') : ''
      },
      ...mapGetters(['site', 'StateUser', 'sitePermission']),
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', this.site.name + ' > Site Shares')
    },

    methods: {
      async initialize () {
        let response = await this.$http.get('/global/user_list')
        this.users = response.data.users
        this.loading = false
      },

      showUser (id) {
        let user = this.users.find(obj => { return obj.id === id })
        if (user) {return user.email}
      },

      showRole (int) {
        return this.roles.find(obj => { return obj.value === int }).text
      },

      editItem (item) {
        if (this.sitePermission > 1) {
          this.editedIndex = this.site.site_shares.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.editedItem.email = this.showUser(this.editedItem.user_id)
          this.saveDisabled = false
          this.dialog = true
        }
      },

      async deleteItem () {
        let response = await this.$http.delete('/site/site_shares', {params: {id: this.editedItem.id}})
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('deleteSiteShare', response.data.site_share.id)
          this.$toast.success("Site Share Deleted")
        }
        this.close()
      },

      close () {
        this.dialogDelete = false
        this.dialog = false
        this.newUser = false
        
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async findInvitee () {
        const User = new FormData()
        User.append("email", this.editedItem.email)
        let response = await this.$http.post('/global/find_user', User)
        if (response.data.user === "not found") {
          this.saveDisabled = false
          this.newUser = true
        } else {
          this.editedItem.user_id = response.data.user.id
          this.saveDisabled = false
          this.newUser = false
        }
      },

      async save () {
        if (this.newUser) {
          const User = new FormData()
          User.append("email", this.editedItem.email)
          User.append("site_id", this.site.id)
          User.append("site_name", this.site.name)
          let response = await this.$http.post('/tenant/users/invite_user', User)
          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            this.$toast.success("User Invited to sign up")
            this.editedItem.user_id = response.data.user.id
            this.editedItem.user_role = 1
            this.newUser = false
          }
        }

        const Share = new FormData()
        Share.append("user_role", this.editedItem.user_role)
        Share.append("validity", this.editedItem.validity)

        if (this.editedIndex > -1) {
          Share.append("id", this.editedItem.id);
          let response = await this.$http.put('/site/site_shares', Share)
          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            this.$store.commit('updateSiteShare', response.data.site_share)
            this.$toast.success("Site Share Updated");
          }
        } else {
          Share.append("user_id", this.editedItem.user_id)
          Share.append("site_id", this.site.id)
          Share.append("inviter_id", this.StateUser.id)
          let response = await this.$http.post('/site/site_shares', Share)
          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            this.$store.commit('createSiteShare', response.data.site_share)
            this.$toast.success("Site Share Created")
            this.initialize()
          }
        }
        this.close()
      },
    },
  }
</script>