<template>
  <v-container fluid class="companies-page mt-5">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filteredCompanies"
          :search="search"
          :loading="loading"
          sort-by="id"
          class="elevation-3"
          item-key="id"
          disable-pagination
          fixed-header
          height="79vh"
          hide-default-footer
          @click:row="editItem"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              height="90"
            >
              <span class="max150">
                <v-select
                  v-model="selectedFilter"
                  :items="filters"
                  label="Filter Companies"
                  color="primary"
                  hide-details
                  prepend-inner-icon="mdi-filter"
                  flat
                ></v-select>
              </span>            
              

              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                clearable
                flat
                color="primary"
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="600px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Company
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
                    <v-toolbar-title class="headline primary--text">{{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip top v-if="editedIndex > -1">
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="dialogDelete = true" color="error">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="save" color="primary">
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </template>
                      <span>Save</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="close" color="error">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Close</span>
                    </v-tooltip>
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Company Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-checkbox
                            v-model="editedItem.customer"
                            label="Customer"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-checkbox
                            v-model="editedItem.manufacturer"
                            label="Manufacturer"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-checkbox
                            v-model="editedItem.supplier"
                            label="Supplier"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" width="600px">
                <v-card class="text-center py-3">
                  <v-icon style="font-size: 64px" color="error" class="my-5">mdi-delete</v-icon>
                  <v-card-title>
                    <v-spacer />
                    <div class="text-center">
                      <h3 class="headline error--text mb-0">Delete {{editedItem.name}}</h3>
                      <h3 class="headline error--text mb-0">Are you Sure ?</h3>
                      <div>Deleted items cannot be retrieved</div>
                    </div>
                    <v-spacer />
                  </v-card-title>
                  <v-card-actions class="mt-5">
                    <v-spacer />
                    <v-btn @click="dialogDelete = false">Cancel</v-btn>
                    <v-btn @click="deleteItem" color="error">Confirm</v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.customer`]="{ item }">
            <v-icon :color="item.customer ? 'primary' : 'error'" small>{{ item.customer ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </template>
          <template v-slot:[`item.manufacturer`]="{ item }">
            <v-icon :color="item.manufacturer ? 'primary' : 'error'" small>{{ item.manufacturer ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </template>
          <template v-slot:[`item.supplier`]="{ item }">
            <v-icon :color="item.supplier ? 'primary' : 'error'" small>{{ item.supplier ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    name: 'Companies',
    data: () => ({
      companies: [],
      tenants: [],
      search: '',
      filters: ["all", "customer", "manufacturer", "supplier"],
      selectedFilter: "all",
      loading: true,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Customer', value: 'customer' },
        { text: 'Manufacturer', value: 'manufacturer' },
        { text: 'Supplier', value: 'supplier' },
        { text: 'Created at', value: 'created_at' },
        { text: 'Updated at', value: 'updated_at' },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        customer: false,
        manufacturer: false,
        supplier: false,
        tenant_id: 1
      },
      defaultItem: {
        name: '',
        customer: false,
        manufacturer: false,
        supplier: false,
        tenant_id: 1
      },
      
    }),

    computed: {
      ...mapGetters(['StateUser']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Company' : 'Edit Company'
      },
      filteredCompanies () {
        if (this.selectedFilter === "all") {
          return this.companies
        } else {
          return this.companies.filter(o => o[this.selectedFilter] == true )
        } 
        
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', 'Companies')
    },

    methods: {
      async initialize () {
        let response = await this.$http.get('/tenant/companies')
        this.companies = response.data.companies
        this.tenants = response.data.tenants
        this.loading = false
        this.editedItem.tenant_id = this.StateUser.tenant_id
        this.defaultItem.tenant_id = this.StateUser.tenant_id
      },

      editItem (item) {
        this.editedIndex = this.companies.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem () {
        this.companies.splice(this.editedIndex, 1)
        this.dialogDelete = false
        this.close()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        const Company = new FormData();
        Company.append("name", this.editedItem.name);
        Company.append("customer", this.editedItem.customer);
        Company.append("manufacturer", this.editedItem.manufacturer);
        Company.append("supplier", this.editedItem.supplier);
        Company.append("tenant_id", this.editedItem.tenant_id);
        if (this.editedIndex > -1) {
          Company.append("id", this.editedItem.id);
          let response = await this.$http.put('/tenant/companies', Company)
          Object.assign(this.companies[this.editedIndex], response.data.company)
          this.$toast.success("Company Updated");
        } else {
          let response = await this.$http.post('/tenant/companies', Company)
          this.companies.push(response.data.company)
          this.$toast.success("Company Created");
        }
        this.close()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .max150 {
    width: 180px !important;
  }
</style>