import Vue from 'vue'
import Router from 'vue-router'

import Layout from '@/layouts/Layout'
import AdminLayout from '@/layouts/AdminLayout'
import SiteLayout from '@/layouts/SiteLayout'
import V3SiteLayout from '@/layouts/V3SiteLayout'

// Pages
import Dashboard from '@/pages/Dashboard/Dashboard'
import Error from "@/pages/Error/Error"
import Login from "@/pages/Login/Login"
import LoginAs from "@/pages/Login/LoginAs"
import ResetPassword from "@/pages/Login/ResetPassword"
import InviteSignUp from "@/pages/Login/InviteSignUp"
import Profile from "@/pages/Profile/Profile"

import Sites from "@/pages/Sites/Sites"
import Site from "@/pages/Sites/Site"
import Devices from "@/pages/Sites/Devices"
import Locations from "@/pages/Sites/Locations"
import Zones from "@/pages/Sites/Zones"
import Schemas from "@/pages/Sites/Schemas"
import Connections from "@/pages/Sites/Connections"
import Attachments from "@/pages/Sites/Attachments"
import Contacts from "@/pages/Sites/Contacts"
import Shares from "@/pages/Sites/Shares"
import Access from "@/pages/Sites/Access"
import Vault from "@/pages/Sites/Vault"
import Logs from "@/pages/Sites/Logs"
import SupportSite from "@/pages/Sites/Support"
import SiteConfig from "@/pages/Sites/Config"

import V3Site from "@/pages/V3/Sites/Site"

import Support from "@/pages/Support/Support"
import SupportPortal from "@/pages/Support/SupportPortal"

import TenantCustomers from "@/pages/Tenant/Customers"
import TenantCompanies from "@/pages/Tenant/Companies"
import TenantProducts from "@/pages/Tenant/Products"
import TenantUsers from "@/pages/Tenant/Users"
import TenantLogs from "@/pages/Tenant/Logs"

import Admin from "@/pages/Admin/Admin"
import AdminTenants from "@/pages/Admin/Tenants"
import AdminUsers from "@/pages/Admin/Users"
import AdminCompanies from "@/pages/Admin/Companies"
import AdminProcats from "@/pages/Admin/Procats"
import AdminProducts from "@/pages/Admin/Products"
import AdminLogs from "@/pages/Admin/Logs"
import AdminJson from "@/pages/Admin/Json"

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/loginas',
      name: 'LoginAs',
      component: LoginAs
    },
    {
      path: '/reset_password',
      name: 'ResetPassword',
      component: ResetPassword
    },
    {
      path: '/invite_sign_up',
      name: 'InviteSignUp',
      component: InviteSignUp
    },
    {
      path: '/',
      redirect: 'login',
      name: 'Layout',
      component: Layout,
      children: 
      [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
        },
        {
          path: 'profile',
          name: 'Profile',
          component: Profile,
        },
        {
          path: 'sites',
          name: 'Sites',
          component: Sites,
        },
        {
          path: 'support',
          name: 'Support',
          component: Support,
        },
        {
          path: 'support/:tenant',
          name: 'SupportPortal',
          component: SupportPortal,
        },

      ],
    },
    {
      path: '/site/:site_id',
      name: 'SiteLayout',
      component: SiteLayout,
      children: 
      [
        {
          path: 'dashboard',
          name: 'Site',
          component: Site,
        },
        {
          path: 'devices',
          name: 'Devices',
          component: Devices,
        },
        {
          path: 'locations',
          name: 'Locations',
          component: Locations,
        },
        {
          path: 'zones',
          name: 'Zones',
          component: Zones,
        },
        {
          path: 'schemas',
          name: 'Schemas',
          component: Schemas,
        },
        {
          path: 'connections',
          name: 'Connections',
          component: Connections,
        },
        {
          path: 'attachments',
          name: 'Attachments',
          component: Attachments,
        },
        {
          path: 'contacts',
          name: 'Contacts',
          component: Contacts,
        },
        {
          path: 'shares',
          name: 'Shares',
          component: Shares,
        },
        {
          path: 'access',
          name: 'Access',
          component: Access,
        },
        {
          path: 'vault',
          name: 'Vault',
          component: Vault,
        },
        {
          path: 'logs',
          name: 'Logs',
          component: Logs,
        },
        {
          path: 'config',
          name: 'SiteConfig',
          component: SiteConfig,
        },
        {
          path: 'support',
          name: 'SupportSite',
          component: SupportSite
        }
      ]
    },
    {
      path: '/tenant',
      name: 'Tenant',
      component: Layout,
      children: 
      [
        {
          path: 'customers',
          name: 'TenantCustomers',
          component: TenantCustomers,
        },
        {
          path: 'companies',
          name: 'TenantCompanies',
          component: TenantCompanies,
        },
        {
          path: 'products',
          name: 'TenantProducts',
          component: TenantProducts,
        },
        {
          path: 'users',
          name: 'TenantUsers',
          component: TenantUsers,
        },
        {
          path: 'logs',
          name: 'TenantLogs',
          component: TenantLogs,
        },
      ]
    },
    {
      path: '*',
      name: 'Error',
      component: Error
    },
    {
    path: '/admin',
    name: 'Admin',
    component: AdminLayout,
    children: 
      [
        {
          path: 'dashboard',
          name: 'AdminDashboard',
          component: Admin,
        },
        {
          path: 'tenants',
          name: 'AdminTenants',
          component: AdminTenants,
        },
        {
          path: 'users',
          name: 'AdminUsers',
          component: AdminUsers,
        },
        {
          path: 'companies',
          name: 'AdminCompanies',
          component: AdminCompanies,
        },
        {
          path: 'procats',
          name: 'AdminProcats',
          component: AdminProcats,
        },
        {
          path: 'products',
          name: 'AdminProducts',
          component: AdminProducts,
        },
        {
          path: 'logs',
          name: 'AdminLogs',
          component: AdminLogs,
        },
        {
          path: 'json',
          name: 'AdminJson',
          component: AdminJson,
        },
      ]
    },
    {
      path: '/v3/site/:site_id',
      name: 'V3SiteLayout',
      component: V3SiteLayout,
      children: 
      [
        {
          path: 'dashboard',
          name: 'V3Site',
          component: V3Site,
        },
        
      ]
    },
  ],
});
