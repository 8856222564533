import axios from 'axios';

const state = {
  tickets: [],
  ticket: {},
  users: [],
  ticket_statuses: ['new', 'open', 'in progress', 'in waiting', 'intervention booked', 'closed'],
  ticket_priorities: ['Low', 'Mid', 'High'],
  ticket_types: ['Problem', 'Question', 'New Feature']
};
const getters = {
  tickets: state => state.tickets,
  ticket: state => state.ticket,
  users: state => state.users,
  ticket_statuses: state => state.ticket_statuses,
  ticket_priorities: state => state.ticket_priorities,
  ticket_types: state => state.ticket_types,
};
const actions = {
  async getTenantSupport({commit}) {
    let response = await axios.get('/support/tenant')
    await commit('setTickets', response.data.tickets)
    await commit('setUsers', response.data.users)
  }
};
const mutations = {
  setTickets (state, tickets) {
    state.tickets = tickets
  },
  setUsers (state, users) {
    state.users = users
  },
  setTicket (state, ticket) {
    state.ticket = ticket
  },
  ceateTicket (state, ticket) {
    state.tickets.push(ticket)
  },
  deleteTicket (state, id) {
    state.tickets = state.tickets.filter(ticket => ticket.id !== id)
  },
  updateTicket (state, ticket) {
    Object.assign(state.tickets[state.tickets.findIndex(a => a.id === ticket.id)], ticket)
  },
  createTicketMessage (state, message) {
    state.ticket.messages.push(message)
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};