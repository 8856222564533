<template>
  <v-container fluid class="sites-page">
    <v-row>
      <v-col cols="12">
        <v-toolbar
          flat
          height="90"
        >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            clearable
            flat
            color="primary"
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="800px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                v-if="StateUser.role > 3"
              >
                New Site
              </v-btn>
            </template>
            <v-card>
              <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
                <v-toolbar-title class="headline primary--text">New Site</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="save" color="primary">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>Save</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="close" color="error">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Close</span>
                </v-tooltip>
              </v-toolbar>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                      v-model="editedItem.name"
                      label="Name*"
                      required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="editedItem.company_id"
                        :items="customers"
                        label="Customer*"
                        item-text="name"
                        item-value="id"
                        ref="customerSelect"
                        :search-input.sync="editedCustomer.name"
                      >
                        <template v-slot:append-item>
                          <v-dialog
                            v-model="dialogCustomer"
                            max-width="600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="ml-4" color="primary" outlined small v-bind="attrs" v-on="on">create new Customer</v-btn>
                            </template>
                            <v-card>
                              <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
                                <v-toolbar-title class="headline primary--text">Create New Customer</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="saveCustomer" color="primary">
                                      <v-icon>mdi-content-save</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Save</span>
                                </v-tooltip>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="closeCustomer" color="error">
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Close</span>
                                </v-tooltip>
                              </v-toolbar>

                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-text-field
                                        v-model="editedCustomer.name"
                                        label="Customer Name"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="9">
                      <v-text-field
                      label="Street"
                      v-model="editedItem.street"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                      label="Street Number"
                      v-model="editedItem.street_number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                      label="Postal Code"
                      v-model="editedItem.zip"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="9">
                      <v-text-field
                      label="City"
                      v-model="editedItem.city"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="editedItem.country"
                        :items="countries"
                        label="Country"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <s3-file-uploader :obj="editedItem" fieldName="image" directory="sites" label="Upload Picture" @located="setFileLocation"/>
                    </v-col>
                    <v-col cols="12">
                      <v-switch
                        v-model="editedItem.site_status"
                        inset
                        :label="editedItem.site_status ? 'Active' : 'Inactive'"
                      ></v-switch>
                    </v-col>
                    
                  </v-row>
                </v-container>
              </v-card-text>

            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-data-iterator
          :items="sites"
          :search="search"
          :loading="loading"
          item-key="id"
          disable-pagination
          hide-default-footer
          sort-by="updated_at"
          sort-desc
        >
          <template v-slot:default="{ items }">
            <v-row>
              <v-col
                v-for="item in items"
                :key="item.id"
                cols="6"
                md="2"
              >
                <v-card
                  class="mx-auto my-6"
                  :to="'/site/'+item.id+'/dashboard'"
                >
                  <v-img
                    v-if="item.image"
                    height="200"
                    :src="item.image"
                  ></v-img>
                  <v-img
                    v-else
                    height="200"
                    src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/placeholder-image.png"
                    contain
                  ></v-img>
                  <v-card-text>
                    <div class="my-4 text-center">
                      {{item.name}}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <template v-slot:loading>
            <v-row>
              <v-col cols="12" class="text-center mt-12">
                <div>
                  <v-progress-circular
                    :size="80"
                    color="primary"
                    indeterminate
                  ></v-progress-circular> 
                </div>
                <div class="mt-8">
                  Loading Sites...
                </div>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import S3FileUploader from '@/components/S3Upload/S3FileUploader';

export default {
  name: "Sites",
  components: {
    S3FileUploader
  },
  data: () => ({
    sites: [],
    shares: [],
    customers: [],
    countries: ['Belgium', 'France', 'Luxemburg', 'Netherlands'],
    search: '',
    tenant_id: '',
    loading: true,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      name: '',
      tenant_id: '',
      company_id: '',
      site_status: true,
      gps: '',
      country: '',
      zip: '',
      city: '',
      street: '',
      street_number: ''
    },
    defaultItem: {
      name: '',
      tenant_id: '',
      company_id: '',
      site_status: true,
      gps: '',
      country: '',
      zip: '',
      city: '',
      street: '',
      street_number: ''
    },
    dialogCustomer: false,
    editedCustomer: {
      name: '',
    },

  }),
  computed: {
    ...mapGetters(['UserRole', 'StateUser']),
  },
  created () {
    this.initialize()
    this.$store.commit('setHeaderTitle', 'Sites')
  },
  methods: {
    async initialize () {
      let response = await this.$http.get('/site/index')
      this.sites = response.data.sites
      this.customers = response.data.customers
      this.tenant_id = response.data.tenant
      this.loading = false
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    setFileLocation (value) {
      this.editedItem.image = value
    },
    async save () {
      const Site = new FormData();
      Site.append("name", this.editedItem.name);
      Site.append("company_id", this.editedItem.company_id);
      Site.append("image", this.editedItem.image);
      Site.append("site_status", this.editedItem.site_status);
      Site.append("gps", this.editedItem.gps);
      Site.append("country", this.editedItem.country);
      Site.append("zip", this.editedItem.zip);
      Site.append("city", this.editedItem.city);
      Site.append("street", this.editedItem.street);
      Site.append("street_number", this.editedItem.street_number);
      Site.append("tenant_id", this.tenant_id);
      let response = await this.$http.post('/site/sites', Site)
      this.sites.push(response.data.site)
      this.$toast.success("Site Created");
      this.close()
    },

    async saveCustomer () {
      const Company = new FormData();
      Company.append("name", this.editedCustomer.name);
      Company.append("customer", true);
      Company.append("manufacturer", false);
      Company.append("supplier", false);
      Company.append("tenant_id", this.StateUser.tenant_id);
      let response = await this.$http.post('/tenant/companies', Company)
      if (response.data.error) {
        Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
      } else {
        this.customers.push(response.data.company)
        this.editedItem.company_id = response.data.company.id
        this.$refs.customerSelect.blur()
        this.$toast.success("Company Created")
      }
      this.closeCustomer()
    }, 

    closeCustomer () {
      this.dialogCustomer = false
      this.$nextTick(() => {
        this.editedCustomer.name = ""
      })
    },
  },
};
</script>