<template>
  <v-sheet @click="selectFile" class="cpointer mt-5">
    <v-img
      v-if="newSvg"
      contain
      :src="newSvg"
      height="250px"
    ></v-img>
    <v-img
      v-else
      contain
      src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/floorplan_upload.jpg"
      height="250px"
    ></v-img>
    <input type="file" ref="svg" hidden @change="uploadFile">
  </v-sheet>
</template>

<script>
  import S3 from 'aws-s3-pro';
  import {mapGetters} from 'vuex'

  export default {
    name: 'svgUpload',
    data() {
      return {
        isLoading: false,
        newSvg: null
      }
    },
    props: ['floor', 'directory', 'site_id'],
    computed: {
      ...mapGetters(['s3Config']),
      S3Client() {
        return new S3(this.s3Config)
      }
    },
    methods: {
      uploadFile() {
        if (process.env.NODE_ENV == "production") {
          this.directory = this.directory + "/" + this.site_id + "/" + this.floor.id
        }
        this.S3Client.config.dirName = this.directory

        let file = this.$refs.svg.files[0]
        let parts = file.name.split('.')
        let filetype = parts[parts.length - 1]

        if (filetype.toLowerCase() === 'svg') {
          this.isLoading = true
          this.S3Client
            .uploadFile(file, "floorplan_" + this.floor.id)
            .then(data => {
              this.$emit('located', data.location) 
              this.isLoading = false
              this.newSvg = data.location
            })
        } else {
          this.$toast.error("Wikibuild only accepts SVG format for floorplans")
        }
        
      },
      selectFile() {
        let fileInputElement = this.$refs.svg
        fileInputElement.click();
      }
    },

  }
</script>