<template>
  <v-card @click="selectFile">
    <v-img
      v-if="newImage"
      contain
      :src="newImage"
      height="250px"
    ></v-img>
    <v-img
      v-else-if="obj.image"
      contain
      :src="obj.image"
      height="250px"
    ></v-img>
    <v-img
      v-else
      contain
      src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/dev/global/9588889690500437.png"
      height="250px"
    ></v-img>
    <input type="file" ref="image" hidden @change="uploadFile">
  </v-card>
</template>

<script>
  import S3 from 'aws-s3';
  import {mapGetters} from 'vuex'

  export default {
    name: 'imageUpload',
    data() {
      return {
        isLoading: false,
        newImage: null
      }
    },
    props: ['obj', 'directory'],
    computed: {
      ...mapGetters(['s3Config']),
      S3Client() {
        return new S3(this.s3Config)
      },
    },
    methods: {
      uploadFile() {
        this.S3Client.config.dirName = this.directory
        let file = this.$refs.image.files[0]
        this.isLoading = true
        this.S3Client
          .uploadFile(file, this.newFileName())
          .then(data => {
            this.$emit('located', data.location) 
            this.isLoading = false
            this.newImage = data.location
          })
        this.$forceUpdate()
      },

      selectFile() {
        let fileInputElement = this.$refs.image
        fileInputElement.click();
      },

      newFileName() {
        return (Math.random().toString().slice(2))
      },

      clear() {
        this.isLoading = false
        this.newImage = null
        this.$refs.image.value = null
      }
    },

  }
</script>