<template>
  <v-container fluid class="config-page">
    <v-row class="mt-5">
      <v-col cols="4">
        <site-edit></site-edit>
      </v-col>
      <v-col cols="4">
        <zones-layers></zones-layers>
      </v-col>
      <v-col cols="4">
        <device-status></device-status>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  import ZonesLayers from '@/components/Site/ZonesLayers.vue'
  import DeviceStatus from '@/components/Site/DeviceStatus.vue'
  import SiteEdit from '@/components/Site/SiteEdit.vue'

  export default {
    name: "Config",
    components: {
      ZonesLayers,
      DeviceStatus,
      SiteEdit
    },
    mounted() {
      this.$store.commit('setHeaderTitle', this.site.name + ' > Config')
    },
    computed: {
      ...mapGetters(['site']),
    },
  }
</script>