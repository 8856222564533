<template>
  <div>
    <v-container fluid>
      <v-row class="mt-3">
        <v-col cols="12">
          <v-data-iterator
            :items="tickets"
            :search="search"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:header>
              <v-toolbar class="mb-3">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
                  open-on-hover
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-menu</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-list
                      nav
                      dense
                    >
                      <v-list-item
                        @click="createTicketDialog = true"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-plus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          New Ticket
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                  </v-card>
                </v-menu>

                <v-toolbar-title class="ml-7 primary--text">Tickets</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-text-field
                  v-model="search"
                  clearable
                  flat
                  color="primary"
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Search"
                  class="mr-5"
                ></v-text-field>
              </v-toolbar>
            </template>

            <template v-slot:default="props">
              <v-card
                v-for="ticket in props.items"
                :key="ticket.id"
                class="mb-5"
              >
                <v-card-text>
                  <v-row class="d-flex align-center">
                    <v-col cols="2">
                      <h6 class="mb-1">{{ ticket.site.name }}</h6>
                      <span>{{ ticket.assigner.fname }} {{ticket.assigner.lname}}</span>
                    </v-col>
                    <v-col cols="7">
                      {{ ticket.subject }}
                    </v-col>
                    <v-col cols="1">
                      <v-chip
                        class="ma-2"
                        color="orange"
                        text-color="white"
                      >
                        {{ ticket.ticket_status }}
                        <v-icon right>
                          mdi-check
                        </v-icon>
                      </v-chip>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">
                      <v-icon class="primary--text" @click="openTicket(ticket)">mdi-eye</v-icon>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>

          </v-data-iterator>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="createTicketDialog" width="80%">
      <create-ticket v-on:closeCreateTicketDialog="createTicketDialog = false"/>
    </v-dialog>
    <v-dialog
      v-model="sheet"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <view-ticket v-on:closeSheet="sheet = false"/>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CreateTicket from '@/components/Support/CreateTicket.vue'
  import ViewTicket from '@/components/Support/viewTicket.vue'

  export default {
    name: "Support",
    components: {
      CreateTicket,
      ViewTicket
    },
    data () {
      return {
        loading: true,
        menu: false,
        search: '',
        sheet: false,
        createTicketDialog: false,
      }
    },
    computed: {
      ...mapGetters(['tickets'])
    },
    mounted() {
      this.initialize()
      this.$store.commit('setHeaderTitle', 'Support')
    },
    methods: {
      initialize () {
        this.$store.dispatch('getTenantSupport')
        this.loading = false
      },
      openTicket (ticket) {
        this.$store.commit('setTicket', ticket)
        this.sheet = true
      },
    },
  }
</script>