const state = {
  planVisible: true,
  locationsVisible: true,
  zonesVisible: false,
  labelsVisible: false, 
  labelFontSize: 10,
  contextMenu: false,
  x: 0,
  y: 0,
};

const getters = {
  planVisible: state => state.planVisible,
  locationsVisible: state => state.locationsVisible,
  zonesVisible: state => state.zonesVisible,
  labelsVisible: state => state.labelsVisible,
  labelFontSize: state => state.labelFontSize,
  contextMenu: state => state.contextMenu,
  x: state => state.x,
  y: state => state.y,
};

const mutations = {

  toggleLayer (state, data) {
    switch (data) {
      case 0:
        state.planVisible = !state.planVisible
        break
      case 1:
        state.locationsVisible = !state.locationsVisible
        if (state.locationsVisible) {state.zonesVisible = false}
        break
      case 2:
        state.zonesVisible = !state.zonesVisible
        if (state.zonesVisible) {state.locationsVisible = false}
        break
      case 3:
        state.labelsVisible = !state.labelsVisible
        break
    }

  },
  hideContextMenu (state) {
    state.contextMenu = false
  }, 

  showContextMenu (state, payload) {
    state.x = payload.x
    state.y = payload.y
    state.contextMenu = true
  }

  
};

export default {
  state,
  getters,
  mutations
};