import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'font-awesome/css/font-awesome.css'

import {
  VTreeSelect,
  VCascader,
  VDataGridSelect,
  VDateTimeSelect,
  VAdvDataTable,
  VMdView } from 'vuetify-toolkit/vuetify-toolkit.umd'

Vue.use(Vuetify,{
    VTreeSelect,
    VCascader,
    VDataGridSelect,
    VDateTimeSelect,
    VAdvDataTable,
    VMdView
});

export default new Vuetify({
    icons: {
      iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
              primary: "#2AAD74",
              secondary: "#FF5C93",
              error: "#E53935",
              warning: "#FFC260",
              success: "#2AAD74",
              info: "#1E88E5",
              textColor: "#B9B9B9",
              iconColor: "#FFFFFF",
              grayBold: '#4a4a4a'
            }
        }
    }
});
