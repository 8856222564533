<template>
  <v-app>
    <Header />
    <SiteSidebar />
    <v-main class="content">
      <router-view />
    </v-main>
    <v-dialog v-model="deviceDialog" min-width="90%" transition="dialog-bottom-transition" @keydown.esc="closeDeviceDialog">
      <show-device/>
    </v-dialog>
    <v-dialog v-model="createDeviceDialog" min-width="90%" transition="dialog-bottom-transition" @keydown.esc="closeDeviceDialog">
      <create-device/>
    </v-dialog>
    <v-dialog v-model="floorplanDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <show-floorplan/>
    </v-dialog>
  </v-app>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Header from '@/components/Header/Header'
  import SiteSidebar from '@/components/Sidebar/SiteSidebar'
  import ShowDevice from '@/components/Device/ShowDevice.vue'
  import CreateDevice from '@/components/Device/CreateDevice.vue'
  import ShowFloorplan from '@/components/Location/ShowFloorplan.vue'
  import './Layout.scss';

  export default {
    name: 'SiteLayout',
    components: { Header, SiteSidebar, ShowDevice, ShowFloorplan, CreateDevice },
    computed: {
      ...mapGetters(['deviceDialog', 'createDeviceDialog', 'floorplanDialog']),
    },
    methods: {
      closeDeviceDialog() {
        this.$store.commit('closeDeviceDialog')
      }
    }
  };
</script>

<style src="./Layout.scss" lang="scss" />