const state = {
  config: {
    bucketName: 'wtec-uploads',
    dirName: 'dev', /* change before production remove dev/ */
    region: 'eu-central-1',
    accessKeyId: 'AKIA4QDQXYHYWNVOCOXH',
    secretAccessKey: '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',
    s3Url: 'https://wtec-uploads.s3.eu-central-1.amazonaws.com', /* optional */
  }
};

const getters = {
  s3Config: state => state.config,
};

export default {
  state,
  getters
};