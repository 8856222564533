<template>
  <div>
    <v-sheet class="pa-4">
      <v-text-field
        v-model="search"
        clearable
        flat
        color="primary"
        hide-details
        prepend-inner-icon="mdi-magnify"
        label="Search Zones"
        @input="handleSearch"
      ></v-text-field>
      <v-row class="mt-3">
        <v-col cols="6">
          <v-select
            v-model="floorOrder"
            :items="orderBy"
            label="Order Floors By"
            @change="orderFloors"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="zoneOrder"
            :items="orderBy"
            label="Order Zones By"
            @change="orderZones"
          ></v-select>
        </v-col>
      </v-row>
    </v-sheet>
    <v-card-text>
      <v-treeview
        ref="tree"
        :items="site.floors"
        item-children="zones"
        activatable
        :search="search"
        :open.sync="open"
        :active="active"
        return-object
        hoverable
      >
        <template v-slot:prepend="{ item }">
          <div class="cpointer" @click="showItem(item)">
            <v-icon v-if="item.zones">mdi-layers-triple</v-icon>
            <v-icon v-else>mdi-table</v-icon>
          </div>
        </template>
        <template v-slot:label="{ item }">
          <v-hover v-slot:default="{ hover }">
            <div class="d-flex justify-space-between cpointer" @click.stop="showItem(item)">
              <span v-if="item.code.length > 0">{{item.name}} || {{item.code}}</span>
              <span v-else>{{item.name}}</span>
              <span v-if="hover && sitePermission>1">
                <v-icon v-if="item.zones" @click.stop="addZone(item.id)" class="mr-3">mdi-plus</v-icon>
                <v-icon @click.stop="editItem(item)">mdi-pencil</v-icon>
              </span>
            </div>
          </v-hover>
        </template>
      </v-treeview>
      <v-btn text color="success" class="mt-5" @click="addFloor" v-if="sitePermission>1">
        <v-icon left> mdi-plus </v-icon>
        add floor
      </v-btn>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      max-width="500"
      transition="dialog-bottom-transition"
      v-if="sitePermission>1"
    >
      <v-card>
        <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
          <v-toolbar-title class="headline primary--text">{{itemAction}} {{itemType}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="saveItem" color="primary">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>Save</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="closeDialog" color="error">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Name"
            v-model="item.name"
          ></v-text-field>
          <v-text-field
            label="Code"
            v-model="item.code"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
    
export default {
  name: "ZoneTreeview",
  data() {
    return {
      open: [],
      active: [],
      search: null,
      item: {},
      itemAction: "",
      itemType: "",
      dialog: false,
      orderBy: ["id ↓", "id ↑", "name ↓", "name ↑", "code ↓", "code ↑"],
      floorOrder: "",
      zoneOrder: "",
    }
  },
  computed: {
    ...mapGetters(['site', 'floor', 'zone', 'sitePermission']),
  },

  watch: {
    floor(){
      this.active.push(this.floor)
    },
    zone() {
      this.active.push(this.zone)
    }
  },

  methods: {
    // opens treeview on found zones
    handleSearch(input) {
      if (input) {
        this.$refs.tree.updateAll(true)
      } else {
        this.$refs.tree.updateAll(false)
      }
    },
    editItem(item) {
      this.item = item
      this.itemAction = "Edit"
      if (item.zones) {
        this.itemType = "Floor"
      } else {
        this.itemType = "Zone"
      }
      this.dialog = true
    },
    // Handles create and update for both Floor and Zone
    async saveItem() {
      if (this.itemType === "Floor") {
        const Floor = new FormData()
        Floor.append("name", this.item.name)
        Floor.append("code", this.item.code)

        if (this.itemAction === "New") {
          Floor.append("site_id", this.site.id)
          let response = await this.$http.post('/site/floors', Floor)
          this.$store.commit('createFloor', response.data.floor)
          this.$toast.success("Floor Created")
        } else if (this.itemAction === "Edit") {
          Floor.append("id", this.item.id)
          let response = await this.$http.put('/site/floors', Floor)
          this.$store.commit('updateFloor', response.data.floor)
          this.$toast.success("Floor Updated")
        }
      } else if (this.itemType === "Zone") {
        const Zone = new FormData()
        Zone.append("name", this.item.name)
        Zone.append("code", this.item.code)

        if (this.itemAction === "New") {
          Zone.append("floor_id", this.item.floor_id)
          let response = await this.$http.post('/site/zones', Zone)
          this.$store.commit('createZone', response.data.zone)
          this.$toast.success("Zone Created")
        } else if (this.itemAction === "Edit") {
          Zone.append("id", this.item.id)
          let response = await this.$http.put('/site/zones', Zone)
          this.$store.commit('updateZone', response.data.zone)
          this.$toast.success("Zone Updated")
        }
      }
      this.closeDialog()
    },

    // Sets floor or floor and zone
    showItem(item) {
      this.item = item
      this.$store.commit('unsetFloorLocationZone')
      this.$nextTick(() => {
        if (item.zones) {
          this.itemType = "Floor"
          this.$store.commit('setFloor', item.id)
        } else {
          this.itemType = "Zone"
          this.$store.commit('setZone', item)
        }
      })
    },
    addZone(floor_id) {
      this.item = {
        floor_id: floor_id,
        name: "",
        code: "",
      }
      this.itemAction = "New"
      this.itemType = "Zone"
      this.dialog = true
    },
    addFloor() {
      this.item = {
        site_id: this.site.id,
        name: "",
        code: "",
      }
      this.itemAction = "New"
      this.itemType = "Floor"
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    findZone(id) {
      let loc = this.floor.zones.find((e) => e.id == id)
      this.showItem(loc)
    },
    orderFloors() {
      this.$store.commit('orderFloors', this.floorOrder )
    }, 
    orderZones() {
      this.$store.commit('orderZones', this.zoneOrder )
    },
  },
  mounted() {
    //select first floor in order to have something to show in zonecard
    if (this.site.floors.length > 0) {
      this.showItem(this.site.floors[0])
      this.active.push(this.site.floors[0])
    }
  },
}
</script>

<style lang="scss">
  .cpointer {
    cursor: pointer;
  }
</style>