<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            elevation="2"
            fab
            icon
            outlined
            class="absolute-fab"
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <span>{{mouseX}} , {{mouseY}}</span>
          <inline-svg 
              :src="floor.svg"
              id="floorplan"
              @loaded="createFloorplan"
            ></inline-svg>
          <div id="locCard"></div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import * as d3 from 'd3'
  import InlineSvg from 'vue-inline-svg';

  export default {
    name: 'showFloorplan',
    components: {
      InlineSvg
    },
    data: () => ({
      editing: false,
      zooming: false,
      svg: null,
      initialViewBox: [],
      viewBox: [],
      mouseX: 0,
      mouseY: 0,
      mouseXabs: 0,
      mouseYabs: 0,
      points: [], 
      lastPoint: null,
      startPoint: null,
      polygon: null,
    }),
    computed: {
      ...mapGetters(['site', 'device', 'floor', 'location']),
      polyLocations() {
        if (this.floor.id) {
          return this.floor.locations.filter( o => o.polygon )
        } else {
          return []
        }
      },
      nonPolyLocations() {
        if (this.floor.id) {
          return this.floor.locations.filter( o => o.polygon == null )
        } else {
          return []
        }
      }
    },
    created () {
      this.initialize()
    },
    methods: {
      initialize() {
      },
      closeDialog() {
        this.$store.commit('closeFloorplanDialog')
      },
      showDevice(item) {
        this.$store.commit('openDeviceDialog', item.id)
      },
      showFloorplan() {
        this.$store.commit('openFloorplanDialog')
      },
      drawFloor() {
        let that = this
        document.querySelectorAll(".location-poly").forEach(el => el.remove());
        if (this.floor.locations) {
          that.svg.selectAll('polygon')
            .data(that.polyLocations)
            .enter()
            .append("polygon")
            .attr("points", function(d) {return d.polygon})
            .attr("id", function(d) { return "zone_poly_"+d.id})
            .attr("fill", "#2AAD74")
            .attr("fill-opacity", "0.3")
            .classed("location-poly", true)
            .on('mouseover', function (d) {
                  d3.select(this).transition()
                      .duration('50')
                      .attr('fill-opacity', '1')
                  setTimeout(() => {
                    that.locCard.transition()
                              .duration(50)
                              .style("opacity", 1)
                              .style("left", (that.mouseXabs) + "px")
                              .style("top", (that.mouseYabs) + "px")
                    that.locCard.html(d.target.__data__.name)
                  }, 50) 
            })
            .on('mouseout', function (d, i) {
                  d3.select(this)
                    .transition()
                    .duration('50')
                    .attr('fill-opacity', '0.3');
                  that.locCard.transition()
                              .duration(50)
                              .style("opacity", 0);
          })
        }    
        this.drawLocation()
      },
      drawLocation() {
        let that = this
        document.querySelectorAll(".location-poly-selected").forEach(el => el.remove());
        if (this.location.polygon) {
          that.svg.append("polygon")
            .attr("points", this.location.polygon)
            .attr("fill", "none")
            .attr("stroke", "red")
            .attr("stroke-width", "3")
            .classed("location-poly-selected", true);
        }
      },
      createFloorplan () {
        let that = this
        this.svg = d3.select("#floorplan")
        let h = this.svg.attr("height")
        let w = this.svg.attr("width")
        this.initialViewBox = [0, 0, w, h]
        this.viewBox = [0, 0, w, h]
        this.svg.attr("viewBox", this.viewBox.join(" ")).attr("height", "700px").attr("width", "100%")

        this.svg.on('mousemove', function(event) {
          let coords = d3.pointer(event);
          that.mouseX = Math.round(coords[0])
          that.mouseY = Math.round(coords[1])
          let absCoords = d3.pointer(event, "body")
          that.mouseXabs = Math.round(absCoords[0])
          that.mouseYabs = Math.round(absCoords[1])
        });

        this.svg.on('click', function () {
          if (that.editing) {
            that.lastPoint = that.svg.append("circle")
              .attr("cx", that.mouseX)
              .attr("cy", that.mouseY)
              .attr("r", 5)
              .attr("class", "tempdots")
              .style("fill", "red")
              .style("cursor", 'pointer');

            that.points.push(that.mouseX + ',' + that.mouseY);

            if (!that.startPoint) {
              that.startPoint = that.lastPoint

              that.startPoint.on('click', function () {
                  that.polygon = that.points.join(" ");
                  that.svg.append("polygon")
                    .attr("points", that.polygon)
                    .attr("fill", "#2AAD74")
                    .classed("location-poly", true);
                  setTimeout(() => {
                    document.querySelectorAll(".tempdots").forEach(el => el.remove());
                    that.startPoint = null
                    that.lastPoint = null
                    that.points = []
                  }, 300)
                  that.savePolygon()
              });
            };
          }
        })

        this.locCard = d3.select("#locCard")
        this.drawFloor()
        this.drawLocation()
      },
      toggleZoom() {
        if (this.location.polygon) {
          if (this.zooming) {
            this.svg.attr("viewBox", this.initialViewBox.join(" "))
            this.zooming = false
          } else {
            let zoomBox = this.findViewBox(this.location.polygon)
            this.svg.attr("viewBox", zoomBox)
            this.zooming = true
          }
        }
      },
      findViewBox(str) {
        let arr = str.split(/[ ,]+/);
        let arrX = [];
        let arrY = [];
        for (let i = 0; i < arr.length; i++) {
          if ((i + 2) % 2 == 0) {
            arrX.push(parseInt(arr[i]));
          }
          else {
            arrY.push(parseInt(arr[i]));
          }
        }
        let xMin = Math.min(...arrX);
        let yMin = Math.min(...arrY);
        let xMax = Math.max(...arrX);
        let yMax = Math.max(...arrY);
        let width = xMax - xMin;
        let height = yMax - yMin;
        let viewBox = xMin + " " + yMin + " " + width + " " + height;
        return viewBox;
      },
      async savePolygon() {
        const Location = new FormData();
        Location.append("polygon", this.polygon);
        Location.append("id", this.location.id)
        let response = await this.$http.put('/site/locations', Location)
        this.$store.commit('updateLocation', response.data.location)
        this.$store.commit('setLocation', response.data.location)
        this.$toast.success("Location Updated")
        this.editing = false
        this.polygon = null
        this.drawFloor()
        this.drawLocation()
      },
    },
    mounted() {
      this.$store.commit('setHeaderTitle', this.site.name + ' > Locations')
      this.$store.commit('unsetFloorLocationZone')
    },
  };
</script>

<style lang="scss" scoped>
  .absolute-fab {
    position: absolute;
    right: 30px;
    top: 30px;
  }

  #locCard {
     position: fixed;
     text-align: center;
     padding: .5rem;
     background: #FFFFFF;
     border: 1px solid #000; 
     opacity: 0;
  }
</style>