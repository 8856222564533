<template>
  <div>
    <plan></plan>
    <treeview></treeview>
  </div>
  
</template>

<script>
import {mapGetters} from 'vuex'
import Plan from '@/components/V3/Floorplan/Plan'
import Treeview from '@/components/V3/Floorplan/Treeview'
export default {
  name: "V3SiteDashboard",
  components: {
    Plan,
    Treeview
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['site', 'siteLogs']),
  },
  mounted() {
  },
  methods: {
  },
};
</script>