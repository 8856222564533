<template>
  <v-container fluid class="procats-page">
    <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
      <h1 class="page-title">Wikibuild Support Page</h1>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p>
          Comming Soon
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "Support",
  data() {
    return {
     
    };
  },
  methods: {
    
  },
  mounted() {
    this.$store.commit('setHeaderTitle', 'Support')
  },
};
</script>