const state = {
  dateFormat: "DD/MM/YYYY",
  dateTimeFormat: "DD/MM/YYYY HH:mm",
  timeFormat: "HH:mm",
  toastOptions:  {
    position: "top-right",
    timeout: 3000,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: true,
    hideProgressBar: true,
    closeButton: "button",
    icon: true
  },
  adminUserRoles: [
    {value: 1, text: "Reader"},
    {value: 2, text: "Contributor"},
    {value: 3, text: "Tenant Manager"},
    {value: 4, text: "Admin"},
    {value: 5, text: "Superadmin"}
  ],
  tenantUserRoles: [
    {value: 1, text: "Reader"},
    {value: 2, text: "Contributor"},
    {value: 3, text: "Tenant Manager"},
  ]
};
const getters = {
  dateFormat: state => state.dateFormat,
  dateTimeFormat: state => state.dateTimeFormat,
  timeFormat: state => state.timeFormat,
  toastOptions: state => state.toastOptions,
  adminUserRoles: state => state.adminUserRoles,
  tenantUserRoles: state => state.tenantUserRoles,
};

export default {
  state,
  getters
};