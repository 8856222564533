<template>
  <v-container fluid class="site-dashboard-page">
    <v-row>
      <v-col cols="9">
        <v-row>
          <v-col cols="4">
            <v-img
              v-if="site.image"
              height="300"
              :src="site.image"
              contain
            ></v-img>
            <v-img
              v-else
              height="300"
              src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/placeholder-image.png"
              contain
            ></v-img>
          </v-col>
          <v-col cols="8" v-if="site.name">
            <!-- needs to be removed for site_id the Arch -->
            <h1 class="page-title mt-10" v-if="UserRole === 'Superadmin'"><a :href="v3Link">{{site?.name}}</a></h1>
            <h1 class="page-title mt-10" v-else-if="site.id === 124"><a :href="v3Link">{{site?.name}}</a></h1>
            <h1 class="page-title mt-10" v-else >{{site?.name}}</h1>
            <h4 class="primary--text mt-6 mb-0">Customer: {{site?.company.name}}</h4>
            <h4 class="primary--text mt-0">Tenant: {{site?.tenant.name}}</h4>
            
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col cols="3">
            <v-card class="text-center" to="devices">
              <v-card-text>
                <div>
                  <v-icon style="font-size: 64px" color="primary">mdi-devices</v-icon>
                </div>
                <h1 class="page-title primary--text mb-5">Devices</h1>
                <span class="font-weight-medium" style="font-size: 36px">
                  {{deviceCount}}
                </span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card class="text-center" to="locations">
              <v-card-text>
                <div>
                  <v-icon style="font-size: 64px" color="primary">mdi-floor-plan</v-icon>
                </div>
                <h1 class="page-title primary--text mb-5">Locations</h1>
                <span class="font-weight-medium" style="font-size: 36px">
                  {{locationCount}}
                </span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card class="text-center" to="contacts">
              <v-card-text>
                <div>
                  <v-icon style="font-size: 64px" color="primary">mdi-card-account-details</v-icon>
                </div>
                <h1 class="page-title primary--text mb-5">Contacts</h1>
                <span class="font-weight-medium" style="font-size: 36px">
                  {{contactCount}}
                </span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card class="text-center" to="access">
              <v-card-text>
                <div>
                  <v-icon style="font-size: 64px" color="primary">mdi-security</v-icon>
                </div>
                <h1 class="page-title primary--text mb-5">Access</h1>
                <span class="font-weight-medium" style="font-size: 36px">
                  {{accessCount}}
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
        <v-card class="mt-9">
          <h1 class="page-title primary--text text-center pt-3">Activity</h1>
          <v-card-text class="pl-0">
            <v-timeline dense> 
              <v-timeline-item
                v-for="item in siteLogs.slice(0, 5)"
                :key="item.id"
                class="mb-2"
                color="primary"
                small
              >
                <v-row class="align-center">
                  <v-col cols="4" class="pb-1">
                    <strong>{{ item.created_at | moment($store.getters.dateFormat) }}</strong>
                    <div>{{ item.created_at | moment($store.getters.timeFormat) }}</div>
                  </v-col>
                  <v-col cols="8" class="text-right pb-1">
                    <strong>{{item.object_name}}</strong> 
                    <div>{{item.note}}</div>
                    <span>by {{item.user_name}}</span>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
          <div class="d-flex justify-center">
            <v-btn text class="mb-5" to="logs">View more...</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "SiteDashboard",
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters(['site', 'siteLogs', 'UserRole']),
    locationCount() {
      let count = 0
      if (this.site.floors) {
        this.site.floors.forEach(function (item) {
          count = count + item.locations.length
        })
      }
      return count
    },
    deviceCount() {
      return this.site.devices?.length
    },
    ticketCount() {
      return this.site.tickets?.length
    },
    accessCount() {
      return this.site.site_accesses?.filter(a => a.active == true).length
    },
    contactCount() {
      return this.site.contacts?.length
    }, 
    v3Link () {
      return "/v3/site/"+ this.site.id + "/dashboard"
    }

  },
  mounted() {
    this.initialize()
    this.$store.commit('setHeaderTitle', this.site.name)
  },
  methods: {
    initialize() {
      this.loading = false
    },
  },
};
</script>