<template>
  <v-card min-height="85vh">
    <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
      <v-toolbar-title class="headline primary--text">Zones & Layers</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu left>
        <template #activator="{ on: onMenu }">
          <v-tooltip top>
            <template #activator="{ on: onTooltip }">
              <v-btn icon v-on="{ ...onMenu, ...onTooltip }" color="primary">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>

            <span>Add</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item @click="zoneDialog = true">
            <v-icon left>mdi-table</v-icon>
            Add Zone
          </v-list-item>
          <v-list-item @click="layerDialog = true">
            <v-icon left>mdi-layers-triple</v-icon>
            Add Layer
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-dialog
            v-model="zoneDialog"
            width="400px"
          >
            <v-card>
              <v-toolbar class="elevation-2" color="grey lighten-2 mb-5">
                <v-toolbar-title class="headline">{{zoneTitle}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="saveZone">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>Save</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="closeZone">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Close</span>
                </v-tooltip>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  label="Name"
                  v-model="editedZone.name"
                ></v-text-field>
                <v-text-field
                  label="Code"
                  v-model="editedZone.code"
                ></v-text-field>
                <v-autocomplete v-if="zoneIndex == -1" v-model="editedZone.floor_id" :items="site.floors" item-text="name" item-value="id" clearable label="Floor">
                  <template v-slot:selection="data">
                    {{ data.item.name }} || {{ data.item.code }}
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.code }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="zoneDeleteDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeZoneDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteZoneConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="layerDialog"
            width="400px"
          >
            <v-card>
              <v-toolbar class="elevation-2" color="grey lighten-2 mb-5">
                <v-toolbar-title class="headline">{{layerTitle}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="saveLayer">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>Save</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="closeLayer">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Close</span>
                </v-tooltip>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  label="Name"
                  v-model="editedLayer.name"
                ></v-text-field>
                <v-text-field
                  label="Code"
                  v-model="editedLayer.code"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="layerDeleteDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeLayerDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteLayerConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-row no-gutters class="d-flex justify-center">
          <v-col cols="12">
            <v-tabs grow v-model="tab">
              <v-tab>Zones</v-tab>
              <v-tab>Layers</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="py-5">
              <v-tab-item>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="primary--text text-subtitle1">
                          Zone Name
                        </th>
                        <th class="primary--text text-subtitle1">
                          Zone Code
                        </th>
                        <th class="primary--text text-subtitle1">
                          Floor
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="zone in allZones"
                        :key="zone.id"
                        @click="editZone(zone)"
                      >
                        <td>{{ zone.name }}</td>
                        <td>{{ zone.code }}</td>
                        <td>{{ showFloor(zone.floor_id) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
              <v-tab-item>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="primary--text text-subtitle1">
                          Layer Name
                        </th>
                        <th class="primary--text text-subtitle1">
                          Layer Code
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="layer in site.layers"
                        :key="layer.id"
                        @click="editLayer(layer)"
                      >
                        <td>{{ layer.name }}</td>
                        <td>{{ layer.code }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "ZonesLayers",
  data() {
    return {
      tab: null,
      zoneDialog: false,
      layerDialog: false,
      zoneDeleteDialog: false,
      layerDeleteDialog: false,
      loading: true,
      editedZone: {
        code: '',
        name: '',
        floor_id: '',
      },
      defaultZone: {
        code: '',
        name: '',
        floor_id: '',
      },
      zoneIndex: -1, 
      defaultLayer: {
        code: '',
        name: '',
      },
      editedLayer: {
        code: '',
        name: '',
      },
      layerIndex: -1,
    };
  },
  computed: {
    ...mapGetters(['site']),
    zoneTitle () {
      return this.zoneIndex === -1 ? 'New Zone' : 'Edit Zone'
    },
    layerTitle () {
      return this.layerIndex === -1 ? 'New Layer' : 'Edit Layer'
    },
    allZones () {
      let array = []
      this.site.floors.forEach(f => {
        f.zones.forEach(z => {
          array.push(z)
        })
      })
      return array
    },
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize () {
      this.loading = false
    },
    editLayer (item) {
      this.layerIndex = this.site.layers.indexOf(item)
      this.editedLayer = Object.assign({}, item)
      this.layerDialog = true
    },
    deleteLayer (item) {
      this.layerIndex = this.site.layers.indexOf(item)
      this.editedLayer = Object.assign({}, item)
      this.layerDeleteDialog = true
    },
    deleteLayerConfirm () {
      //this.site.layers.splice(this.layerIndex, 1)
      this.closeLayerDelete()
    },
    closeLayer () {
      this.layerDialog = false
      this.$nextTick(() => {
        this.editedLayer = Object.assign({}, this.defaultLayer)
        this.layerIndex = -1
      })
    },
    closeLayerDelete () {
      this.layerDeleteDialog = false
      this.$nextTick(() => {
        this.editedLayer = Object.assign({}, this.defaultLayer)
        this.layerIndex = -1
      })
    },
    async saveLayer () {
      const Layer = new FormData();
      Layer.append("name", this.editedLayer.name);
      Layer.append("code", this.editedLayer.code);

      if (this.layerIndex > -1) {
        Layer.append("id", this.editedLayer.id);
        let response = await this.$http.put('/site/layers', Layer)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('updateLayer', response.data.layer)
          this.$toast.success("Layer Updated")
        }       
      } else {
        Layer.append("site_id", this.site.id);
        let response = await this.$http.post('/site/layers', Layer)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('createLayer', response.data.layer)
          this.$toast.success("Layer Created")
        }
      }
      this.closeLayer()
    },
    showFloor(id) {
      return this.site.floors.find(obj => { return obj.id === id }).name
    },
    editZone (item) {
      this.zoneIndex = this.site.floors.find(obj => { return obj.id === item.floor_id }).zones.indexOf(item)
      this.editedZone = Object.assign({}, item)
      this.zoneDialog = true
    },
    deleteZone (item) {
      this.zoneIndex = this.site.floors.find(obj => { return obj.id === item.floor_id }).zones.indexOf(item)
      this.editedZone = Object.assign({}, item)
      this.zoneDeleteDialog = true
    },
    deleteZoneConfirm() {
      /* let parent = this.site.floors.find(obj => {
        return obj.id === this.editedZone.floor_id
      })
      let parentIndex = this.site.floors.indexOf(parent)
      this.site.floors[parentIndex].zones.splice(this.zoneIndex, 1) */
      this.closeZoneDelete()
    },
    closeZone () {
      this.zoneDialog = false
      this.$nextTick(() => {
        this.editedZone = Object.assign({}, this.defaultZone)
        this.zoneIndex = -1
      })
    },
    closeZoneDelete () {
      this.zoneDeleteDialog = false
      this.$nextTick(() => {
        this.editedZone = Object.assign({}, this.defaultZone)
        this.zoneIndex = -1
      })
    },
    async saveZone() {
      const Zone = new FormData();
      Zone.append("name", this.editedZone.name);
      Zone.append("code", this.editedZone.code);

      if (this.zoneIndex > -1) {
        Zone.append("id", this.editedZone.id)
        let response = await this.$http.put('/site/zones', Zone)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('updateZone', response.data.zone)
          this.$toast.success("Zone Updated")
        } 
      } else {
        Zone.append("site_id", this.site.id);
        Zone.append("floor_id", this.editedZone.floor_id);
        let response = await this.$http.post('/site/zones', Zone)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('createZone', response.data.zone)
          this.$toast.success("Zone Created")
        } 
      }
      this.closeZone()
    },
  },
};
</script>