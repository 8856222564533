<template>

  <v-menu 
    offset-y
    nudge-bottom="15"
    nudge-right="354"
    :close-on-content-click="false"
    :close-on-click="false"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
      <v-icon>mdi-layers-edit</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        @click="toggleLayer(i)"
      >
        <v-list-item-icon >
          <v-icon v-if="item.shown" :color="item.shown ? 'primary' : 'error'">mdi-eye</v-icon>
          <v-icon v-else :color="item.shown ? 'primary' : 'error'">mdi-eye-off</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title :class="item.shown ? 'primary--text' : 'error--text'">{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
  
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "VisibleLayers",
  data() {
    return {
      loading: false,
      items: [
        {name: "Floorplan", shown: true},
        {name: "Locations", shown: true},
        {name: "Zones", shown: false},
        {name: "Labels", shown: false},
      ]
    }
  },
  computed: {
    ...mapGetters(['site', 'siteLogs', 'locationsVisible', 'zonesVisible', 'labelsVisible' , 'planVisible']),
  },
  methods: {
    toggleLayer(index) {
      if (index <= 3) {
        this.$store.commit('toggleLayer', index)
        this.planVisible ? this.items[0].shown = true : this.items[0].shown = false
        this.locationsVisible ? this.items[1].shown = true : this.items[1].shown = false
        this.zonesVisible ? this.items[2].shown = true : this.items[2].shown = false
        this.labelsVisible ? this.items[3].shown = true : this.items[3].shown = false
      }
    },
  },
};
</script>