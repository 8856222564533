import axios from 'axios';

const state = {
  site: {},
  deviceDialog: false,
  createDeviceDialog: false,
  device: {},
  floorplanDialog: false,
  floor: {},
  location: {},
  zone: {},
  sitePermission: 0,
  deviceLogs: [],
  siteLogs: [],
  siteAccess: 0,
  deviceFilterVisible: false,
  deviceFilters: {
    locations: [],
    layers: [],
    statuses: [],
    procats: [],
    show_archived: false
  },
  defaultDeviceHeaders: [
    { visId: 0, text: 'Image', value: 'product.image', visible: true, class: "success--text subtitle-1", sortable: false },
    { visId: 1, text: 'ID', value: 'id', visible: false, class: "success--text subtitle-1" },
    { visId: 2, text: 'Code', value: 'code', visible: true, class: "success--text subtitle-1" },
    { visId: 3, text: 'Name', value: 'name', visible: false, class: "success--text subtitle-1" },
    { visId: 4, text: 'Category', value: 'product.procat.name', visible: true, class: "success--text subtitle-1" },
    { visId: 5, text: 'Brand', value: 'product.brand', visible: true, class: "success--text subtitle-1" },
    { visId: 6, text: 'Model', value: 'product.product_model', visible: true, class: "success--text subtitle-1" },
    { visId: 7, text: 'Floor', value: 'floor.name', visible: true, class: "success--text subtitle-1" },
    { visId: 8, text: 'Floor Code', value: 'floor.code', visible: false, class: "success--text subtitle-1" },
    { visId: 9, text: 'Location', value: 'location.name', visible: true, class: "success--text subtitle-1" },
    { visId: 10, text: 'Location Code', value: 'location.code', visible: false, class: "success--text subtitle-1" },
    { visId: 11, text: 'Zone', value: 'zone.name', visible: false, class: "success--text subtitle-1" },
    { visId: 12, text: 'Zone Code', value: 'zone.code', visible: false, class: "success--text subtitle-1" },
    { visId: 13, text: 'Layer', value: 'layer.name', visible: false, class: "success--text subtitle-1" },
    { visId: 14, text: 'Layer Code', value: 'layer.code', visible: false, class: "success--text subtitle-1" },
    { visId: 15, text: 'Manufacturer', value: 'product.company.name', visible: false, class: "success--text subtitle-1" },
    { visId: 16, text: 'Serial Number', value: 'sn', visible: false, class: "success--text subtitle-1" },
    { visId: 17, text: 'Mac Address', value: 'mac', visible: false, class: "success--text subtitle-1" },
    { visId: 18, text: 'Ip Address', value: 'ip', visible: false, class: "success--text subtitle-1" },
    { visId: 19, text: 'Port', value: 'port', visible: false, class: "success--text subtitle-1" },
    { visId: 20, text: 'Patch', value: 'patch', visible: false, class: "success--text subtitle-1" },
    { visId: 21, text: 'Note', value: 'note', visible: false, class: "success--text subtitle-1" },
    { visId: 22, text: 'Status', value: 'status', visible: true, class: "success--text subtitle-1" },
    { visId: 23, text: 'Archived', value: 'archived', visible: false, class: "success--text subtitle-1" },
  ],
  deviceHeaders: [],
};
const getters = {
  site: state => state.site,
  deviceDialog: state => state.deviceDialog,
  createDeviceDialog: state => state.createDeviceDialog,
  device: state => state.device,
  floorplanDialog: state => state.floorplanDialog,
  floor: state => state.floor,
  location: state => state.location,
  zone: state => state.zone,
  sitePermission: state => state.sitePermission,
  deviceLogs: state => state.deviceLogs,
  siteLogs: state => state.siteLogs,
  deviceFilters: state => state.deviceFilters,
  deviceFilterVisible: state => state.deviceFilterVisible,
  deviceHeaders: state => state.deviceHeaders,
  defaultDeviceHeaders: state => state.defaultDeviceHeaders,
  siteAccess: state => state.siteAccess,
  filteredDevices: state => {
    
    let devices = state.site.devices

    if (state.deviceFilters.statuses.length > 0) {
      devices = devices.filter(d => state.deviceFilters.statuses.includes(d.status))
    }

    if (!state.deviceFilters.show_archived) {
      devices = devices.filter(d => d.archived === false)
    }

    if (state.deviceFilters.procats.length > 0) {
      let idArray = []
      for(let item of state.deviceFilters.procats) {
        idArray.push(item.filter)
      }
      devices = devices.filter(d => idArray.includes(d.product.procat_id))
    }
    
    if (state.deviceFilters.locations.length > 0) {
      let idArray = []
      for(let item of state.deviceFilters.locations) {
        idArray.push(item.id)
      }
      devices = devices.filter(d => idArray.includes(d.location_id))
    } 

    if (state.deviceFilters.layers.length > 0) {
      let idArray = []
      for(let item of state.deviceFilters.layers) {
        idArray.push(item)
      }
      devices = devices.filter(d => idArray.includes(d.layer_id))
    }

    return devices
    
  }
};
const actions = {
  async setSite({commit}, id) {
    let response = await axios.get('/site/sites', { params: { id: id } })
    let site = response.data.site
    site.floors.sort((a,b) => {return a.id - b.id})
    site.floors.forEach( f => {f.locations.sort((a,b) => {return a.id - b.id})})
    await commit('setSite', site)
    let logs = response.data.logs
    await commit('setLogs', logs)
  }
};
const mutations = {
  setSite (state, site) {
    state.site = site
  },
  setLogs (state, logs) {
    state.siteLogs = logs
  },
  unsetSite (state) {
    state.site = {}
    state.deviceDialog = false
    state.createDeviceDialog = false
    state.device = {}
    state.floor = {}
    state.location = {}
    state.sitePermission = 0
    state.deviceLogs = []
  },
  updateSite (state, site) {
    state.site.name = site.name
    state.site.company_id = site.company_id
    state.site.country = site.country
    state.site.image = site.image
    state.site.street = site.street
    state.site.street_number = site.street_number
    state.site.zip = site.zip
    state.site.updated_at = site.updated_at
    state.site.device_statuses = site.device_statuses
  },
  setPermission (state, permission) {
    state.sitePermission = permission
  },
  unsetPermission (state) {
    state.sitePermission = 0
  },
  deleteFloor (state, id) {
    state.site.floors = state.site.floors.filter(floor => floor.id !== id)
  },
  updateFloor (state, floor) {
    Object.assign(state.site.floors[state.site.floors.findIndex(a => a.id === floor.id)], floor)
  },
  createFloor (state, floor) {
    state.site.floors.push(floor)
  },
  orderFloors (state, floorOrder) {
    let orderOptions = floorOrder.split(' ')
    if (orderOptions[1] == "↑") {
      if (orderOptions[0] == "id") {
        state.site.floors.sort((a, b) => String(b.id).localeCompare(String(a.id)))
      } else if (orderOptions[0] == "name") {
        state.site.floors.sort((a, b) => String(b.name).localeCompare(String(a.name)))
      } else {
        state.site.floors.sort((a, b) => String(b.code).localeCompare(String(a.code)))
      }
    } else {
      if (orderOptions[0] == "id") {
        state.site.floors.sort((a, b) => String(a.id).localeCompare(String(b.id)))
      } else if (orderOptions[0] == "name") {
        state.site.floors.sort((a, b) => String(a.name).localeCompare(String(b.name)))
      } else {
        state.site.floors.sort((a, b) => String(a.code).localeCompare(String(b.code)))
      }
    }
  },
  deleteLocation (state, location) {
    let floorIndex = state.site.floors.findIndex(a => a.id === location.floor_id)
    state.site.floors[floorIndex].locations = state.site.floors[floorIndex].locations.filter(a => a.id !== location.id)
  },
  updateLocation (state, location) {
    let floorIndex = state.site.floors.findIndex(a => a.id === location.floor_id)
    Object.assign(state.site.floors[floorIndex].locations[state.site.floors[floorIndex].locations.findIndex(a => a.id === location.id)], location)
  },
  createLocation (state, location) {
    let floorIndex = state.site.floors.findIndex(a => a.id === location.floor_id)
    state.site.floors[floorIndex].locations.push(location)
  },
  orderLocations (state, locationOrder) {
    let orderOptions = locationOrder.split(' ')
    if (orderOptions[1] == "↑") {
      state.site.floors.forEach((floor) => {
        if (orderOptions[0] == "id") {
          floor.locations.sort((a, b) => String(b.id).localeCompare(String(a.id)))
        } else if (orderOptions[0] == "name") {
          floor.locations.sort((a, b) => String(b.name).localeCompare(String(a.name)))
        } else {
          floor.locations.sort((a, b) => String(b.code).localeCompare(String(a.code)))
        }
      })
    } else {
      state.site.floors.forEach((floor) => {
        if (orderOptions[0] == "id") {
          floor.locations.sort((a, b) => String(a.id).localeCompare(String(b.id)))
        } else if (orderOptions[0] == "name") {
          floor.locations.sort((a, b) => String(a.name).localeCompare(String(b.name)))
        } else {
          floor.locations.sort((a, b) => String(a.code).localeCompare(String(b.code)))
        }
      })
    }
  },
  deleteZone (state, zone) {
    let floorIndex = state.site.floors.findIndex(a => a.id === zone.floor_id)
    state.site.floors[floorIndex].zones = state.site.floors[floorIndex].zones.filter(a => a.id !== zone.id)
  },
  updateZone (state, zone) {
    let floorIndex = state.site.floors.findIndex(a => a.id === zone.floor_id)
    Object.assign(state.site.floors[floorIndex].zones[state.site.floors[floorIndex].zones.findIndex(a => a.id === zone.id)], zone)
  },
  createZone (state, zone) {
    let floorIndex = state.site.floors.findIndex(a => a.id === zone.floor_id)
    state.site.floors[floorIndex].zones.push(zone)
  },
  orderZones (state, zoneOrder) {
    let orderOptions = zoneOrder.split(' ')
    if (orderOptions[1] == "↑") {
      state.site.floors.forEach((floor) => {
        if (orderOptions[0] == "id") {
          floor.zones.sort((a, b) => String(b.id).localeCompare(String(a.id)))
        } else if (orderOptions[0] == "name") {
          floor.zones.sort((a, b) => String(b.name).localeCompare(String(a.name)))
        } else {
          floor.zones.sort((a, b) => String(b.code).localeCompare(String(a.code)))
        }
      })
    } else {
      state.site.floors.forEach((floor) => {
        if (orderOptions[0] == "id") {
          floor.zones.sort((a, b) => String(a.id).localeCompare(String(b.id)))
        } else if (orderOptions[0] == "name") {
          floor.zones.sort((a, b) => String(a.name).localeCompare(String(b.name)))
        } else {
          floor.zones.sort((a, b) => String(a.code).localeCompare(String(b.code)))
        }
      })
    }
  },
  deleteLayer (state, id) {
    state.site.layers = state.site.layers.filter(layer => layer.id !== id)
  },
  updateLayer (state, layer) {
    Object.assign(state.site.layers[state.site.layers.findIndex(a => a.id === layer.id)], layer)
  },
  createLayer (state, layer) {
    state.site.layers.push(layer)
  },
  createDevice (state, device) {
    state.site.devices.push(device)
  },
  openDeviceDialog (state, id) {
    state.device = state.site.devices[state.site.devices.findIndex(a => a.id === id)]
    state.deviceDialog = true
  },
  openCreateDeviceDialog (state) {
    state.createDeviceDialog = true
  },
  closeDeviceDialog (state) {
    state.deviceDialog = false
    state.createDeviceDialog = false
  },
  updateDevice (state, device) {
    Object.assign(state.site.devices[state.site.devices.findIndex(a => a.id === device.id)], device)
  },
  deleteDevice (state, id) {
    state.site.devices = state.site.devices.filter(device => device.id !== id)
  },
  setFloor (state, id) {
    state.floor = state.site.floors[state.site.floors.findIndex(a => a.id === id)]
  },
  setLocation (state, location) {
    let floorIndex = state.site.floors.findIndex(a => a.id === location.floor_id)
    state.floor = state.site.floors[floorIndex]
    state.location = state.site.floors[floorIndex].locations[state.site.floors[floorIndex].locations.findIndex(b => b.id === location.id)]
  },
  setZone (state, zone) {
    let floorIndex = state.site.floors.findIndex(a => a.id === zone.floor_id)
    state.floor = state.site.floors[floorIndex]
    state.zone = state.site.floors[floorIndex].zones[state.site.floors[floorIndex].zones.findIndex(b => b.id === zone.id)]
  },
  unsetFloorLocationZone (state) {
    state.floor = {}
    state.location = {}
    state.zone = {}
  },
  openFloorplanDialog (state) {
    state.floorplanDialog = true
  },
  closeFloorplanDialog (state) {
    state.floorplanDialog = false
  },
  createSecret (state, secret) {
    state.site.secrets.push(secret)
  },
  updateSecret (state, secret) {
    Object.assign(state.site.secrets[state.site.secrets.findIndex(a => a.id === secret.id)], secret)
  },
  deleteSecret (state, id) {
    state.site.secrets = state.site.secrets.filter(secret => secret.id !== id)
  },
  createSiteShare (state, share) {
    state.site.site_shares.push(share)
  },
  updateSiteShare (state, share) {
    Object.assign(state.site.site_shares[state.site.site_shares.findIndex(a => a.id === share.id)], share)
  },
  deleteSiteShare (state, id) {
    state.site.site_shares = state.site.site_shares.filter(share => share.id !== id)
  },
  updateSiteAccess (state, access) {
    Object.assign(state.site.site_accesses[state.site.site_accesses.findIndex(a => a.id === access.id)], access)
  },
  createSiteAccess (state, access) {
    state.site.site_accesses.push(access)
  },
  createAttachment (state, attachment) {
    state.site.attachments.push(attachment)
  },
  updateAttachment (state, attachment) {
    Object.assign(state.site.attachments[state.site.attachments.findIndex(a => a.id === attachment.id)], attachment)
  },
  deleteAttachment (state, id) {
    state.site.attachments = state.site.attachments.filter(attachment => attachment.id !== id)
  },
  createContact (state, contact) {
    state.site.contacts.push(contact)
  },
  updateContact (state, contact) {
    Object.assign(state.site.contacts[state.site.contacts.findIndex(a => a.id === contact.id)], contact)
  },
  deleteContact (state, id) {
    state.site.contacts = state.site.contacts.filter(contact => contact.id !== id)
  },
  setDeviceLogs (state, logs) {
    state.deviceLogs = logs
  },
  setAccess (state, access) {
    state.siteAccess = access
  },
  unsetAccess (state) {
    state.siteAccess = 0
  },
  setDeviceFilters (state, filters) {
    if (filters.length == 0) {
      state.deviceFilters.locations = []
      state.deviceFilters.layers = []
      state.deviceFilters.statuses = []
      state.deviceFilters.procats = []
    } else {
      state.deviceFilters = filters
    } 
  },
  async saveDeviceFilters (state) {
    const Filters = new FormData()
    Filters.append("id", state.siteAccess)
    Filters.append("filters", JSON.stringify(state.deviceFilters))
    let response = await axios.post('/site/filters', Filters)
    if (response.data.error) {
      Array.isArray(response.data.error) ? response.data.error.forEach(m => console.log(m)) : console.log(response.data.error)
    }
  },
  toggleDeviceFilter (state) {
    state.deviceFilterVisible = !state.deviceFilterVisible
  },
  setDeviceHeaders (state, headers) {
    if (headers.length == 0) {
      state.deviceHeaders = state.defaultDeviceHeaders
    } else {
      state.deviceHeaders = headers
    }
  },
  async saveDeviceHeaders (state) {
    const Headers = new FormData()
    Headers.append("id", state.siteAccess)
    Headers.append("headers", JSON.stringify(state.deviceHeaders))
    let response = await axios.post('/site/headers', Headers)
    if (response.data.error) {
      Array.isArray(response.data.error) ? response.data.error.forEach(m => console.log(m)) : console.log(response.data.error)
    }
  },
};
export default {
  state,
  getters,
  actions,
  mutations
};