<template>
  <v-card>
    <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
      <v-toolbar-title class="headline primary--text">New Device</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top v-if="stepper > 1">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="createDevice" color="primary">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>Create</span>
      </v-tooltip>
      <v-tooltip top v-if="stepper > 1">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="stepper--">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Previous</span>
      </v-tooltip>
      <v-tooltip top v-if="stepper < 4">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="stepper++">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </template>
        <span>Next</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="closeDialog"  color="error">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Close</span>
      </v-tooltip>
    </v-toolbar>
    <v-stepper v-model="stepper" non-linear>
      <v-stepper-header>
        <v-stepper-step :complete="stepper > 1" step="1" editable >
          {{stepperTitle1}}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepper > 2" step="2" editable >
          {{stepperTitle2}}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepper > 3" step="3" editable >
          {{stepperTitle3}}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" editable >
          Purchase Information
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>

        <v-stepper-content step="1">
          <v-card height="525px">
            <v-data-table
              dense
              :headers="headers"
              :items="products"
              :search="search"
              item-key="id"
              fixed-header
              height="400px"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:top>
                <v-row>
                  <v-col cols="10">
                    <v-text-field v-model="search" prepend-icon="mdi-magnify" label="Search" dense clearable clear-icon="mdi-close-circle"></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-dialog
                      v-model="newProductDialog"
                      width="900"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="success"
                          block
                          v-bind="attrs"
                          v-on="on"
                        >
                          Create Product
                        </v-btn>
                      </template>

                      <v-card>
                        <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
                          <v-toolbar-title class="headline primary--text">Create Product</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on" @click="createProduct" color="primary">
                                <v-icon>mdi-content-save</v-icon>
                              </v-btn>
                            </template>
                            <span>Create</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on" @click="closeProductDialog"  color="error">
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </template>
                            <span>Close</span>
                          </v-tooltip>
                        </v-toolbar>

                        <v-card-text>
                          <v-row>
                            <v-col cols="4">
                              <image-uploader :obj="editedProduct" directory="products" @located="setFileLocation" class="mt-16"/>
                            </v-col>
                            <v-col cols="8">
                              <v-text-field
                                v-model="editedProduct.name"
                                label="Product Name*"
                                prepend-icon="mdi-package-variant"
                              ></v-text-field>
                              <v-text-field
                                v-model="editedProduct.brand"
                                label="Brand"
                                prepend-icon="mdi-tag"
                              ></v-text-field>
                              <v-text-field
                                v-model="editedProduct.product_model"
                                label="Model"
                                prepend-icon="mdi-package"
                              ></v-text-field>
                              <v-autocomplete 
                                v-model="editedProduct.procat_id" 
                                :items="procats" 
                                item-text="name" 
                                item-value="id" 
                                label="Product Category*" 
                                prepend-icon="mdi-format-list-bulleted-type" >
                              </v-autocomplete>
                              <v-combobox 
                                v-model="editedProduct.company_id" 
                                :items="manufacturers" 
                                item-text="name" 
                                label="Manufacturer*" 
                                prepend-icon="mdi-tools" >
                              </v-combobox>
                              <v-text-field
                                v-model="editedProduct.product_url"
                                label="Product Webpage"
                                prepend-icon="mdi-web"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:item="{ item }">
                <tr @click="selectProduct(item)" :class="{'primary white--text': item.id === selectedProduct}">
                  <td>
                    <v-img
                      max-height="50"
                      max-width="60"
                      contain
                      :src="item.image"
                    ></v-img>
                  </td>
                  <td>{{item.name}}</td>
                  <td>{{item.brand}}</td>
                  <td>{{item.product_model}}</td>
                  <td><span v-html="item.procat.name"></span></td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card height="525px">
            <v-row>
              <v-col cols="6">
                <v-autocomplete v-model="editedItem.floor_id" :items="site.floors" label="Select Floor" prepend-icon="mdi-tag-heart-outline" item-text="name" item-value="id" clearable  >
                  <template v-slot:selection="data">
                    {{ data.item.name }} || {{ data.item.code }}
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.code }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete v-model="editedItem.location_id" :items="locationsOnFloor" label="Select Location" prepend-icon="mdi-tag-heart-outline" item-text="name" item-value="id" clearable>
                  <template v-slot:selection="data">
                    {{ data.item.name }} || {{ data.item.code }}
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.code }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-select v-model="editedItem.zone_id" :items="site.zones" label="Select Zone" prepend-icon="mdi-tag-heart-outline" item-text="name" item-value="id" clearable/>
              </v-col>
              <v-col cols="6">
                <v-select v-model="editedItem.layer_id" :items="site.layers" label="Select Layer" prepend-icon="mdi-tag-heart-outline" item-text="name" item-value="id" clearable/>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="qty" prepend-icon="mdi-pound" label="Quantity" type="number"></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card height="525px">
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="editedItem.code" prepend-icon="mdi-pound" label="Unique Identifier"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="editedItem.sn" prepend-icon="mdi-barcode" label="Serial Number"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="editedItem.mac" prepend-icon="mdi-xml" label="Mac Address"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="editedItem.ip" prepend-icon="mdi-lan" label="Ip Address"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="editedItem.patch" prepend-icon="mdi-sitemap" label="Patch"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="editedItem.port" prepend-icon="mdi-server" label="Port"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="editedItem.note" prepend-icon="mdi-note" label="Note"></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-card height="525px">
            <v-row>
              <v-col cols="4">
                <v-autocomplete v-model="editedItem.company_id" :items="suppliers" item-text="name" item-value="id" label="Supplier" prepend-icon="mdi-truck" />
              </v-col>
              <v-col cols="4">
                <v-menu
                  v-model="dp1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedPurchaseDate"
                      label="Purchase Date"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.purchase_date"
                    @input="dp1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-menu
                  v-model="dp2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedWarrantyExpirationDate"
                      label="Warranty Expiration Date"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.warranty_expiration_date"
                    @input="dp2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ImageUploader from '@/components/S3Upload/ImageUploader'

  export default {
    name: 'NewDevice',
    components: {
      ImageUploader
    },
    data: () => ({
      search:"",
      headers: [
        { text: '',value: 'image'},
        { text: 'Product',value: 'name'},
        { text: 'Brand', value: 'brand' },
        { text: 'Model', value: 'product_model' },
        { text: 'Category', value: 'procat.name' }
      ],
      editedItem: {
        name: '',
        product_id: '',
        floor_id: '',
        location_id: '',
        zone_id: '',
        layer_id: '',
        company_id: '',
        code: '',
        sn: '',
        mac: '',
        ip: '',
        patch: '',
        port: '',
        note: '',
        purchase_date: '',
        warranty_expiration_date: ''
      },
      defaultItem: {
        name: '',
        product_id: '',
        floor_id: '',
        location_id: '',
        zone_id: '',
        layer_id: '',
        company_id: '',
        code: '',
        sn: '',
        mac: '',
        ip: '',
        patch: '',
        port: '',
        note: '',
        purchase_date: '',
        warranty_expiration_date: ''
      },
      editedProduct: {
        image: '',
        name: '',
        brand: '',
        product_model: '',
        procat_id: '',
        company_id: '',
        product_url: '',
      },
      defaultProduct: {
        image: '',
        name: '',
        brand: '',
        product_model: '',
        procat_id: '',
        company_id: '',
        product_url: '',
      },
      stepper: 1,
      products: [],
      procats: [],
      suppliers: [],
      manufacturers: [],
      selectedProduct: -1,
      qty: 1,
      dp1: false,
      dp2: false,
      newProductDialog: false
    }),
    computed: {
      stepperTitle1 () {
        if (this.editedItem.product_id > 0) {
          let productIndex = this.products.findIndex(a => a.id == this.editedItem.product_id)
          return this.products[productIndex].name
        } else {
          return "Product"
        }
      },
      stepperTitle2 () {
        if (this.editedItem.floor_id > 0) {
          let floorIndex = this.site.floors.findIndex(a => a.id == this.editedItem.floor_id)
          let title = this.qty + " on " + this.site.floors[floorIndex].name
          if (this.editedItem.zone_id > 0) {
            let zoneIndex = this.site.floors[floorIndex].zones.findIndex(a => a.id == this.editedItem.zone_id)
            title = title.concat(" | ", this.site.floors[floorIndex].zones[zoneIndex].name)
            return title
          } else {
            return title
          }
        } else {
          return "Qty and Location"
        }
      },
      stepperTitle3 () {
        return this.editedItem.code ? this.editedItem.code : "Product Details"
      },
      locationsOnFloor () {
        if (this.editedItem.floor_id) {
          let floorIndex = this.site.floors.findIndex(a => a.id == this.editedItem.floor_id)
          return this.site.floors[floorIndex].locations
        } else {
          return []
        }
      },
      computedPurchaseDate () {
        return this.editedItem.purchase_date ? this.$moment(String(this.editedItem.purchase_date)).format('DD/MM/YYYY') : ''
      },
      computedWarrantyExpirationDate () {
        return this.editedItem.warranty_expiration_date ? this.$moment(String(this.editedItem.warranty_expiration_date)).format('DD/MM/YYYY') : ''
      },
      ...mapGetters(['site', 'floor', 'location', 'StateUser']),
    },
    watch: {
      floor(){
        this.editedItem.floor_id = this.floor.id
        this.defaultItem.floor_id = this.floor.id
      },
      location() {
        this.editedItem.location_id = this.location.id
        this.defaultItem.location_id = this.location.id
      }
    },
    created () {
      this.initialize()
    },
    methods: {
      async initialize () {
        let response = await this.$http.get('/site/devices/new')
        this.products = response.data.products
        this.procats = response.data.procats
        this.suppliers = response.data.suppliers
        this.manufacturers = response.data.manufacturers
        this.loading = false
      },
      selectProduct(product) {
        this.editedItem.product_id = product.id
        this.selectedProduct = product.id
        this.stepper = 2
      },
      async createDevice() {
        const Device = new FormData()
        Device.append('name', this.editedItem.name)
        Device.append('product_id', this.editedItem.product_id)
        Device.append('floor_id', this.editedItem.floor_id)
        Device.append('location_id', this.editedItem.location_id)
        Device.append('zone_id', this.editedItem.zone_id)
        Device.append('layer_id', this.editedItem.layer_id)
        Device.append('site_id', this.site.id)
        Device.append('company_id', this.editedItem.company_id)
        Device.append('code', this.editedItem.code)
        Device.append('sn', this.editedItem.sn)
        Device.append('mac', this.editedItem.mac)
        Device.append('ip', this.editedItem.ip)
        Device.append('patch', this.editedItem.patch)
        Device.append('port', this.editedItem.port)
        Device.append('note', this.editedItem.note)
        Device.append('purchase_date', this.editedItem.purchase_date)
        Device.append('warranty_expiration_date', this.editedItem.warranty_expiration_date)
        for (let step = 0; step < this.qty; step++) {
          let response = await this.$http.post('/site/devices', Device )
          this.$store.commit('createDevice', response.data.device)
        }
        this.closeDialog()
        let message = ''
        if (this.qty === 1) {
          message = "Device Created"
        } else {
          message = this.qty + " Devices Created"
        }
        this.$toast.success(message);
      },
      closeDialog() {
        this.$store.commit('closeDeviceDialog')
        this.editedItem = Object.assign({}, this.defaultItem)
        this.stepper = 1
        this.selectedProduct = -1
        this.search = ""
        this.qty = 1
      },

      setFileLocation (value) {
        this.editedProduct.image = value
      },

      closeProductDialog() {
        this.newProductDialog = false
        this.editedProduct = this.defaultProduct
      },

      async createProduct() {
        if (typeof this.editedProduct.company_id === 'string' || this.editedProduct.company_id instanceof String) {
          const Company = new FormData()
          Company.append("name", this.editedProduct.company_id)
          Company.append("customer", false)
          Company.append("manufacturer", true)
          Company.append("supplier", false)
          Company.append("tenant_id", this.StateUser.tenant_id)
          let res = await this.$http.post('/tenant/companies', Company)
          this.manufacturers.push(res.data.company)
          this.editedProduct.company_id = res.data.company
        }

        const Product = new FormData();
        Product.append("image", this.editedProduct.image )
        Product.append("name", this.editedProduct.name)
        Product.append("product_model", this.editedProduct.product_model)
        Product.append("product_url", this.editedProduct.product_url)
        Product.append("brand", this.editedProduct.brand)
        Product.append("procat_id", this.editedProduct.procat_id)
        Product.append("company_id", this.editedProduct.company_id.id)
        Product.append("tenant_id", this.StateUser.tenant_id)
        Product.append("product_status", true)
        Product.append("interfaces", JSON.stringify([]))

        let response = await this.$http.post('/tenant/products', Product)
        this.products.push(response.data.product)
        this.closeProductDialog()
        this.selectProduct(response.data.product)
        this.$toast.success("Product Created")
      },
    }
  }
</script>