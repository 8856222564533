<template>
  <v-menu
    v-model="visMenu"
    :close-on-content-click="false"
    max-width="600"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </template>

    <v-card>
      <!-- <div class="float-right mt-2">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="setDefaultColumns" class="mr-5">
              <v-icon color="primary">mdi-format-columns</v-icon>
            </v-btn>
          </template>
          <span>Restore Default Settings</span>
        </v-tooltip>
      </div> -->
      <v-subheader>Visible Columns</v-subheader>
      <v-card-text>
        <v-row>
          <v-col
            v-for="(item, index) in deviceHeaders"
            :key="index"
            cols="4"
          >
            <v-checkbox
              v-model="headers[index].visible"
              :label="item.text"
              class="py-0 my-0"
              hide-details
              @change="updateVisibleColumns"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "DeviceVisMenu",
  data() {
    return {
      visMenu: false,
      headers: [
        { visible: false },
        { visible: true },
        { visible: false },
        { visible: true },
        { visible: false },
        { visible: true },
        { visible: false },
        { visible: true },
        { visible: false },
        { visible: true },
        { visible: false },
        { visible: true },
        { visible: false },
        { visible: true },
        { visible: false },
        { visible: true },
        { visible: false },
        { visible: true },
        { visible: false },
        { visible: true },
        { visible: false },
        { visible: true },
        { visible: false },
        { visible: true },
      ],
    };
  },
  computed: {
    ...mapGetters(['site', 'sitePermission', 'deviceFilters', 'deviceHeaders', 'defaultDeviceHeaders']),
  },
  mounted() {
    if (this.deviceHeaders.length > 0) {
      this.headers = this.deviceHeaders
    } else {
      this.headers = this.defaultDeviceHeaders
    }
  }, 
  methods: {
    updateVisibleColumns() {
      this.$store.commit('setDeviceHeaders', this.headers)
      this.$store.commit('saveDeviceHeaders')
    }, 
    setDefaultColumns() {
      this.headers = Object.assign({}, this.defaultDeviceHeaders)
      this.$store.commit('setDeviceHeaders', this.headers)
      this.$store.commit('saveDeviceHeaders')
    }
  },
};
</script>