<template>
  <v-container fluid class="Logs-page mt-5">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="logs"
          :search="search"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              height="90"
            >
              <v-menu
                v-model="filterMenu"
                :close-on-content-click="false"
                offset-x
                min-width="450"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                          <v-icon>mdi-filter</v-icon>
                        </v-btn>
                      </template>
                      <span>Filter Logs</span>
                    </v-tooltip>
                  </v-btn>
                </template>

                <v-card>
                  <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
                    <v-toolbar-title class="headline primary--text">Filter Logs</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="clearFilterLogs()" color="error">
                          <v-icon>mdi-filter-remove</v-icon>
                        </v-btn>
                      </template>
                      <span>Remove Filters</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="filterMenu = false" color="error">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>close</span>
                    </v-tooltip>
                  </v-toolbar>
                  <v-card-text>
                    <user-select :items="users" label="Users" :dense="false" @submit="userSelected" ref="userSelect" />
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                clearable
                flat
                color="primary"
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.created_at_time`]="{ item }">
            {{ item.created_at | moment($store.getters.timeFormat) }}
          </template>
          <template v-slot:[`item.parent`]="{ item }">
            {{ item.parent_model }}: {{item.parent_name}}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import UserSelect from '@/components/Selectors/UserSelect.vue'

  export default {
    name: 'Logs',
    components: {
      UserSelect,
    },
    data: () => ({
      users: [],
      search: '',
      loading: true,
      headers: [
        { text: 'Day', value: 'created_at' },
        { text: 'Time', value: 'created_at_time' },
        { text: 'User', value: 'user_name' },
        { text: 'Ressource', value: 'object_model' },
        { text: 'Item', value: 'object_name' },
        { text: 'Action', value: 'note' },
      ],
      filter_user: [],
      results: 100,
      filterMenu: false,
    }),

    computed: {
      ...mapGetters(['site', 'sitePermission', 'siteLogs']),
      logs() {
        if (this.filter_user.length > 0) {
          return this.siteLogs.filter(log => this.filter_user.includes(log.user_id))
        } else {
          return this.siteLogs
        }
      }
    },

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', this.site.name + ' > Logs')
    },

    methods: {
      async initialize () {
        let userList = this.siteLogs.map(a => a.user_id)
        userList = userList.filter(this.findUnique)
        const users = new FormData()
        users.append("users", userList)
        let response = await this.$http.post('/global/find_users', users)
        this.users = response.data.users
        this.loading = false
      },
      userSelected(user) {
        this.filter_user = user
      },
      findUnique(value, index, array) {
        return array.indexOf(value) === index
      },
      clearFilterLogs() {
        this.$refs.userSelect.clear()
        this.filterMenu = false
        this.search = ''
      }
    }
  }
</script>