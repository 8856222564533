<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list nav dense>
        <div v-if="sitePermission>1">
          <device-bulk-edit :devices="devices"></device-bulk-edit>
          <v-divider></v-divider>
          <xls-devices-downloader :devices="devices"></xls-devices-downloader>
          <xls-site-downloader />
          <v-divider></v-divider>
        </div>

        <div v-if="sitePermission>2">
          <xls-devices-uploader></xls-devices-uploader>
        </div>

      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import {mapGetters} from 'vuex'
import XlsDevicesDownloader from '@/components/Xls/XlsDevicesDownloader'
import XlsDevicesUploader from '@/components/Xls/XlsDevicesUploader'
import XlsSiteDownloader from '@/components/Xls/XlsSiteDownloader'
import DeviceBulkEdit from '@/components/Site/DeviceBulkEdit'


export default {
  name: "ToolbarFilter",
  components: {
    XlsDevicesDownloader,
    XlsDevicesUploader,
    XlsSiteDownloader,
    DeviceBulkEdit
  },
  data() {
    return {
      menu: false,
    };
  },
  props: ['devices'],
  computed: {
    ...mapGetters(['site', 'sitePermission']),
  },
};
</script>