<template>
  <v-container fluid class="locations-page">
    <v-row class="mt-3">
      <v-col cols="3">
        <v-card>
          <v-row no-gutters>
            <v-col cols="6">
              <v-btn block text color="primary" :to="'/site/'+$route.params.site_id+ '/locations'">Locations</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn block text color="primary" :to="'/site/'+$route.params.site_id+ '/zones'" >Zones</v-btn>
            </v-col>
          </v-row>
          <zone-treeview ref="zoneTreeview"></zone-treeview>
        </v-card>
      </v-col>
      <v-col cols="9">
        
        <zone-card @newZone="findZone"></zone-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import ZoneCard from '@/components/Location/ZoneCard.vue'
import ZoneTreeview from '@/components/Location/ZoneTreeview.vue'
    
export default {
  name: "Zones",
  components: {
    ZoneCard,
    ZoneTreeview,
    },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['site', 'locationTypes', 'floor', 'zone', 'sitePermission']),
  },

  methods: {
    findZone(id) {
      this.$refs.zoneTreeview.findZone(id)
    },
  },
  mounted() {
    this.$store.commit('setHeaderTitle', this.site.name + ' > Locations')
  },
}
</script>

<style lang="scss">
  .cpointer {
    cursor: pointer;
  }
</style>