<template>
  <v-app>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">
          <div class="d-flex">
            <v-img
              contain
              src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/header-logo.svg"
              max-width="200"
            ></v-img>
          </div>
          <div class="d-flex">
            <p>WIKIBUILD</p>
          </div>
        </v-col>
        <v-col cols="12" lg="5" class="login-part d-flex align-center justify-center">
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper pt-md-4 pt-0">
                <v-form>
                  <v-container>
                    <v-row class="flex-column" v-if="error===false">
                      <v-col>
                        <p class="login-slogan display-2 text-center font-weight-medium mt-10">Sign up on Invite</p>
                        <p class="text-center font-weight-medium primary--text">{{email}}</p>
                      </v-col>
                      <v-form>
                        <v-col>
                          <v-text-field
                            v-model="fname"
                            label="First Name*"
                            required
                          ></v-text-field>
                          <v-text-field
                            v-model="lname"
                            label="Last Name*"
                            required
                          ></v-text-field>
                          <v-text-field
                            v-model="newPass1"
                            :rules="passRules"
                            type="password"
                            label="Password*"
                            required
                          ></v-text-field>
                          <v-text-field
                            v-model="newPass2"
                            :rules="passRules"
                            type="password"
                            label="Retype Password*"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex justify-space-between">
                          <v-btn
                            class="text-capitalize"
                            large
                            :disabled="newPass1.length < 6 || newPass2.length < 6"
                            color="primary"
                            @click="submit"
                          >Submit</v-btn>
                        </v-col>
                      </v-form>
                    </v-row>
                    <v-row class="flex-column" v-else>
                      <v-col>
                        <p class="login-slogan display-2 text-center font-weight-medium mt-10">Sign up by invite</p>
                        <p class="text-center font-weight-medium error--text">
                          The provided parameters are wrong please use the link provided by email. For further assistance please contact hello@wikibuild.io
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text">© 2020-2023 WikiBuild. All rights reserved.</div>
              </v-footer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    name: 'InviteSignUp',
    data() {
      return {
        fname: '',
        lname: '',
        newPass1: '',
        newPass2: '',
        email: '',
        token: '',
        error: false,
        passRules: [
          v => !!v || 'Password is required',
          v => v.length >= 6 || 'Min 6 characters'
        ]
      }
    },
    methods: {
      async submit(){
        if (this.newPass1 == this.newPass2) {
          const User = new FormData()
          User.append("token", this.token)
          User.append("password", this.newPass1)
          User.append("email", this.email)
          User.append("lname", this.lname)
          User.append("fname", this.fname)
          let response = await this.$http.post('/tenant/users/invite_sign_up', User)
          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            this.$toast.success('sign up complete')
            User.delete('token')
            User.delete('lname')
            User.delete('fname')
            User.append("username", this.email)
            let res = await this.$http.post('/admin/users/login', User)
            if (res.data.error) {
              this.$toast.error(res.data.error);
            } else {
              this.$store.commit('setUser', res.data.user)
              this.$store.commit('setToken', res.data.token)
              this.$router.push('/sites')
            }
          }
        } else {
          this.$toast.error("passwords don't match")
        }
        
      },
    },
    created() {
      if (this.$route.query.email && this.$route.query.token) {
        this.email = this.$route.query.email
        this.token = this.$route.query.token
      } else {
        this.error = true 
      }
      
    }
  }

</script>

<style src="./Login.scss" lang="scss"/>