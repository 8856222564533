<template>
  <div id="main-wrapper">
    <v-toolbar
      floating
      min-width="400px"
    >
      <v-menu
        v-model="treeviewMenu"
        absolute
        nudge-bottom="32"
        nudge-left="264"
        :close-on-content-click="false"
        :close-on-click="false"
        max-width="450px"
        min-width="400px"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-file-tree</v-icon>
          </v-btn>
        </template>


        <v-card>
          <v-toolbar flat> 
            <v-text-field
              v-model="search"
              clearable
              flat
              color="primary"
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Search"
              @input="handleSearch"
            ></v-text-field>

            <v-spacer></v-spacer>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="addFloor" v-if="sitePermission>1">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>Add Floor</span>
            </v-tooltip>
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      <v-icon>mdi-order-alphabetical-ascending</v-icon>
                    </v-btn>
                  </template>
                  <span>Order Building Tree</span>
                </v-tooltip>
              </template>
              <v-list class="pt-4">
                <v-list-item>
                  <v-select
                    dense
                    v-model="floorOrder"
                    :items="orderBy"
                    label="Order Floors By"
                    @change="orderFloors"
                  ></v-select>
                </v-list-item>
                <v-list-item>
                  <v-select
                    v-model="locationOrder"
                    :items="orderBy"
                    label="Order Locations By"
                    @change="orderLocations"
                  ></v-select>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-card-text style="max-height: 700px" class="overflow-y-auto">
            <v-treeview
              ref="tree"
              :items="site.floors"
              item-children="locations"
              activatable
              :search="search"
              :open.sync="open"
              :active="active"
              return-object
              hoverable
            >
              <template v-slot:prepend="{ item }">
                <div class="cpointer" @click="showItem(item)">
                  <v-icon v-if="item.locations">mdi-layers-triple</v-icon>
                  <v-icon v-else>mdi-table</v-icon>
                </div>
              </template>
              <template v-slot:label="{ item }">
                <v-hover v-slot:default="{ hover }">
                  <div class="d-flex justify-space-between cpointer" @click.stop="showItem(item)">
                    <span v-if="item.code.length > 0">{{item.name}} || {{item.code}}</span>
                    <span v-else>{{item.name}}</span>
                    <span v-if="hover && sitePermission>1">
                      <v-icon v-if="item.locations" @click.stop="addLocation(item.id)" class="mr-3">mdi-plus</v-icon>
                      <v-icon @click.stop="editItem(item)">mdi-pencil</v-icon>
                    </span>
                  </div>
                </v-hover>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-menu>

      <visible-layers/>

    </v-toolbar>

    
    
    <v-dialog
      v-model="dialog"
      max-width="500"
      transition="dialog-bottom-transition"
      v-if="sitePermission>1"
    >
      <v-card>
        <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
          <v-toolbar-title class="headline primary--text">{{itemAction}} {{itemType}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="saveItem" color="primary">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>Save</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="closeDialog" color="error">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Name"
            v-model="item.name"
          ></v-text-field>
          <v-text-field
            label="Code"
            v-model="item.code"
          ></v-text-field>
          <v-select v-if="itemType==='Location'" v-model="item.location_type" :items="locationTypes" label="Select Type" clearable/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import VisibleLayers from '@/components/V3/Floorplan/VisibleLayers'
    
export default {
  name: "LocationTreeview",
  components: {
    VisibleLayers
  },
  data() {
    return {
      treeviewMenu: false,
      open: [],
      active: [],
      search: null,
      item: {},
      itemAction: "",
      itemType: "",
      dialog: false,
      orderBy: ["id ↓", "id ↑", "name ↓", "name ↑", "code ↓", "code ↑"],
      floorOrder: "",
      locationOrder: "",
    }
  },
  computed: {
    ...mapGetters(['site', 'locationTypes', 'floor', 'location', 'zone', 'sitePermission']),
  },

  watch: {
    floor(){
      this.active.push(this.floor)
    },
    location() {
      this.active.push(this.location)
    }
  },

  methods: {
    // opens treeview on found locations
    handleSearch(input) {
      if (input) {
        this.$refs.tree.updateAll(true)
        this.treeviewMenu = true
      } else {
        this.$refs.tree.updateAll(false)
      }
    },
    editItem(item) {
      this.item = item
      this.itemAction = "Edit"
      if (item.locations) {
        this.itemType = "Floor"
      } else {
        this.itemType = "Location"
      }
      this.dialog = true
    },
    // Handles create and update for both Floor and Location
    async saveItem() {
      if (this.itemType === "Floor") {
        const Floor = new FormData()
        Floor.append("name", this.item.name)
        Floor.append("code", this.item.code)

        if (this.itemAction === "New") {
          Floor.append("site_id", this.site.id)
          let response = await this.$http.post('/site/floors', Floor)
          this.$store.commit('createFloor', response.data.floor)
          this.$toast.success("Floor Created")
        } else if (this.itemAction === "Edit") {
          Floor.append("id", this.item.id)
          let response = await this.$http.put('/site/floors', Floor)
          this.$store.commit('updateFloor', response.data.floor)
          this.$toast.success("Floor Updated")
        }
      } else if (this.itemType === "Location") {
        const Location = new FormData()
        Location.append("name", this.item.name)
        Location.append("code", this.item.code)
        Location.append("location_type", this.item.location_type)

        if (this.itemAction === "New") {
          Location.append("floor_id", this.item.floor_id)
          let response = await this.$http.post('/site/locations', Location)
          this.$store.commit('createLocation', response.data.location)
          this.$toast.success("Location Created")
        } else if (this.itemAction === "Edit") {
          Location.append("id", this.item.id)
          let response = await this.$http.put('/site/locations', Location)
          this.$store.commit('updateLocation', response.data.location)
          this.$toast.success("Location Updated")
        }
      }
      this.closeDialog()
    },

    // Sets floor or floor and location
    showItem(item) {
      this.item = item
      this.$store.commit('unsetFloorLocationZone')
      this.$nextTick(() => {
        if (item.locations) {
          this.itemType = "Floor"
          this.$store.commit('setFloor', item.id)
        } else {
          this.itemType = "Location"
          this.$store.commit('setLocation', item)
        }
      })
    },
    addLocation(floor_id) {
      this.item = {
        floor_id: floor_id,
        name: "",
        code: "",
        location_type: ""
      }
      this.itemAction = "New"
      this.itemType = "Location"
      this.dialog = true
    },
    addFloor() {
      this.item = {
        site_id: this.site.id,
        name: "",
        code: "",
      }
      this.itemAction = "New"
      this.itemType = "Floor"
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    findLocation(id) {
      let loc = this.floor.locations.find((e) => e.id == id)
      this.showItem(loc)
    },
    orderFloors() {
      this.$store.commit('orderFloors', this.floorOrder )
    }, 
    orderLocations() {
      this.$store.commit('orderLocations', this.locationOrder )
    },
  },
  mounted() {
    //select first floor in order to have something to show on the plan
    /* if (this.site.floors.length > 0) {
      this.showItem(this.site.floors[0])
      this.active.push(this.site.floors[0])
    } */
  },
}
</script>

<style scoped lang="scss">
  .cpointer {
    cursor: pointer;
  }

  #main-wrapper {
    position: absolute;
    top: 10px;
    left: 12px;
  }

</style>