<template>
  <v-app-bar
    height="64"
    fixed
    color='primary'
    dark>
    <router-link to="/">
      <v-img
        class="ml-n2"
        max-height="40"
        max-width="40"
        src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/header-logo.svg"
      ></v-img>
    </router-link>
    <v-toolbar-title class="ml-8">{{HeaderTitle}}</v-toolbar-title>
    <v-spacer></v-spacer>
    <site-filter></site-filter>
    <site-links-card></site-links-card>
    <user-menu></user-menu>
  </v-app-bar>
</template>

<script>
  import {mapGetters} from 'vuex'
  import SiteFilter from '@/components/V3/Header/SiteFilter'
  import SiteLinksCard from '@/components/V3/Header/SiteLinksCard'
  import UserMenu from '@/components/V3/Header/UserMenu'
  
  export default {
    name: 'Header',
    components: {
      SiteFilter,
      SiteLinksCard,
      UserMenu
    },
    data: () => ({
    }),
    computed: {
      ...mapGetters(['HeaderTitle']),
    },
    mounted() {
    },
    methods: {
    }
  };
</script>
