<template>
  <v-container fluid class="devices-page">
    <v-row class="mt-3">
      <v-col cols="12">
        <template>
          <v-data-table
            ref="deviceTable"
            v-model="selectedDevices"
            :headers="headers"
            :items="filteredDevices"
            :item-class="textColor"
            class="elevation-3"
            :search="search"
            item-key="id"
            disable-pagination
            show-select
            fixed-header
            height="79vh"
            hide-default-footer
            checkbox-color="primary"
            @click:row="showDevice"
          >
            <template v-slot:top>
              <v-toolbar flat height="90">

                <device-bulk-actions v-if="sitePermission>1" :devices="selectedDevices"/>

                <device-vis-menu/>

                <device-filter/>

                <device-archive-toggle/>

                <v-toolbar-title class="ml-5">Devices : {{deviceCount}} of {{deviceTotal}}</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-text-field
                  v-model="search"
                  clearable
                  flat
                  color="primary"
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Search"
                  class="mr-5"
                ></v-text-field>

                <v-spacer></v-spacer>

                <v-btn
                  v-if="sitePermission>1"
                  color="primary"
                  dark
                  class="mb-2"
                  @click="newDevice"
                >
                  Add Device
                </v-btn>
              </v-toolbar>
              
            </template>

            <template v-slot:[`item.product.product_category.name`]="{ item }">
              <span v-html="item.product.product_category.name"></span>
            </template>
            <template v-slot:[`item.product.image`]="{ item }">
              <v-img v-if="item.product.image.length > 0" :src="item.product.image" :alt="item.name" max-height="100px" max-width="100px" contain></v-img>
              <v-img v-else src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/placeholder-image.png" :alt="item.name" max-height="100px" max-width="100px" contain></v-img>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip v-if="item.status" :color="statusColor(item.status)" outlined>
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:[`item.archived`]="{ item }">
              <v-icon :color="item.archived ? 'primary' : 'error'" >{{ item.archived ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </template>
          </v-data-table>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import DeviceFilter from '@/components/Site/DeviceFilter'
import DeviceBulkActions from '@/components/Site/DeviceBulkActions'
import DeviceVisMenu from '@/components/Site/DeviceVisMenu'
import DeviceArchiveToggle from '@/components/Site/DeviceArchiveToggle'

export default {
  name: "Devices",
  components: {
    DeviceFilter,
    DeviceBulkActions,
    DeviceVisMenu,
    DeviceArchiveToggle,
  },
  data() {
    return {
      selectedDevices: [],
      search: '',
    };
  },
  computed: {
    ...mapGetters(['site', 'sitePermission', 'deviceFilters', 'UserRole', 'deviceHeaders', 'filteredDevices']),
    headers () {
      let arr = this.deviceHeaders.filter(function (el) {
        if (el && el.visible) { return el }
      })
      return arr
    },
    deviceCount () {
      if (this.filteredDevices) {
        if (this.search.length > 0) {
          return this.$refs.deviceTable.$children[0].filteredItems.length
        } else {
          return this.filteredDevices.length
        }
      } else {
        return 0
      }
    },
    deviceTotal () {
      if (this.site.devices) {
        return this.site.devices.length
      } else {
        return 0
      }
    },
  },
  mounted() {
    this.$store.commit('setHeaderTitle', this.site.name + ' > Devices')
  },
  methods: {
    newDevice () {
      this.menu = false
      this.$store.commit('openCreateDeviceDialog')
    },
    excel () {
      this.menu = false
    },
    showDevice(item) {
      this.$store.commit('openDeviceDialog', item.id)
    },
    statusColor(status) {
      let index = this.site.device_statuses.findIndex(a => a.name == status)
      return (index > -1) ? this.site.device_statuses[index].color : "#F1F1F1"
    },
    toggleDeviceFilter () {
      this.$store.commit('toggleDeviceFilter')
    }, 
    textColor(item) {
      return item.archived ? "error--text" : "hello"
    }
  },
};
</script>