<template>
  <v-navigation-drawer
    app
    clipped
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    expand-on-hover
  >

    <v-list>
      <template v-for="(item, i) in items">
        
        <v-divider
          v-if="item.divider"
          :key="i"
          dark
          class="my-4"
        ></v-divider>
        <v-list-item
          color="primary"
          v-else
          :key="item.text"
          :href="item.href ? item.href : null"
          :to="item.link === '#' ? null : item.link"
          link>
          <v-list-item-action>
            <v-icon
              size="28"
              :color="item.color ? item.color : ''"
            >{{ item.icon }}</v-icon>
          </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="grey--text"
                link>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <template v-slot:append>
      <v-list dense>
        <v-list-item
          color="primary"
          link
          to="/">
          <v-list-item-action>
            <v-icon size="28">mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              class="grey--text"
              link>
                Exit Admin
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>

  export default {
    data(){
      return {
        items: [
          { title: 'Dashboard', icon: 'mdi-home', link: '/admin/dashboard' },
          { title: 'Tenants', icon: 'mdi-shield-home', link: '/admin/tenants' },
          { title: 'Users', icon: 'mdi-account-details', link: '/admin/users' },
          { title: 'Products', icon: 'mdi-package-variant', link: '/admin/products' },
          { title: 'Categories', icon: 'mdi-family-tree', link: '/admin/procats' },
          { title: 'Companies', icon: 'mdi-domain', link: '/admin/companies' },
          { title: 'Logs', icon: 'mdi-clipboard-text-clock', link: '/admin/logs' },
          { title: 'Export', icon: 'mdi-code-json', link: '/admin/json' },
        ],
        mini: true
      }
    },
    mounted() {
      this.$store.commit('unsetSite')
    }
  }
</script>

<style src="./Sidebar.scss" lang="scss"/>
