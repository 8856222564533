<template>
  <v-card>
    <v-toolbar color="grey lighten-2 mb-5">
      <v-toolbar-title class="headline">Ticket #{{ ticket.id }}</v-toolbar-title>
      <v-spacer></v-spacer>
      {{ticket.subject}}
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="updateTicket">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Update</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="closeTicket">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Close</span>
      </v-tooltip>
    </v-toolbar>
    <div class="px-4">
      <v-row class="d-flex align-center">
        <v-col cols="6">
          <h2 class="mb-1 primary--text">{{ ticket.site.name }}</h2>
          <div>{{ ticket.assigner.fname }} {{ticket.assigner.lname}}</div>
          <small>{{ ticket.created_at | moment($store.getters.dateFormat) }} ({{ ticket.created_at | moment("from") }})</small>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-btn-toggle
            v-model="ticketStatusToggle"
          >
            <v-btn
              v-for="(status, index) in ticket_statuses" 
              :key="index"
              color="primary" 
              text
              small
            >
              {{status}}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-divider class="mt-5"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab" fixed grow>
            <v-tab>Messages</v-tab>
            <v-tab>Notes</v-tab>
            <v-tab>Time entries</v-tab>
            <v-tab>Attachments</v-tab>
            <v-tab>History</v-tab>
            <v-tab>Resolution</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <message-board></message-board>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>{{'hello notes'.repeat(5000)}}</v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>hello time entries</v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>hello attachments</v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>hello history</v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>hello resolution</v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="updateTicketDialog" width="80%">
      <update-ticket v-on:closeUpdateTicketDialog="updateTicketDialog = false"/>
    </v-dialog>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import UpdateTicket from '@/components/Support/UpdateTicket.vue'
  import MessageBoard from '@/components/Support/MessageBoard.vue'

  export default {
    name: "viewTicket",
    components: {
      UpdateTicket,
      MessageBoard,
    },
    data () {
      return {
        loading: true,
        ticketStatusToggle: undefined,
        editTicketdialog: false,
        dp1: false,
        updateTicketDialog: false,
        tab: 0,
      }
    },
    computed: {
      computedDueDate () {
        return this.ticket.due_date ? this.$moment(String(this.ticket.due_date)).format('DD/MM/YYYY') : ''
      },
      ...mapGetters(['users', 'ticket', 'ticket_statuses'])
    },
    mounted() {
      this.initialize()
    },
    methods: {
      initialize () {
        this.loading = false
      },
      updateTicket () {
        this.updateTicketDialog = true
      },
      closeTicket () {
        this.$emit('closeSheet')
      },
      saveTicket () {
        this.sheet = false
      }
    },
  }
</script>