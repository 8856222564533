<template>
  <v-container fluid class="users-page mt-5">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="tenantUsers"
          :search="search"
          :loading="loading"
          sort-by="id"
          class="elevation-1"
          @click:row="editItem"
        >
          <template v-slot:top>
            <v-toolbar flat height="90" >
              <v-toolbar-title>users</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                clearable
                flat
                color="primary"
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="newItem"
              >
                New User
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            {{ showRole (item.role) }}
          </template>
          <template v-slot:[`item.user_status`]="{ item }">
            <v-icon small>{{ item.user_status ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    name: 'users',
    data: () => ({
      search: '',
      loading: true,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Email/Username', value: 'email'},
        { text: 'First Name', value: 'fname' },
        { text: 'Last Name', value: 'lname' },
        { text: 'Role', value: 'role' },
        { text: 'Active', value: 'user_status' },
        { text: 'Created at', value: 'created_at' },
        { text: 'Updated at', value: 'updated_at' },
      ],
    }),

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', this.tenantTenant.name +' > Users')
    },

    computed: {
      ...mapGetters(['tenantUserRoles', 'tenantUsers', 'StateUser', 'tenantTenant']),
    },
    
    methods: {
      async initialize () {
        let response = await this.$http.get('/tenant/users')
        this.$store.commit('setTenantData', response.data)
        this.loading = false
      },

      newItem () {
        this.$store.commit('openTenantNewUserCard', this.StateUser.tenant_id)
      },

      editItem (item) {
        this.$store.commit('openTenantUserCard', item.id)
      },

      showRole (role) {
        if (role === 5) {
          return "Superadmin"
        } else if (role === 4) {
          return "Admin"
        } else if (role === 3) {
          return "Tenant Manager"
        } else if (role === 2) {
          return "Contributor"
        } else if (role === 1) {
          return "Reader"
        } else {
          return "no role assigned"
        }
      },
    }
  }
</script>