<template>
  <v-container fluid class="Logs-page mt-5">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="logs"
          :search="search"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              height="90"
            >
              <v-menu
                v-model="filterMenu"
                :close-on-content-click="false"
                offset-x
                min-width="450"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                          <v-icon>mdi-filter</v-icon>
                        </v-btn>
                      </template>
                      <span>Filter Logs</span>
                    </v-tooltip>
                  </v-btn>
                </template>

                <v-card>
                  <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
                    <v-toolbar-title class="headline primary--text">Filter Logs</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="filterLogs()" color="primary">
                          <v-icon>mdi-filter-check</v-icon>
                        </v-btn>
                      </template>
                      <span>Apply Filters</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="clearFilterLogs()" color="error">
                          <v-icon>mdi-filter-remove</v-icon>
                        </v-btn>
                      </template>
                      <span>Remove Filters</span>
                    </v-tooltip>
                  </v-toolbar>
                  <v-card-text>
                    <site-select :items="sites" label="sites" :dense="false" @submit="siteSelected" ref="siteSelect" />
                    <user-select :items="users" label="Users" :dense="false" @submit="userSelected" ref="userSelect" />
                    <v-slider
                      class="mt-10"
                      v-model="results"
                      thumb-label="always"
                      :min="10"
                      :max="500"
                      :step="10"
                      label="Number of results"
                    ></v-slider>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                clearable
                flat
                color="primary"
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.created_at_time`]="{ item }">
            {{ item.created_at | moment($store.getters.timeFormat) }}
          </template>
          <template v-slot:[`item.parent`]="{ item }">
            {{ item.parent_model }}: {{item.parent_name}}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import UserSelect from '@/components/Selectors/UserSelect.vue'
  import SiteSelect from '@/components/Selectors/SiteSelect.vue'

  export default {
    name: 'Logs',
    components: {
      UserSelect,
      SiteSelect
    },
    data: () => ({
      users: [],
      sites: [],
      logs: [],
      search: '',
      loading: true,
      headers: [
        { text: 'Day', value: 'created_at' },
        { text: 'Time', value: 'created_at_time' },
        { text: 'User', value: 'user_name' },
        { text: 'Parent', value: 'parent' },
        { text: 'Ressource', value: 'object_model' },
        { text: 'Item', value: 'object_name' },
        { text: 'Action', value: 'note' },
        
      ],
      filter_users: [],
      filter_sites: [],
      results: 100,
      filterMenu: false,
    }),

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', 'Admin > Logs')
    },

    methods: {
      async initialize () {
        let response = await this.$http.get('/admin/logs')
        this.users = response.data.users
        this.logs = response.data.logs
        this.sites = response.data.sites
        this.loading = false
      },
      userSelected(users) {
        this.filter_users = users
      },
      siteSelected(sites) {
        this.filter_sites = sites
      }, 
      async filterLogs() {
        this.filterMenu = false
        const Filters = new FormData();
        Filters.append("sites", this.filter_sites)
        Filters.append("users", this.filter_users)
        Filters.append("results", this.results)

        let response = await this.$http.post('/admin/filter_logs', Filters)
        this.logs = response.data.logs
      }, 
      clearFilterLogs() {
        this.$refs.userSelect.clear()
        this.$refs.siteSelect.clear()
        this.filterMenu = false
        this.results = 100
        this.search = ''
        this.filterLogs()
      }
    }
  }
</script>