<template>
  <v-container fluid class="users-page mt-5">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="adminUsers"
          :search="search"
          :loading="loading"
          sort-by="id"
          class="elevation-1"
          @click:row="editItem"
        >
          <template v-slot:top>
            <v-toolbar flat height="90" >
              <v-toolbar-title>users</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                clearable
                flat
                color="primary"
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="newItem"
              >
                New User
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            {{ showRole (item.role) }}
          </template>
          <template v-slot:[`item.tenant`]="{ item }">
            {{ showTenantName (item.tenant_id) }}
          </template>
          <template v-slot:[`item.user_status`]="{ item }">
            <v-icon small>{{ item.user_status ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" @click.stop="loginAs(item)">mdi-login-variant</v-icon>
              </template>
              <span>Login as {{item.fname}}</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    name: 'users',
    data: () => ({
      search: '',
      loading: true,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Email/Username', value: 'email'},
        { text: 'First Name', value: 'fname' },
        { text: 'Last Name', value: 'lname' },
        { text: 'Tenant', value: 'tenant' },
        { text: 'Role', value: 'role' },
        { text: 'Active', value: 'user_status' },
        { text: 'Created at', value: 'created_at' },
        { text: 'Updated at', value: 'updated_at' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'right' },
      ],
    }),

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', 'Admin > Users')
    },

    computed: {
      ...mapGetters(['adminUserRoles', 'adminUsers', 'adminTenants']),
    },
    
    methods: {
      async initialize () {
        let response = await this.$http.get('/admin/users')
        this.$store.commit('setAdminData', response.data)
        this.loading = false
      },

      newItem () {
        this.$store.commit('openAdminNewUserCard')
      },

      editItem (item) {
        this.$store.commit('openAdminUserCard', item.id)
      },

      loginAs(user) {
        let routeData = this.$router.resolve({path: '/loginas', query: {id: user.id, name: user.fname+" "+user.lname}})
        window.open(routeData.href, '_blank')
      },

      showRole (role) {
        if (role === 5) {
          return "Superadmin"
        } else if (role === 4) {
          return "Admin"
        } else if (role === 3) {
          return "Tenant Manager"
        } else if (role === 2) {
          return "Contributor"
        } else if (role === 1) {
          return "Reader"
        } else {
          return "no role assigned"
        }
      },

      showTenantName (id) {
        return this.adminTenants.find(obj => { return obj.id === id }).name
      },
    }
  }
</script>