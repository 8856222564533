const state = {
  adminUsers: [],
  adminTenants: [],
  adminSites: [],
  adminSelectedUser: {},
  adminUserCardDialog: false,
};

const getters = {
  adminUsers: state => state.adminUsers,
  adminTenants: state => state.adminTenants,
  adminSites: state => state.adminSites,
  adminSelectedUser: state => state.adminSelectedUser,
  adminUserCardDialog: state => state.adminUserCardDialog,
};

const mutations = {
  setAdminData (state, data) {
    state.adminUsers = data.users
    state.adminTenants = data.tenants
    state.adminSites = data.sites
  },
  clearAdminData (state) {
    state.adminUsers = []
    state.adminTenants = []
    state.adminSites = []
  },
  openAdminNewUserCard (state) {
    state.adminSelectedUser = {
      username: '',
      password: '',
      email: '',
      fname: '',
      lname: '',
      tenant_id: '',
      user_status: true,
      site_accesses: [],
    }
    state.adminUserCardDialog = true
  },
  openAdminUserCard (state, id) {
    state.adminSelectedUser = state.adminUsers[state.adminUsers.findIndex(a => a.id === id)]
    state.adminUserCardDialog = true
  },
  closeAdminUserCard (state) {
    state.adminUserCardDialog = false
    state.adminSelectedUser = {
      username: '',
      password: '',
      email: '',
      fname: '',
      lname: '',
      tenant_id: '',
      user_status: true,
      site_accesses: [],
    }
  },

  createAdminUser (state, user) {
    state.adminUsers.push(user)
  },

  updateAdminUser (state, user) {
    Object.assign(state.adminUsers[state.adminUsers.findIndex(a => a.id === user.id)], user)
  },

  deleteAdminUser (state, id) {
    state.adminUsers = state.adminUsers.filter(a => a.id !== id)
  },

  updateAdminSiteAccess (state, siteAccess) {
    Object.assign(state.adminSelectedUser.site_accesses[state.adminSelectedUser.site_accesses.findIndex(a => a.id === siteAccess.id)], siteAccess)
  },
  createAdminSiteAccess (state, siteAccess) {
    state.adminSelectedUser.site_accesses.push(siteAccess)
  },
};

export default {
  state,
  getters,
  mutations
};