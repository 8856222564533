<template>
  <v-autocomplete
    v-model="users"
    :items="items"
    :label="label"
    :filter="userFilter"
    item-value="id"
    prepend-icon="mdi-account"
    clearable
    multiple
    chips
    :dense="dense"
    @change="input"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar left>
          <img v-if="data.item.avatar" :src="data.item.avatar">
          <span v-else class="white--text headline">{{ data.item.fname?.substring(0,1) }}</span>
        </v-avatar>
        {{ data.item.fname }} {{ data.item.lname }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template>
        <v-list-item-avatar color="primary">
          <img v-if="data.item.avatar" :src="data.item.avatar">
          <span v-else class="white--text headline">{{ data.item.fname?.substring(0,1) }}</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >{{ data.item.fname }} {{ data.item.lname }}</v-list-item-title>
          <v-list-item-subtitle >{{ data.item.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
  export default {
    name: 'UserSelect',
    data() {
      return {
        search: '',
        users: []
      }
    },
    props: ['items', 'label', 'dense'],
    methods: {
      userFilter (item, queryText) {
        const textOne = item.fname.toLowerCase()
        const textTwo = item.lname.toLowerCase()
        const textThree = item.email.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1 || textThree.indexOf(searchText) > -1
      },
      input () {
        this.$emit('submit', this.users)
      },
      remove (item) {
        const index = this.users.indexOf(item.id)
        if (index >= 0) this.users.splice(index, 1)
      },
      clear () { 
        this.users = []
        this.search = ''
        this.input()
      },
    },

  }
</script>