<template>
  <v-container fluid class="logs-page mt-5">
    <v-virtual-scroll :items="deviceLogs" height="600" item-height="50" class="no-v-scrollbar">
      <template v-slot:default="{ item }">
        <v-row>
          <v-col cols="1" class="d-flex justify-center align-center">
            <strong>{{ item.created_at | moment($store.getters.dateFormat) }}</strong>
          </v-col>
          <v-col cols="1" class="d-flex justify-center align-center">
            <strong>{{ item.created_at | moment($store.getters.timeFormat) }}</strong>
          </v-col>
          <v-col cols="3">
            <strong>{{item.note}}</strong> by {{ item.user_name }}

          </v-col>
        </v-row>
      </template>
    </v-virtual-scroll>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Logs',
    data: () => ({
      search: '',
      loading: true,
      users: [],

    }),
    props: ['view'],
    computed: {
      ...mapGetters(['site', 'device', 'sitePermission', 'deviceLogs']),
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        let response = await this.$http.get('/global/user_list')
        this.users = response.data.users
        this.loading = false
      },
    },
  }
</script>