<template>
  <attachments view="site"/>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Attachments from '@/components/Multimodal/Attachments'

  export default {
    name: "Attachments-page",
    components: {
      Attachments,
    },
    data() {
      return {}
    },
    computed: {
      ...mapGetters(['site']),
    },
    methods: {
      initialize () {
        this.loading = false
      },
    },
    mounted() {
      this.initialize()
      this.$store.commit('setHeaderTitle', this.site.name + ' > Attachments')
    },
  };
</script>