<template>
  <v-container fluid class="support-page">
    <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
      <h1 class="page-title">Support</h1>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p>
          Help by Tenant for problems related this site
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "SupportSite",
  data() {
    return {
     
    };
  },
  computed: {
    ...mapGetters(['site']),
  },
  mounted() {
    this.$store.commit('setHeaderTitle', this.site.name + ' > Support')
  },
  methods: {
    
  },
};
</script>