<template>
  <v-container fluid class="companies-page mt-5">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="customers"
          :search="search"
          :loading="loading"
          sort-by="id"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              height="90"
            >
              <v-toolbar-title>Companies</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                clearable
                flat
                color="primary"
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Company
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Company Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-checkbox
                            v-model="editedItem.customer"
                            label="Customer"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-checkbox
                            v-model="editedItem.manufacturer"
                            label="Manufacturer"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-checkbox
                            v-model="editedItem.supplier"
                            label="Supplier"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.tenant_id"
                            :items="tenants"
                            item-text="name"
                            item-value="id"
                            label="Tenant"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.tenant_id`]="{ item }">
            {{ showTenantName(item.tenant_id) }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    name: 'Customers',
    data: () => ({
      companies: [],
      tenants: [],
      search: '',
      loading: true,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Tenant', value: 'tenant_id' },
        { text: 'Created at', value: 'created_at' },
        { text: 'Updated at', value: 'updated_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        customer: false,
        manufacturer: false,
        supplier: false,
        tenant_id: 1
      },
      defaultItem: {
        name: '',
        customer: false,
        manufacturer: false,
        supplier: false,
        tenant_id: 1
      },
    }),

    computed: {
      ...mapGetters(['StateUser']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Company' : 'Edit Company'
      },
      customers () {
        return this.companies.filter( o => o.customer == true )
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', 'Customers')
    },

    methods: {
      async initialize () {
        let response = await this.$http.get('/tenant/companies')
        this.companies = response.data.companies
        this.tenants = response.data.tenants
        this.loading = false
        this.editedItem.tenant_id = this.StateUser.tenant_id
        this.defaultItem.tenant_id = this.StateUser.tenant_id
      },

      editItem (item) {
        this.editedIndex = this.companies.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.companies.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.companies.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        const Company = new FormData();
        Company.append("name", this.editedItem.name);
        Company.append("customer", this.editedItem.customer);
        Company.append("manufacturer", this.editedItem.manufacturer);
        Company.append("supplier", this.editedItem.supplier);
        Company.append("tenant_id", this.editedItem.tenant_id);
        if (this.editedIndex > -1) {
          Company.append("id", this.editedItem.id);
          let response = await this.$http.put('/tenant/companies', Company)
          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            Object.assign(this.companies[this.editedIndex], response.data.company)
            this.$toast.success("Company Updated"); 
          }
        } else {
          let response = await this.$http.post('/tenant/companies', Company)
          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            this.companies.push(response.data.company)
            this.$toast.success("Company Created"); 
          }
        }
        this.close()
      },

      showTenantName (id) {
        return this.tenants.find(obj => { return obj.id === id }).name
      }
    },
  }
</script>