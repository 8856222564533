<template>
  <v-menu offset-y bottom right nudge-bottom="10" transition="scale-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-0" v-bind="attrs" v-on="on" icon>
        <v-icon style="font-size: 28px"> mdi-compass</v-icon>
      </v-btn>
    </template>
    <v-card max-width="360px">
      <v-card-text>
        <v-row>
          <v-col 
            v-for="shortcut in items"
            :key="shortcut.title"
            cols="6"
            class="text-center cpointer shortcut-icon"
            @click="goTo(shortcut.link)"
          >
            
            <v-avatar>
              <v-icon x-large>{{ shortcut.icon }}</v-icon>
            </v-avatar>
            <v-card-subtitle class="py-0">
              {{ shortcut.title }}
            </v-card-subtitle>
            
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'SiteLinksCard',

    data: () => ({
      userItems: [
        { title: 'Dashboard', icon: 'mdi-monitor-dashboard', link: 'dashboard' },
        { title: 'Devices', icon: 'mdi-devices', link: 'devices' },
        { title: 'Locations', icon: 'mdi-floor-plan', link: 'locations' },     
        { title: 'Attachments', icon: 'mdi-paperclip', link: 'attachments' },
        { title: 'Vault', icon: 'mdi-shield-lock', link: 'vault' },
        { title: 'Contacts', icon: 'mdi-card-account-details', link: 'contacts' },
      ],
      adminItems: [
        { title: 'Access', icon: 'mdi-monitor-dashboard', link: 'access' },
        { title: 'Logs', icon: 'mdi-devices', link: 'logs' },
        { title: 'Config', icon: 'mdi-floor-plan', link: 'config' },     
      ],
    }),
    computed: {
      ...mapGetters(['sitePermission']),
      items () {
        if (this.sitePermission == 3) {
          return [...this.userItems, ...this.adminItems]
        } else {
          return this.userItems
        }
      }
    },
    methods: {
      goTo(link) {
        let url = '/site/'+ this.$route.params.site_id + '/'+ link
        this.$router.push(url)
      }
    }
  };
</script>

<style lang="scss">
  .shortcut-icon:hover {
    background-color: #2AAD7444;
  }
</style>