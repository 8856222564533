<template>
  <div>
    <div v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
    <div v-else>
      <v-file-input
        v-model="uploadObject"
        @change="uploadFile"
        :label="label"
        truncate-text="200"
        :clearable="false"
      />
    </div>
  </div>
</template>

<script>
  import S3 from 'aws-s3';
  import {mapGetters} from 'vuex'

  export default {
    name: 'S3FileUploader',
    data() {
      return {
        loading: false,
        uploadObject: {name: this.obj[this.fieldName]},
      }
    },
    props: ['obj', 'fieldName', 'directory', 'label'],
    computed: {
      ...mapGetters(['s3Config']),
      S3Client() {
        return new S3(this.s3Config)
      },
      newFileName() {
        if (this.obj.name) {
          return this.obj.name.trim().split(' ').join('_')+'_'+(Math.random().toString().slice(2))
        } else {
          return (Math.random().toString().slice(2))
        }
      }
    },
    methods: {
      uploadFile() {
        this.S3Client.config.dirName = this.directory
        let file = this.uploadObject
        this.isLoading = true
        this.S3Client
          .uploadFile(file, this.newFileName)
          .then(data => {
            this.$emit('located', data.location) 
            this.isLoading = false
            this.uploadObject = {name: this.obj[this.fieldName]}
          })
      },
    },

  }
</script>