const state = {
  title: "Wikibuild"
};
const getters = {
  HeaderTitle: state => state.title
};
const actions = {
  async setHeaderTitle({commit}, title) {
    commit('setHeaderTitle', title)
  }
};
const mutations = {
  setHeaderTitle (state, title) {
    state.title = title
  },
};
export default {
  state,
  getters,
  actions,
  mutations
};