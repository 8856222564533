<template>
  <v-card v-if="floor.id" class="fixed-card" min-height="500">
    <div v-if="floor.svg">
      <v-toolbar
        dense
        floating
        class="floortoolbar"
        v-if="zone.id"
      >
        <v-btn icon color="primary" @click="undoEditing" v-if="editing">
          <v-icon>mdi-undo</v-icon>
        </v-btn>
        <v-btn icon color="primary" @click="toggleEditing" v-if="sitePermission>1">
          <v-icon v-if="editing">mdi-close</v-icon>
          <v-icon v-else>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon color="primary" @click="toggleZoom">
          <v-icon v-if="zooming">mdi-magnify-minus</v-icon>
          <v-icon v-else>mdi-magnify-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar
        dense
        floating
        class="floortoolbar"
        v-else-if="sitePermission>1"
      >
        <v-dialog v-model="deleteDialog" width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="error"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <v-card class="text-center py-3">
            <v-icon style="font-size: 64px" color="error" class="my-5">mdi-delete</v-icon>
            <v-card-title>
              <v-spacer />
              <div class="text-center">
                <h3 class="headline error--text mb-0">Delete Floorplan for {{floor.name}}</h3>
                <h3 class="headline error--text mb-0">Are you Sure ?</h3>
                <div>Deleted items cannot be retrieved</div>
              </div>
              <v-spacer />
            </v-card-title>
            <v-card-actions class="mt-5">
              <v-spacer />
              <v-btn @click="deleteDialog = false">Cancel</v-btn>
              <v-btn @click="deletePlan" color="error">Confirm</v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <inline-svg 
        :src="floor.svg"
        id="floorplan"
        @loaded="createFloorplan"
      >
      </inline-svg>
      <div id="zoCard"></div>
      <div id="selZoCard"></div>
    </div>
    <div v-else>
      <v-sheet heigth="600" class="text-center mt-12">
        <span class="text-h5">No floorplan Uploaded for this Floor</span> 
        <p class="mt-5 mb-0" v-if="sitePermission>1">
          In order to work properly floorplans need to be of the SVG format (Scalable Vector Graphics).
        </p>
        <p class="mt-2 mb-0" v-if="sitePermission>1">
          Contact Wikibuild support if you need help converting your plans to this Format.   
        </p>
        <p class="my-2" v-if="sitePermission>1">
          Click on the image below to upload your SVG.   
        </p>
        <svg-uploader :floor="floor" directory="floorplans" @located="setFloorSvg" :site_id="site.id" v-if="sitePermission>1"/>
        <div v-if="newSvg">
          <p class="mt-5">
            Great you've selected a suitable format can we proceed to upload this floorplan to Wikibuild ? You can change your file by clicking the image above.  
          </p>
          <v-btn text color="success" class="mt-5" @click="uploadPlan">
            <v-icon left> mdi-upload </v-icon>
            Upload Plan
          </v-btn>
        </div>
      </v-sheet>
    </div>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import * as d3 from 'd3'
import InlineSvg from 'vue-inline-svg'
import SvgUploader from '@/components/S3Upload/SvgUploader'

    
export default {
  name: "ZoneCard",
  components: {
    InlineSvg,
    SvgUploader,
  },
  data() {
    return {
      item: {},
      itemType: "",
      editing: false,
      zooming: false,
      svg: null,
      initialViewBox: [],
      viewBox: [],
      mouseX: 0,
      mouseY: 0,
      mouseXabs: 0,
      mouseYabs: 0,
      points: [], 
      lastPoint: null,
      startPoint: null,
      polygon: null,
      zoCard: null,
      newSvg: null,
      deleteDialog: false
    };
  },
  computed: {
    ...mapGetters(['site', 'floor', 'location', 'zone', 'sitePermission']),
    //all zones with a polygon except the selected zone if any
    polyZones() {
      if (this.floor.id) {
        if (this.zone.id) {
          let zones  = this.floor.zones.filter( o => o.polygon )
          return zones.filter(o => o.id !== this.zone.id)
        } else {
          return this.floor.zones.filter( o => o.polygon )
        }
      } else {
        return []
      }
    },
    nonPolyZones() {
      if (this.floor.id) {
        return this.floor.zones.filter( o => o.polygon == null )
      } else {
        return []
      }
    }
  },
  watch: {
    floor(){
      if (this.svg) { this.drawFloor() }
    },
    zone() {
      if (this.svg) { this.drawZone() }
    }
  },
  methods: {
    setFloorSvg (value) {
      this.newSvg = value
    },
    async uploadPlan () {
      const Floor = new FormData()
      Floor.append("id", this.floor.id)
      Floor.append("svg", this.newSvg)
      let response = await this.$http.put('/site/floors', Floor)
      this.$store.commit('updateFloor', response.data.floor)
      this.$toast.success("FloorPlan Uploaded")
      this.newSvg = null
    },
    async deletePlan() {
      const Floor = new FormData()
      Floor.append("id", this.floor.id)
      Floor.append("svg", "")
      let response = await this.$http.put('/site/floors', Floor)
      this.deleteDialog = false
      this.$store.commit('updateFloor', response.data.floor)
      this.$toast.success("FloorPlan Deleted")
    },
    drawFloor() {
      let that = this
      document.querySelectorAll(".zone_group").forEach(el => el.remove());
      if (this.floor.zones) {
        let lg = that.svg
                      .select('#zones_group')
                      .selectAll('g')
                      .data(that.polyZones)
                      .enter()
                      .append('g')
                      .classed("zone_group", true)
                      .attr("id", function(d) {return "zon_"+d.id})

        lg.append("polygon")
          .attr("points", function(d) {return d.polygon})
          .attr("id", function(d) { return "zone_poly_"+d.id})
          .attr("fill", "#000000")
          .attr("fill-opacity", "0.3")
          .classed("zone_poly", true)
          .on('mouseover', function (d) {
            d3.select(this)
              .transition()
              .duration('50')
              .attr("fill", "#FF0000")
            that.zoCard.html(d.target.__data__.name+" || "+d.target.__data__.code)
            that.zoCard.transition()
                        .duration(50)
                        .style("opacity", 1)
                        .style("left", (that.mouseXabs+30) + "px")
                        .style("top", (that.mouseYabs-30) + "px")
          })
          .on('mouseout', function (d, i) {
            d3.select(this)
              .transition()
              .duration('50')
              .attr("fill", "#000000")
            that.zoCard.transition()
                        .duration(50)
                        .style("opacity", 0);
          })
          .on('click', function (d) {
            that.showItem(d.target.__data__.id)
          })


      }    
      this.drawZone()
    },
    drawZone() {
      let that = this
      document.querySelectorAll(".zone_group_selected").forEach(el => el.remove());
      if (this.zone.polygon) {
        that.svg.select('#zones_group')
          .append('g')
          .classed("zone_group_selected", true)
          .attr("id", "zon_" + this.zone.id)
          .append("polygon")
          .attr("points", this.zone.polygon)
          .attr("fill", "#2AAD74")
          .attr('fill-opacity', '0.3')
          .classed("zone_poly_selected", true)
        that.svg.select('.zone_group_selected')
                .append('text')
                .text(this.zone.name + " || " + this.zone.code)
                .attr('x', that.viewBoxtoArray(that.findViewBox(that.zone.polygon))[0])
                .attr('y', that.viewBoxtoArray(that.findViewBox(that.zone.polygon))[1]-5)
      }
    },
    createFloorplan () {
      let that = this
      this.svg = d3.select("#floorplan")
      if (this.svg.attr("viewBox")) {
        this.initialViewBox = this.svg.attr("viewBox").split(" ")
        this.viewBox = this.svg.attr("viewBox").split(" ")
      } else {
        let h = this.svg.attr("height")
        let w = this.svg.attr("width")
        this.initialViewBox = [0, 0, w, h]
        this.viewBox = [0, 0, w, h]
      }
      this.svg.attr("viewBox", this.viewBox.join(" ")).attr("height", "700px").attr("width", "100%")

      this.svg.on('mousemove', function(event) {
        let coords = d3.pointer(event);
        that.mouseX = Math.round(coords[0])
        that.mouseY = Math.round(coords[1])
        let absCoords = d3.pointer(event, "body")
        that.mouseXabs = Math.round(absCoords[0])
        that.mouseYabs = Math.round(absCoords[1])
      });

      this.svg.on('click', function () {
        if (that.editing) {
          document.querySelectorAll(".zone_group_selected").forEach(el => el.remove());
          that.lastPoint = that.svg.append("circle")
                                    .attr("cx", that.mouseX)
                                    .attr("cy", that.mouseY)
                                    .attr("r", 5)
                                    .attr("class", "tempdots")
                                    .style("fill", "red")
                                    .style("cursor", 'pointer')

          that.points.push(that.mouseX + ',' + that.mouseY)

          if (!that.startPoint) {
            that.startPoint = that.lastPoint

            that.startPoint.on('click', function () {
              that.polygon = that.points.join(" ");
              that.svg.append("g")
                      .classed("zone_group", true)
                      .append("polygon")
                      .attr("points", that.polygon)
                      .attr("fill", "#2AAD74")
                      .attr('fill-opacity', '0.3')
                      .classed("zone_poly_selected", true)  
              setTimeout(() => {
                document.querySelectorAll(".tempdots").forEach(el => el.remove());
                that.startPoint = null
                that.lastPoint = null
                that.points = []
              }, 300)
              that.savePolygon()
            });
          };
        }
      })

      this.svg.append("g").attr('id', 'zones_group')
      this.zoCard = d3.select("#zoCard")
      this.drawFloor()
      this.drawZone()
    },
    toggleZoom() {
      if (this.zone.polygon) {
        if (this.zooming) {
          this.svg.attr("viewBox", this.initialViewBox.join(" "))
          this.zooming = false
        } else {
          let zoomBox = this.findViewBox(this.zone.polygon)
          this.svg.attr("viewBox", zoomBox)
          this.zooming = true
        }
      }
    },
    toggleEditing () {
      if (this.editing) {
        this.editing = false
        this.polygon = null
        this.startPoint = null
        this.lastPoint = null
        this.points = []
        document.querySelectorAll(".tempdots").forEach(el => el.remove())
        this.drawZone()
      } else {
        this.editing = true
      }
    },
    undoEditing () {
      let tempdots = document.querySelectorAll('.tempdots')
      tempdots[tempdots.length-1].remove()
      this.points.pop()
      if (this.points.length == 0) {this.startPoint = null}
    },
    findViewBox(str) {
      let arr = str.split(/[ ,]+/);
      let arrX = [];
      let arrY = [];
      for (let i = 0; i < arr.length; i++) {
        if ((i + 2) % 2 == 0) {
          arrX.push(parseInt(arr[i]));
        }
        else {
          arrY.push(parseInt(arr[i]));
        }
      }
      let xMin = Math.min(...arrX);
      let yMin = Math.min(...arrY);
      let xMax = Math.max(...arrX);
      let yMax = Math.max(...arrY);
      let width = xMax - xMin;
      let height = yMax - yMin;
      let viewBox = xMin + " " + yMin + " " + width + " " + height;
      return viewBox;
    },
    viewBoxtoArray(str) {
      let arr = str.split(" ")
      return [arr[0], arr[1]]
    },
    async savePolygon() {
      const Zone = new FormData();
      Zone.append("polygon", this.polygon);
      Zone.append("id", this.zone.id)
      let response = await this.$http.put('/site/zones', Zone)
      this.$store.commit('updateZone', response.data.zone)
      this.$store.commit('setZone', response.data.zone)
      this.$toast.success("Zone Updated")
      this.editing = false
      this.polygon = null
      this.drawFloor()
      this.drawZone()
    },
    showItem(id) {
      if (!this.editing) { this.$emit('newZone', id) } 
    },
    statusColor(status) {
      let index = this.site.device_statuses.findIndex(a => a.name == status)
      return (index > -1) ? this.site.device_statuses[index].color : "#F1F1F1"
    },
  },
  mounted() {
    this.$store.commit('setHeaderTitle', this.site.name + ' > Zones')
    this.$store.commit('unsetFloorLocationZone')
  },
};
</script>


<style lang="scss" scoped>
  .floortoolbar {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  #zoCard,
  #selZoCard {
     position: fixed;
     text-align: center;
     padding: .5rem;
     background: #FFFFFF;
     border: 1px solid #000; 
     opacity: 0;
  }

  .fixed-card {
    position: sticky;
    top: 64px;
  }

</style>