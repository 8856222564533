<template>
  <v-card min-height="85vh">
    <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
      <v-toolbar-title class="headline primary--text">Device Statuses</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu :close-on-content-click="false" v-model="newMenu" offset-x min-width="150" left>
        <template #activator="{ on: onMenu }">
          <v-tooltip top>
            <template #activator="{ on: onTooltip }">
              <v-btn icon v-on="{ ...onMenu, ...onTooltip }" color="primary">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>

            <span>Add Status</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item>
            <v-text-field label="Status" v-model="newStatus" v-on:keyup.enter="addStatus"></v-text-field>
          </v-list-item>
          <v-list-item>
            Color
            <v-color-picker
              v-model="newColor"
              hide-canvas
              hide-inputs
              hide-sliders
              hide-mode-switch
              mode="hex"
              :swatches="swatches"
              show-swatches
            ></v-color-picker>
          </v-list-item>
          <v-list-item>
            <v-spacer></v-spacer>
            <v-btn icon @click="addStatus" color="primary">
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn icon @click="newMenu = false" color="error">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" color="primary">
            <v-icon @click="sort">mdi-sort</v-icon>
          </v-btn>
        </template>
        <span>To original order</span>
      </v-tooltip>
      <v-menu
        v-model="editMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        :close-on-content-click="false" 
      >
        <v-list>
          <v-list-item>
            <v-text-field label="Status" v-model="editedItem.name" v-on:keyup.enter="updateStatus"></v-text-field>
          </v-list-item>
          <v-list-item>
            Color
            <v-color-picker
              v-model="editedItem.color"
              hide-canvas
              hide-inputs
              hide-sliders
              hide-mode-switch
              mode="hexa"
              :swatches="swatches"
              show-swatches
            ></v-color-picker>
          </v-list-item>
          <v-list-item>
            <v-spacer></v-spacer>
            <v-btn icon @click="updateStatus" color="primary">
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn icon @click="deleteStatus(editedItem)" color="error">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <draggable v-model="device_statuses" v-bind="dragOptions" @start="drag = true" @end="drag = false" @change="saveStatuses">
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <v-toolbar v-for="element in device_statuses" :key="element.id" class="list-group-item mt-1" dense elevation="1">
          <v-icon @click="editStatus($event, element)" class="mr-10">mdi-menu</v-icon>
          <v-toolbar-title :style="{ color: element.color }">{{element.name}}</v-toolbar-title>
        </v-toolbar>
      </transition-group>
    </draggable>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: "DeviceStatus",
  components: {
    draggable,
  },
  data() {
    return {
      loading: true,
      device_statuses: [],
      drag: false,
      newStatus: "",
      newMenu: false, 
      newColor: "#2AAD74",
      editMenu: false,
      x: 0,
      y: 0,
      editedItem: {
        id: "",
        name: "",
        added: true,
        color: ""
      },
      defaultItem: {
        id: "",
        name: "",
        added: true,
        color: ""
      },
      swatches: [
        ['#2AAD74', '#4797ff', '#FFC260', '#fc5454', '#4a4a4a'],
        ['#00C853', '#2962FF', '#FFAB00', '#D50000','#212121'],
        ['#1B5E20', '#0D47A1', '#FF6F00','#B71C1C', '#616161'],
        ['#66BB6A', '#42A5F5', '#FFCA28', '#EF5350','#BDBDBD'],
        ['#C8E6C9', '#BBDEFB', '#FFECB3','#FFCDD2', '#F5F5F5'],
      ],
    }
  },
  computed: {
    ...mapGetters(['site']),
    dragOptions() {
      return {
        animation: 500,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize () {
      this.site.device_statuses.forEach(e => this.device_statuses.push(e))
      this.loading = false
    },
    
    sort() {
      this.device_statuses = this.device_statuses.sort((a, b) => a.id - b.id)
      this.saveStatuses()
    },

    addStatus() {
      let id = Math.max(...this.device_statuses.map(o => o.id)) + 1
      this.device_statuses.push({name: this.newStatus, id: id, added: true, color: this.newColor})
      this.newStatus = ""
      this.newColor = "#2AAD74"
      this.newMenu = false
      this.saveStatuses()
    },

    async saveStatuses() {
      const Site = new FormData()
      Site.append("device_statuses", JSON.stringify(this.device_statuses))
      Site.append("id", this.site.id)
      let response = await this.$http.put('/site/sites', Site)
      if (response.data.error) {
        Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
      } else {
        this.$store.commit('updateSite', response.data.site)
        this.$toast.success("Saved")
      }
    },

    editStatus(e, item) {
      this.editedItem = item
      this.editMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.editMenu = true
      })
    },

    updateStatus(item) {
      this.device_statuses[this.device_statuses.indexOf(item)] = this.editedItem
      this.editedItem = this.defaultItem
      this.editMenu = false
      this.saveStatuses()
    },

    deleteStatus (item) {
      this.device_statuses.splice(this.device_statuses.indexOf(item), 1)
      this.editMenu = false
      this.saveStatuses()
    },
  },
}
</script>

<style lang="scss" scoped>
  .flip-list-move {
    transition: transform 1s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .list-group-item {
    cursor: move;
  }
  .list-group-item i {
    cursor: pointer;
  }
</style>