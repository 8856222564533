<template>
  <v-card height="90vh" class="scroll-card">
    <v-card-text>
      <v-row class="pt-3">
        <v-col cols="2">
          <v-img
            v-if="dev.product?.image"
            height="150"
            :src="dev.product.image"
            contain
          ></v-img>
        </v-col>
        <v-col cols="10">
          <div class="float-right mt-2">
            <v-tooltip top v-if="sitePermission>1">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="toggleArchive()" class="mr-5">
                  <v-icon v-if="dev.archived" color="error">mdi-archive-cancel-outline</v-icon>
                  <v-icon v-else color="primary">mdi-archive-check-outline</v-icon>
                </v-btn>
              </template>
              <span v-if="dev.archived">Device Archived</span>
              <span v-else>Archive this Device</span>
            </v-tooltip>
            <v-tooltip top v-if="sitePermission>1">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="editing = !editing" class="mr-5">
                  <v-icon v-if="editing" color="error">mdi-pencil-off</v-icon>
                  <v-icon v-else color="primary">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip top v-if="sitePermission>1">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="updateDevice" class="mr-5">
                  <v-icon color="primary">mdi-content-save</v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="closeDialog">
                  <v-icon color="error">mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </div>
          
          <v-row v-if="editing" class="mt-4">
            <v-col cols="5">
              <v-text-field v-model="dev.name" outlined label="Device Name" hide-details></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field v-model="dev.code" outlined label="Device Code" hide-details></v-text-field>
            </v-col>
            <v-col cols="10">
              <h4 class="primary--text mt-2">{{dev.product?.procat?.name}}</h4>
            </v-col>
            <v-col cols="2">
              <status-selector></status-selector>
            </v-col>
          </v-row>

          <v-row v-else class="mt-3 mb-1">
            <v-col cols="12">
              <h1 class="page-title">
                {{dev.name}} || {{dev.code}}
              </h1>
            </v-col>
            <v-col cols="10">
              <h4 class="primary--text mt-2">{{dev.product?.procat?.name}}</h4>
            </v-col>
            <v-col cols="2">
              <status-selector></status-selector>
            </v-col>
          </v-row>
          
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab" fixed grow>
            <v-tab>General</v-tab>
            <v-tab>Specifications</v-tab>
            <v-tab>Files & Pictures</v-tab>
            <v-tab>Vault</v-tab>
            <v-tab @click="reloadLogs">Activity</v-tab>
            <!-- <v-tab>Comments</v-tab>
            <v-tab>Tickets</v-tab> -->
          </v-tabs>
          <v-tabs-items v-model="tab" class="mt-5">
            <v-tab-item>
              <v-row v-if="editing">
                <v-scroll-x-transition>
                  <v-col cols="9">
                    <v-row>
                      <v-col cols="3">
                        <v-list>
                          <v-list-item v-for="(item, i) in col1" :key="i">
                            <v-list-item-icon>
                              <v-icon class="pl-2">{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="3">
                        <v-list>
                          <v-list-item class="mb-2">
                            <v-text-field v-model="dev.sn" dense hide-details reverse></v-text-field>
                          </v-list-item>
                          <v-list-item class="mb-2">
                            <v-text-field v-model="dev.mac" dense hide-details reverse></v-text-field>
                          </v-list-item>
                          <v-list-item class="mb-2">
                            <v-text-field v-model="dev.ip" dense hide-details reverse></v-text-field>
                          </v-list-item>
                          <v-list-item class="mb-2">
                            <v-text-field v-model="dev.patch" dense hide-details reverse></v-text-field>
                          </v-list-item>
                          <v-list-item class="mb-2">
                            <v-text-field v-model="dev.port" dense hide-details reverse></v-text-field>
                          </v-list-item>
                          <v-list-item class="mb-2">
                            <v-menu
                              v-model="dp1"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="computedPurchaseDate"
                                  v-bind="attrs"
                                  v-on="on"
                                  dense 
                                  clearable      
                                  hide-details
                                  reverse
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="dev.purchase_date"
                                @input="dp1 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="3">
                        <v-list>
                          <v-list-item v-for="(item, i) in col2" :key="i">
                            <v-list-item-icon>
                              <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="3">
                        <v-list>
                          <v-list-item class="mb-2">
                            <v-autocomplete v-model="dev.floor_id" :items="site.floors" item-text="name" item-value="id" clearable dense hide-details >
                              <template v-slot:selection="data">
                                {{ data.item.name }} || {{ data.item.code }}
                              </template>
                              <template v-slot:item="data">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ data.item.code }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-list-item>
                          <v-list-item class="mb-2">
                            <v-autocomplete v-model="dev.location_id" :items="locationsOnFloor" item-text="name" item-value="id" clearable dense hide-details>
                              <template v-slot:selection="data">
                                {{ data.item.name }} || {{ data.item.code }}
                              </template>
                              <template v-slot:item="data">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ data.item.code }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-list-item>
                          <v-list-item class="mb-2">
                            <v-autocomplete v-model="dev.zone_id" :items="zonesOnFloor" item-text="name" item-value="id" clearable dense hide-details>
                              <template v-slot:selection="data">
                                {{ data.item.name }} || {{ data.item.code }}
                              </template>
                              <template v-slot:item="data">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ data.item.code }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-list-item>
                          <v-list-item class="mb-2">
                            <v-autocomplete v-model="dev.layer_id" :items="site.layers" item-text="name" item-value="id" clearable dense hide-details>
                              <template v-slot:selection="data">
                                {{ data.item.name }} || {{ data.item.code }}
                              </template>
                              <template v-slot:item="data">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ data.item.code }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-list-item>
                          <v-list-item class="mb-2">
                            <v-autocomplete v-model="dev.company_id" :items="suppliers" item-text="name" item-value="id" clearable dense hide-details/>
                          </v-list-item>
                          <v-list-item class="mb-2">
                            <v-menu
                              v-model="dp2"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="computedWarrantyExpirationDate"
                                  v-bind="attrs"
                                  v-on="on"
                                  clearable
                                  dense 
                                  hide-details
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="dev.warranty_expiration_date"
                                @input="dp2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          outlined
                          dense
                          label="Note"
                          v-model="dev.note"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-scroll-x-transition>
              </v-row>
              <v-row v-else>
                <v-scroll-x-transition>
                  <v-col cols="9">
                    <v-row>
                      <v-col cols="6">
                        <v-list>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-barcode</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="pl-2">Serial Number</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action >
                              <v-list-item-title class="primary--text">{{dev.sn}}</v-list-item-title>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-xml</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="pl-2">Mac Adress</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action >
                              <v-list-item-title class="primary--text">{{dev.mac}}</v-list-item-title>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-lan</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="pl-2">IP address</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action >
                              <v-list-item-title class="primary--text">{{dev.ip}}</v-list-item-title>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-ethernet</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="pl-2">Patch</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action >
                              <v-list-item-title class="primary--text">{{dev.patch}}</v-list-item-title>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-server</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="pl-2">Port</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action >
                              <v-list-item-title class="primary--text">{{dev.port}}</v-list-item-title>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-calendar-edit</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="pl-2">Purchase Date</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action >
                              <v-list-item-title class="primary--text">{{ dev.purchase_date | moment($store.getters.dateFormat) }}</v-list-item-title>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="6">
                        <v-list>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-layers-triple</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="pl-2">Floor</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action >
                              <v-list-item-title v-if="dev.floor" class="primary--text">{{dev.floor?.name}} || {{dev.floor?.code}}</v-list-item-title>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-table</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="pl-2">Location</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action >
                              <v-list-item-title v-if="dev.location" class="primary--text">{{dev.location?.name}} || {{dev.location?.code}}</v-list-item-title>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-table</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="pl-2">Zone</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action >
                              <v-list-item-title v-if="dev.zone" class="primary--text">{{dev.zone?.name}} || {{dev.zone?.code}}</v-list-item-title>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-layers-triple</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="pl-2">Layer</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action >
                              <v-list-item-title v-if="dev.layer" class="primary--text">{{dev.layer?.name}} || {{dev.layer?.code}}</v-list-item-title>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-package-variant</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="pl-2">Supplier</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action >
                              <v-list-item-title class="primary--text">{{dev.company?.name}}</v-list-item-title>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-calendar-alert</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="pl-2">Warranty Expires</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action >
                              <v-list-item-title class="primary--text">{{ dev.warranty_expiration_date | moment($store.getters.dateFormat) }}</v-list-item-title>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <h5>Note</h5>
                        <p>{{ dev.note }}</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-scroll-x-transition>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row>
                <v-col cols="4">
                  <v-list>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon>mdi-package-variant</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Product Name</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action >
                        <v-list-item-title class="primary--text">{{dev.product.name}}</v-list-item-title>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon>mdi-tag</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Brand</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action >
                        <v-list-item-title class="primary--text">{{dev.product.brand}}</v-list-item-title>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon>mdi-package</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Model</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action >
                        <v-list-item-title class="primary--text">{{dev.product.product_model}}</v-list-item-title>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon>mdi-format-list-bulleted-type</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Product Category</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action >
                        <v-list-item-title class="primary--text">{{dev.product?.procat?.name}}</v-list-item-title>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon>mdi-truck</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Supplier</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action >
                        <v-list-item-title class="primary--text">{{dev.product.preferred_supplier_id}}</v-list-item-title>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon>mdi-tools</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Manufacturer</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action >
                        <v-list-item-title class="primary--text">{{dev.product.company.name}}</v-list-item-title>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon>mdi-web</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Webpage</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action >
                      <v-list-item-title class="primary--text">{{dev.product.product_url}}</v-list-item-title>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-card flat >
                <attachments view="device"/>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <secrets view="device"/>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <logs view="device"/>
              </v-card>
            </v-tab-item>
            <!-- <v-tab-item>
              <v-card flat>
                <v-card-text>hello history</v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>hello resolution</v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>hello Tickets</v-card-text>
              </v-card>
            </v-tab-item> -->
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Attachments from '@/components/Multimodal/Attachments'
  import Secrets from '@/components/Multimodal/Secrets'
  import Logs from '@/components/Multimodal/Logs'
  import StatusSelector from '@/components/Selectors/StatusSelector.vue'

  export default {
    name: 'showDevice',
    components: {
      Attachments,
      Secrets,
      Logs,
      StatusSelector
    },
    data: () => ({
      dev: {},
      categories: [],
      suppliers: [],
      dp1: false,
      dp2: false,
      tab: 0,
      editing: false,
      col1: [
        {icon: "mdi-barcode", text: "Serial Number"},
        {icon: "mdi-xml", text: "Mac Adress"},
        {icon: "mdi-lan", text: "IP address"},
        {icon: "mdi-ethernet", text: "Patch"},
        {icon: "mdi-server", text: "Port"},
        {icon: "mdi-calendar-edit", text: "Purchase Date"},
      ], 
      col2: [
        {icon: "mdi-layers-triple", text: "Floor"},
        {icon: "mdi-table", text: "Location"},
        {icon: "mdi-table", text: "Zone"},
        {icon: "mdi-layers-triple", text: "Layer"},
        {icon: "mdi-package-variant", text: "Supplier"},
        {icon: "mdi-calendar-alert", text: "Warranty Expires"},
      ],
      statusMenu: false
    }),
    computed: {
      locationsOnFloor () {
        if (this.dev.floor_id) {
          let floorIndex = this.site.floors.findIndex(a => a.id == this.dev.floor_id)
          return this.site.floors[floorIndex].locations
        } else {
          return []
        }
      },
      zonesOnFloor () {
        if (this.dev.floor_id) {
          let floorIndex = this.site.floors.findIndex(a => a.id == this.dev.floor_id)
          return this.site.floors[floorIndex].zones
        } else {
          return []
        }
      },
      computedPurchaseDate () {
        return this.dev.purchase_date ? this.$moment(String(this.dev.purchase_date)).format('DD/MM/YYYY') : ''
      },
      computedWarrantyExpirationDate () {
        return this.dev.warranty_expiration_date ? this.$moment(String(this.dev.warranty_expiration_date)).format('DD/MM/YYYY') : ''
      },
      ...mapGetters(['site', 'device', 'sitePermission'])
    },
    watch: {
      device(){
        this.reload()
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      async initialize() {
        this.reload()
        let response = await this.$http.get('/site/devices/new')
        this.categories = response.data.procats
        this.suppliers = response.data.suppliers
        this.loading = false
      },
      reload() {
        this.dev = this.device
      },
      async updateDevice() {
        const Device = new FormData()
        Device.append('id', this.dev.id)
        if (this.dev.floor_id != null) { Device.append('floor_id', this.dev.floor_id)}
        if (this.dev.location_id != null) { Device.append('location_id', this.dev.location_id)}
        if (this.dev.zone_id != null) { Device.append('zone_id', this.dev.zone_id)}
        if (this.dev.layer_id != null) { Device.append('layer_id', this.dev.layer_id)}
        if (this.dev.company_id != null) { Device.append('company_id', this.dev.company_id)}
        Device.append('name', this.dev.name)
        Device.append('code', this.dev.code)
        Device.append('sn', this.dev.sn)
        Device.append('mac', this.dev.mac)
        Device.append('ip', this.dev.ip)
        Device.append('patch', this.dev.patch)
        Device.append('port', this.dev.port)
        Device.append('note', this.dev.note)
        Device.append('archived', this.dev.archived)
        Device.append('purchase_date', this.dev.purchase_date)
        Device.append('warranty_expiration_date', this.dev.warranty_expiration_date) 
        
        let response = await this.$http.put('/site/devices', Device)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('updateDevice', response.data.device)
          this.$toast.success('Device Updated')
          this.reload()
        }
        
      },

      async toggleArchive() {
        this.dev.archived = !this.dev.archived
        const Device = new FormData()
        Device.append('id', this.dev.id)
        Device.append('archived', this.dev.archived)
        let response = await this.$http.put('/site/devices', Device)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('updateDevice', response.data.device)
          if (this.dev.archived) {
            this.$toast.success('Device Archived')
          } else {
            this.$toast.success('Device Removed from Archive')
          }
        }
      },

      closeDialog() {
        this.$store.commit('closeDeviceDialog')
        this.tab = 0
      },

      async reloadLogs() {
        const Device = new FormData()
        Device.append("model", "devices")
        Device.append("id", this.device.id)
        let response = await this.$http.post('/site/devices/logs', Device)
        //needs error handling
        this.$store.commit('setDeviceLogs', response.data.logs)
      },

    }

  }
</script>
