<template>
  <v-container fluid class="connections-page">
    <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
      <h1 class="page-title">Connections</h1>
    </v-row>
    <v-row>
      <v-col cols="12">
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "Connections",
  data() {
    return {
     
    };
  },
  computed: {
    ...mapGetters(['site']),
  },
  mounted() {
    this.$store.commit('setHeaderTitle', this.site.name + ' > Connections')
  },
  methods: {
    
  },
};
</script>