<template>
  <v-app>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">
          <div class="d-flex">
            <v-img
              contain
              src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/header-logo.svg"
              max-width="200"
            ></v-img>
          </div>
          <div class="d-flex">
            <p>WIKIBUILD</p>
          </div>
        </v-col>
        <v-col cols="12" lg="5" class="login-part d-flex align-center justify-center">
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper pt-md-4 pt-0">
                <v-form>
                  <v-container>
                    <v-row v-if="loading" class="flex-column text-center mx-auto">
                      <div>
                        <v-progress-circular
                          :size="80"
                          color="primary"
                          indeterminate
                        ></v-progress-circular>
                        <p>Processing</p>
                      </div>
                    </v-row>
                    <v-row v-else class="flex-column">
                      <v-col>
                        <p v-if="forgot==false" class="login-slogan display-2 text-center font-weight-medium my-10">Welcome to WikiBuild</p>
                        <p v-else class="login-slogan display-2 text-center font-weight-medium my-10">Reset Password Procedure</p>
                      </v-col>
                      <v-form v-if="forgot==false">
                        <v-col>
                          <v-text-field
                              v-model="username"
                              :rules="usernameRules"
                              type="text"
                              label="Email/Username"
                              required
                              autocomplete
                          ></v-text-field>
                          <v-text-field
                              v-model="password"
                              :rules="passRules"
                              type="password"
                              label="Password"
                              required
                              autocomplete
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex justify-space-between">
                          <v-btn
                              class="text-capitalize"
                              large
                              :disabled="password.length < 6 || username.length === 0"
                              color="primary"
                              @click="submit"
                          >
                            Login</v-btn>
                          <v-btn large text class="text-capitalize primary--text" @click="forgot = true">Forgot Password</v-btn>
                        </v-col>
                      </v-form>
                      <v-form v-else>
                        <v-col>
                          <v-text-field
                              v-model="username"
                              :rules="usernameRules"
                              label="Email/Username"
                              required
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex justify-space-between">
                          <v-btn
                              class="text-capitalize"
                              large
                              color="primary"
                              @click="sendReset"
                          >
                            send email</v-btn>
                          <v-btn large text class="text-capitalize primary--text" @click="forgot = false">Cancel</v-btn>
                        </v-col>
                      </v-form>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text">© 2020-2023 WikiBuild. All rights reserved.</div>
              </v-footer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    name: 'Login',
    data() {
      return {
        username: '',
        usernameRules: [
          v => !!v || 'Username is required'
        ],
        password: '',
        passRules: [
          v => !!v || 'Password is required',
          v => v.length >= 6 || 'Min 6 characters'
        ],
        forgot: false,
        loading: false
      }
    },
    computed: {
      isAuthenticated () {
        return this.$store.getters.isAuthenticated
      }
    },
    methods: {
      async submit(){
        this.loading = true
        const User = new FormData()
        User.append("username", this.username)
        User.append("password", this.password)
        let response = await this.$http.post('/admin/users/login', User)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('setUser', response.data.user)
          this.$store.commit('setToken', response.data.token)
          this.$router.push('/sites')
        }
        this.loading = false
      },
      async sendReset() {
        this.loading = true
        const User = new FormData()
        User.append("email", this.username)
        let response = await this.$http.post('/global/password/forgot', User)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$toast.success("An email has been send to reset your password");
        }
        this.loading = false
      }
    },
    created() {
      if (this.isAuthenticated) {
        this.$router.push('/sites')
      }
    }
  }

</script>

<style src="./Login.scss" lang="scss"/>
