<template>

  <v-menu
    v-model="contextMenu"
    :position-x="x"
    :position-y="y"
    :close-on-content-click="false"
    absolute
    offset-y
  >
  <v-card min-width="300px">
    <v-toolbar flat dense> 
      <v-spacer></v-spacer>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="close" x-small>
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
        <span>close</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-slider
        v-model="fontSize"
        dense
        max="50"
        min="1"
        thumb-label="always"
        prepend-icon="mdi-format-font-size-decrease"
        append-icon="mdi-format-font-size-increase"
        @change="changeFontSize"
      ></v-slider>
    </v-card-text>
  </v-card>
  </v-menu>
  
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "ContextMenu",
  data() {
    return {
      fontSize: 10,
    }
  },
  computed: {
    ...mapGetters(['site', 'contextMenu', 'x', 'y']),
  },
  methods: {
    close() {
      this.$store.commit('hideContextMenu')
    },
    changeFontSize () {
      //refactoring this needs to commit a mutation in the store
      const textElements = document.querySelectorAll('.labelText');
        
      // Loop through the selected elements and change their font size
      textElements.forEach((element) => {
          element.setAttribute('font-size', this.fontSize)
      });
    },
  },
};
</script>