<template>
  <v-container fluid class="tenants-page mt-5">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="procats"
          :search="search"
          :loading="loading"
          sort-by="id"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              height="90"
            >
              <v-toolbar-title>Product Categories</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                clearable
                flat
                color="primary"
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Category
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-combobox
                            v-model="editedItem.main"
                            :items="mains"
                            label="Main Category"
                            hint="Select existing or create new"
                            persistent-hint
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12">
                          <v-combobox
                            v-model="editedItem.sub1"
                            :items="subs1"
                            label="Sub-Category 1"
                            hint="Select existing or create new"
                            persistent-hint
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12">
                          <v-combobox
                            v-model="editedItem.sub2"
                            :items="subs2"
                            label="Sub-Category 2"
                            hint="Select existing or create new"
                            persistent-hint
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.tenant_id"
                            :items="tenants"
                            item-text="name"
                            item-value="id"
                            label="Tenant"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.tenant_id`]="{ item }">
            {{ showTenantName(item.tenant_id) }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Procats',
    data: () => ({
      procats: [],
      tenants: [],
      search: '',
      loading: true,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Main', value: 'main' },
        { text: 'Sub-Category 1', value: 'sub1' },
        { text: 'Sub-Category 2', value: 'sub2' },
        { text: 'Tenant', value: 'tenant_id' },
        { text: 'Created at', value: 'created_at' },
        { text: 'Updated at', value: 'updated_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        main: '',
        sub1: '',
        sub2: '',
        sub3: '',
        tenant_id: 1
      },
      defaultItem: {
        main: '',
        sub1: '',
        sub2: '',
        sub3: '',
        tenant_id: 1
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Category' : 'Edit Category'
      },
      mains () {
        return [...new Set(this.procats.map(a => a.main))]
      },
      subs1 () {
        return [...new Set(this.procats.map(a => a.sub1))]
      },
      subs2 () {
        return [...new Set(this.procats.map(a => a.sub2))]
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', 'Admin > Product Categories')
    },

    methods: {
      async initialize () {
        let response = await this.$http.get('/admin/procats')
        this.procats = response.data.procats
        this.tenants = response.data.tenants
        this.loading = false
      },

      editItem (item) {
        this.editedIndex = this.procats.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.procats.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.procats.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        const Procat = new FormData();
        Procat.append("main", this.editedItem.main);
        Procat.append("sub1", this.editedItem.sub1);
        Procat.append("sub2", this.editedItem.sub2);
        Procat.append("sub3", this.editedItem.sub3);
        Procat.append("tenant_id", this.editedItem.tenant_id);
        if (this.editedIndex > -1) {
          Procat.append("id", this.editedItem.id);
          let response = await this.$http.put('/admin/procats', Procat)
          Object.assign(this.procats[this.editedIndex], response.data.procat)
          this.$toast.success("Procduct Category Updated");
        } else {
          let response = await this.$http.post('/admin/procats', Procat)
          this.procats.push(response.data.procat)
          this.$toast.success("Product Category Created");
        }
        this.close()
      },

      showTenantName (id) {
        return this.tenants.find(obj => { return obj.id === id }).name
      }
    },
  }
</script>